// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getUserDataFromToken } from './TokenAuth';

interface AuthContextType {
  isAuthenticated: boolean;
  user: string|null;
  login: (data:string) => void;
  logout: () => void;
}
interface BaseLayoutProps {
    children?: ReactNode;
  }
  
const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<BaseLayoutProps> =({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useLocalStorage("user", null);

  const login = (data:string) => {
    setUser(data)
    setIsAuthenticated(true) 
    getUserDataFromToken(user)
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null)
    localStorage.clear()
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      login,
      logout,
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
