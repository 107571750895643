import React ,{useEffect}from 'react';
import Router from './components/Router';
import { AxiosProvider } from './components/Axios/AxiosContext';
import { AuthProvider } from './components/Authentication/AuthContext';
import ScrollToTopOnLoad from './components/ScrollToTopOnLoad';


const App: React.FC = () => {
  //  useEffect(() => {
  //    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  //   }, []);
  return (
    <AuthProvider>
    <AxiosProvider>
 
        <Router />
        {/* <ScrollToTopOnLoad/> */}

    </AxiosProvider>
  </AuthProvider>

  );
};

export default App;