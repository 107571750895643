import React, { useState  , useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm,  SubmitHandler } from "react-hook-form"
import { ColorRing } from 'react-loader-spinner';
import { AxiosContext } from '../Axios/AxiosContext';
import { CancelSharp } from '@mui/icons-material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

interface FormData {
   oldPassword: string,
   newPassword: string,
  }

const ChangePassword: React.FC=() =>{
    const [loading, setLoading] = useState<boolean>(false)
    const [passwordType, setPasswordType] = useState<string>('password');
    const {authAxios}  = useContext(AxiosContext)
    const { logout } = useAuth()

    
    const togglePassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault();
            setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
          };
        
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
          } = useForm<FormData>()
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
 
    const successNotification = () => toast.success('Your password has been successfully changed. To apply the changes, you will be automatically logged out of the system.', {
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style:{
            textTransform: 'capitalize',
        }
        });
    const onSubmit: SubmitHandler<FormData> = async(data) => {
        setLoading(true)
        await authAxios.put('/api/ivantage/agent/changePassword',{
          oldPassword: data.oldPassword,
          newPassword: data.newPassword
        },
            ).then((res)=>{
          

            setLoading(false)
            successNotification()
            setTimeout(() => logout(), 3000)
          
        }).catch(e=>{
          setLoading(false)
          errMessage.current = e.response.data.message
          errorNotification()
        })
    }


  return (
    <>
    <Header/>
    <div className='flex'>
      <SideBar/>
    <div className='flex-1'>
    <div  className='p-8 flex-1 bg-lightgray overflow-hidden'>
    <p className='text-teal-500 text-[12px] underline mt-10 mb-2 cursor-pointer' onClick={()=>navigate(-1)}>Go back</p>
        <h2 className='text-2xl lg:mt-8 mt-10   mb-4 font-heading'>Change Password</h2>


        <form action="" className='pt-4' onSubmit={handleSubmit(onSubmit)}>
   
          {/* password */}
          <div className='px-4 my-4 '>
              <p  className='text-[14px] mb-1'>Old Password</p>
              <div className='w-[100%] lg:w-[50%] '>
                <div className=' w-full relative'>
                <input 
                  type={passwordType} 
                  {...register("oldPassword") 
                  }
                  placeholder='Enter your old password'  
                  className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%]  text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                <button className="absolute right-2 top-0 bottom-0 " onClick={togglePassword}>
                     { passwordType==="password"? <FaEyeSlash /> : <FaEye/> }
                 </button>
                 
              </div>
              {errors.oldPassword && <span className='text-xs text-red-600 '>This field is required</span>}  
                 
              </div>
            </div>
          {/* confirm password */}
            <div className='px-4 my-4'>
              <p  className='text-[14px] mb-1'>New Password</p>
              <div className='w-[100%] lg:w-[50%] relative'>
                <input 
                type={passwordType} 
                
                {...register("newPassword", {required: true , pattern:{value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
                message: "Password must include at least (one) Uppercase letter, (one) digit, and must be a minimum of 8 characters.",  
                
                } }) }
                placeholder='Enter your new password'  
                className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                <button className="absolute right-2 top-2 " onClick={togglePassword}>
                  { passwordType==="password"? <FaEyeSlash /> : <FaEye/> }
                </button>
                {errors.oldPassword && <span className='text-xs text-red-600 '>This field is required</span>}  
                 {errors.newPassword && <p className='text-xs text-red-600 text-justify'>{errors.newPassword.message}</p>}
                              
              </div>
            </div>
            <div className='text-end mt-10 mb-4 '>
            {
                loading?
             
                <button className='bg-cyan-900 rounded-lg text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                :
              <button className='bg-cyan-900 text-sm   text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 '
              type='submit'
              >SUBMIT</button>
            }
            </div>
        </form>
       
    </div>
    <Footer/>
    </div>
    </div>
    </>
  )
}

export default ChangePassword