import React,{ useState} from 'react'
import { CancelOutlined } from '@mui/icons-material'
import { motion } from 'framer-motion';

interface PopupProps {
    onClose: () => void;
    onAgree: () => void;
  }
  

const Terms: React.FC<PopupProps> = ({ onClose , onAgree}) => {
  return (
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 0.5, delay: 0.4 }}
    className="fixed inset-0 flex  w-screen h-screen bg-black bg-opacity-50 z-50">
        <div className='lg:w-[60%] w-[85%] mx-auto rounded-lg' >

        <div className='p-10 bg-white my-4'>
            <div className='text-end cursor-pointer' onClick={onClose}>
              <CancelOutlined sx={{fontSize: 20, justifyItems:'flex-end', alignItems:'end'}}/>
            </div>
            <p className='py-2 text-2xl font-semibold text-teal-800'>Terms and Conditions</p>
            <p className='text-[14px] pb-2'>By signing up, you agree to the following terms and conditions:</p>
            <div className='shadow-lg px-4 pt-2 h-[50vh] text-[14px] overflow-y-scroll'>
                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'> Listing of Properties:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> As a realtor, you are responsible for providing accurate and up-to-date information regarding the properties you list.</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>b.</span> We reserve the right to meticulously review and approve all property listings.</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>c.</span> By signing up, you agree strictly adhere to our platform's guidelines and standards concerning property listings. </p>
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>Fees and Payments:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> The platform may introduce fees for the listing services we offer, and we assure you that these fees will be clearly communicated to you.</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>b.</span> By signing up, you agree to promptly settle the applicable fees associated with utilizing our platform's services</p>
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>Compliance with Laws:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> Realtors must diligently comply with all relevant local, state, and federal laws and regulations. The platform cannot be held responsible for any legal violations committed by realtors.</p>               
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>Intellectual Property:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> You, as realtors, retain full ownership rights over the intellectual property linked to your property listings.</p>      
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>b.</span> The platform may utilize listing information for promotional purposes, provided proper attribution is provided.</p>                
                </div>
            
                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'> Termination:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> Either party reserves the right to terminate the agreement with prior notice.</p>      
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>b.</span> The platform retains the authority to suspend or terminate a realtor's account in the event of non-compliance with the agreed terms.</p>                
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>   Confidentiality:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span>  Mutual confidentiality is paramount. Both parties pledge to maintain the confidentiality of any information exchanged during our collaboration.</p>      
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>b.</span> Exceptions to confidentiality may be applicable as necessitated by law or with mutual consent.</p>                
                </div>
               
                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>   Liability and Indemnification:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span>  Realtors acknowledge their responsibility for any claims, damages, or liabilities arising from their use of our platform.</p>                 
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>   Dispute Resolution::</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span>  Realtors acknowledge their responsibility for any claims, damages, or liabilities arising from their use of our platform.</p>                 
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>   Liability and Indemnification:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> Disputes will be resolved through negotiation or, if necessary, through legal
                    means.</p>                 
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'>   Amendments:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span>  The platform reserves the right to update the terms and conditions with notice.</p>                 
                </div>

                <div className='py-2'>
                    <p className='text-[14px] font-bold  text-teal-800'> Entire Agreement:</p>
                    <p className='py-1'> <span className='text-[14px] font-bold text-teal-800'>a.</span> This Terms and Conditions constitute the entire agreement between the parties and supersede any
                        previous agreements.
                        <br className='my-2'/>
                        <br />
                        <span>
                        Realtors should thoroughly review and understand the terms before entering
                        into an agreement. Consultation with legal professionals is advisable to ensure
                        compliance with local laws and regulations.</span></p>                 
                </div>
            </div>

            <div className='text-end mt-8 ' onClick={onAgree} >
        <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 hover:bg-gray hover:text-teal-600 ' type='submit'> AGREE
        </button>
        </div>
        </div>
                
        </div>
            
    </motion.div>
  )
}

export default Terms