import React,{useState,useEffect, useRef} from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import { useForm,  SubmitHandler } from "react-hook-form"
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { logo } from '../../assets';
import { motion } from 'framer-motion';

interface FormData {
  email: string;
  password: string;
}

const  ResetPassword: React.FC = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [passwordType, setPasswordType] = useState<string>('password');
    const { email } = useParams<any>();
    
    const togglePassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
      };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger
      } = useForm<FormData>()

   const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    const successNotification = () => toast.success('Password reset successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    const onSubmit: SubmitHandler<FormData> = async(data) => {
      setLoading(true)
        const handleChangePassword= async()=>{
            await axios.post(`${baseUrl}/api/ivantage/agent/setPassword`,{
              email: email,
              password: data.password
            }
              ).then((res)=>{
              
              successNotification()
              setTimeout(()=> navigate(`/login`), 1000)
            }).catch(e=>{
                setLoading(false)
              errMessage.current = e.response.data.message
              
              errorNotification()
            })
          }

          handleChangePassword()
        }
          
          // await axios.get(`${baseUrl}/api/ivantage/agent/getUserByEmail?email=${email}`).then((res)=>{
          
          
          //   setLoading(true)
            
            
          // if(res.data.data != null){
          //   handleChangePassword()   
          // }
          // else{
          //   setLoading(false)
          //   errMessage.current = "The email address is not registered!"
          //   errorNotification()
          // }
        // }).catch((e)=> 
        // setLoading(false)
        // ) 
        


  return (
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className='min-h-screen bg-teal-600 text-gray-900 antialiased relative w-screen flex items-center justify-center'>
       <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
       ></div>
       {/* logo */}
     
       <div className='p-6 absolute left-0 top-0'>
          <img src={logo} alt="i-vantage logo" className='w-[50%]' />
        </div>
        <div className='z-10 h-[80%] mt-24 mx-auto px-3 items-center rounded-t-lg rounded-b-lg lg:p-8 lg:w-[40%] sm:w-[90%] md:w-[60%] p-6  bg-white'>
            <div>
                <h2 className='font-semibold text-2xl text-center mb-8 text-teal-600'>Reset Password</h2>
                <p className='text-[16px] text-center'>Enter your new password below</p>
                <form action=""  onSubmit={handleSubmit(onSubmit)}>
                      {/* password */}
                        <div className=' mt-6 mb-2 '>
                
                        <div className='w-[100%] '>
                            <div className=' w-full relative'>
                            <input 
                            type={passwordType} 
                            
                            {...register("password", {required: true , pattern:{value: /^(?=.*[a-z])(?=.*[0-9]).{8,}$/,
                            message: "Password must include at least (one) lowercase letter and (one) digit.",  
                            }
                            }
                            ) 
                            }
                            placeholder='Enter your password'  
                            className='bg-gray border-gray w-[100%] rounded py-2 px-3 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none' 
                            />
                            <button className="absolute right-2 top-0 bottom-0 " onClick={togglePassword}>
                                { passwordType==="password"? <FaEyeSlash /> : <FaEye/> }
                            </button>
                            
                        </div>
                        {errors.password && <span className='text-xs text-red-600 '>This field is required</span>}  
                        {errors.password && <br />     }
                        {errors.password && <p className='text-xs text-red-600 text-justify'>{errors.password.message}</p>}               
                        </div>
            </div>
        
                            
            <div className='text-end mt-8 mr-4 rounded-md mb-4' >
              {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="35"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-teal-600 text-sm  text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
            
              >RESET PASSWORD
              </button>

              }
            </div>
            </form>
            </div>
        </div>

          {/* Toast container for notifications */}
          

    </motion.div>
  )
}

export default ResetPassword