import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { signupimage1, logo } from '../../assets';
import axios from 'axios';
import { useForm,  SubmitHandler } from "react-hook-form"
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import Terms from './Terms';
import { ColorRing } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import { onChange } from 'react-toastify/dist/core/store';
import Footer from '../Footer';

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  agentCompany: string;
  password: string;
  confirmPassword: string;
}

const Signup: React.FC = () => {
  const [passwordType, setPasswordType] = useState<string>('password');
  const [confirmpasswordType, setconfirmPasswordType] = useState<string>('password');
  const [showTerms, setShowTerms] = useState<boolean>(false)
  const [agree, setAgree] = useState<boolean>(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const errMessage = useRef<string>('')
  const [passwordMessage, setPasswordMessage] = useState<boolean>(false)
  const [numberMessage, setNumberMessage] = useState<boolean>(false)
 
  const successNotification = () => toast.success('You have successfully signed up.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

 const errorNotification = () => toast.error((errMessage.current), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style:{
        textTransform: 'capitalize'
      }
      });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger
  } = useForm<FormData>()

  const watchPassword = watch('password',)
 
  const onSubmit: SubmitHandler<FormData> = async(data) => {
    setLoading(true)
    localStorage.setItem('email', data.email)
    await axios.post(`${baseUrl}/api/ivantage/agent`,{
      name: data.name,
      email: data.email,
      phoneNumber: `+234${data.phoneNumber.substring(1)}`,
      address: data.address,
      agentCompany: data.agentCompany,
      password: data.password,
      confirmPassword: data.confirmPassword
    }
    ).then((res)=>{
      
      if(res.status == 200 || res.data.success==true){
        setLoading(false)
        successNotification()
        localStorage.setItem('phoneNumber',JSON.stringify(`+234${data.phoneNumber.substring(1)}`))
        // navigate(`/activate-account/${data.email}`)
        navigate('/login')
      }
    }).catch(e=>{
      setLoading(false)
      if(e.response.data.message == "Validation error"){
        errMessage.current = e.response.data.message + '! An account with this email or phone number exists.'
      }
      else{
        errMessage.current = e.response.data.message 
      }
     
      errorNotification()
    })
  }

  const togglePassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const togglePassword2 = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setconfirmPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  
  return (
    <div className=' bg-teal-600 flex flex-col items-start text-gray-900 antialiased relative h-screen lg:h-full md:h-screen'>
       <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
       
    {/* <div className="mx-auto z-10 mt-16 text-center">
        <h1 className="text-teal-600 text-xl font-semibold">
          Welcome to <span className="text-cyan-900">iVantage's Property Listing Website.</span>
        </h1>
        <p className="text-green-200 mt-2">
          
        </p>
      </div>  */}
     <div  className= 'z-10  mx-auto px-3 mt-20 items-center rounded-t-lg rounded-b-lg lg:p-8 lg:w-[50%] md:w-[60%]  sm:w-[90%] p-6  ' >
     <div className='p-6 absolute left-0 top-0'>
      <img src={logo} alt="i-vantage logo" className='w-[50%]' />
    </div>
        <motion.div
        initial={{ opacity:0 }}
        animate={{ opacity:1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        >
     
        {/* sign up form */}
        <div className='w-[100%] bg-white  lg:px-8 px-4 rounded-lg '>
          
          <h1 className='text-teal-600 text-3xl py-4 px-0 font-semibold  text-center '>Create an Account</h1>

          <form action="" className='' onSubmit={handleSubmit(onSubmit)}>
          {/* name */}
            <div className='flex flex-row my-4 w'>
              <p className='p-2 font-semibold w-[40%] '>Full Name</p>
              <div className=' w-[100%]'>
              <input 
              type="text" 
              
              {...register("name", {required: true})}
              placeholder='Enter your fullname'  
              className='bg-gray border-gray px-3 p-2 text-sm rounded text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none w-[100%]'/>
               {errors.name && <span className='text-xs text-red-600'>This field is required</span>}                   
              </div>
            </div>

          {/* email */}
            <div className='flex flex-row my-4'>
              <p className='p-2 font-semibold  w-[40%] '>Email</p>
              <div className='w-[100%]'>
              <input 
              type="email" 
              {...register("email", {required: true, pattern: {
                value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Enter a valid email address"         
              }   })}
              placeholder='Enter your email'  
              className='bg-gray border-gray rounded px-3  w-[100%] p-2 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>
              {errors.email && <span className='text-xs text-red-600'>This field is required</span>}
              {errors.email && <p className='text-xs text-red-600 text-justify'>{errors.email.message}</p>}      
             
              
              </div>
            </div>

          {/* phone number*/}
            <div className='flex flex-row my-4 '>
              <p className='p-2 text-start font-semibold w-[40%] '>Phone Number</p>
              <div className='w-[100%]'>
                <div>

              <input 
              type="tel" 
              onFocus={()=>setNumberMessage(true)}
              {...register("phoneNumber", {required: true, 
                pattern:{
                value: /^\d{11}$/,
                message: 'Enter a valid phone number without country code'
              }
            })}
              placeholder='Enter your phone number'  
              className='bg-gray border-gray p-2 rounded px-3 w-[100%] text:p-3  text-sm placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>
              
              {errors.phoneNumber && <span className='text-xs text-red-600'>This field is required</span>}
              {errors.phoneNumber && <p className='text-xs text-red-600 text-justify'>{errors.phoneNumber.message}</p>}  
                                
              </div> 
              {/* {numberMessage && <span className='text-teal-600 text-xs text-semibold' onClick={()=>setNumberMessage(false)}>Enter a valid phone number with the country code (+234)</span>}                       */}
              </div>
            </div>

          {/* Address */}
            <div className='flex flex-row my-4 items-center '>
              <p className='p-2  font-semibold  w-[40%] '>Address</p>
              <div className='w-[100%]'>
              <input 
              type="text" 
              {...register("address", {required: true})}
              placeholder='Enter your residential address'  
              className='bg-gray border-gray p-2 text:p-3 rounded w-[100%] text-sm  px-3 text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>
              {errors.address && <span className='text-xs text-red-600'>This field is required</span>}                   
              </div>
            </div>

           {/* Address */}
           <div className='flex flex-row my-4 items-center '>
              <p className='p-2 font-semibold  w-[40%] '>Company</p>
              <div className='w-[100%]'>
              <input 
              type="text" 
              {...register("agentCompany", {required: true})}
              placeholder="Enter your organization's name"  
              className='bg-gray border-gray p-2 text:p-3 rounded w-[100%] text-sm  px-3 text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>
              {errors.agentCompany && <span className='text-xs text-red-600'>This field is required</span>}                   
              </div>
            </div>


          {/* password */}
            <div className='flex flex-row mt-4 mb-2 '>
              <p className='p-2  text-start font-semibold w-[40%]'>Password</p>
              <div className='w-[100%] '>
                <div className=' w-full relative'>
                 
                <input 
                  type={passwordType} 
                  onFocus={()=>setPasswordMessage(true)}
             
                  {...register("password", {required: true , pattern:{value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
                  message: "Password must include at least (one) Uppercase letter, (one) digit, and must be a minimum of 8 characters.",  
                  
                  }
                  }
                  ) 
                  }
                  placeholder='Enter your password'  
                  className='bg-gray border-gray w-[100%] rounded py-2 px-3 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none' 
                />
                <button className="absolute right-2 top-0 bottom-0 " onClick={togglePassword}>
                     { passwordType==="password"? <FaEyeSlash /> : <FaEye/> }
                 </button>
                 
              </div>
              {passwordMessage && <span className='text-teal-600 text-xs text-semibold' onClick={()=>setPasswordMessage(false)}>Password must include at least (one) uppercase letter and (one) digit</span>}
              {errors.password && <br />     }
            
             {errors.password && <p className='text-xs text-red-600 text-justify'>{errors.password.message}</p>}  
             {/* {watchPassword && trigger('password')}              */}
              </div>
            </div>
          {/* confirm password */}
            <div className='flex flex-row items-center'>
              <p className='p-2  font-semibold w-[40%] '>Confirm Password</p>
              <div className='w-[100%] relative'>
                <input 
                type={confirmpasswordType} 
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === watchPassword || "The passwords do not match"
                })}
                placeholder='Re-enter your password'  
                className='relative bg-gray border-gray rounded py-2 px-3 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none' 
                />
                <button className="absolute right-2 " onClick={togglePassword2}>
                  { confirmpasswordType==="password"? <FaEyeSlash /> : <FaEye/> }
                </button>
                 {errors.confirmPassword && <p className='text-xs text-red-600 text-justify'>{errors.confirmPassword.message}</p>}      
              </div>
            </div>
            {/* terms and condition */}
            <div className='flex px-2 pt-6'>
                {/* checkbox */}
               
               <input type="checkbox" name="" id="" className='accent-teal-500 mr-2 w-4 p-2' 
                onClick={()=>{
                  setAgree(!agree)
                  console.log(agree)
                }}
                />
               
                <p className='text-[14px] text-cyan-900'>Agree to <span className='underline cursor-pointer' onClick={()=>setShowTerms(true)}> terms and conditions.</span> 
                </p>
            </div>
            {
              showTerms &&
              <Terms onClose={()=>setShowTerms(false)} onAgree={()=>{
                setAgree(true)
                setShowTerms(false)
              }}/>
            }
            {/* submit button */}
            
            <div className='text-end mt-10 mb-4' >
              {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="35"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
                disabled={!agree}
              > SIGNUP
              </button>

              }
            </div>
          </form>
          
          <div  className='text-center my-2 lg:m-0'>
            <p className='text-[14px] text-black'>Have an account?  <span className='text-teal-600 hover:underline'>
                <Link to="/login">
                  Login
                </Link>
              </span>
            </p>
          </div>
        </div>
        {/* Toast container for notifications */}
        
      </motion.div>
            
     </div>
  
    </div>
  )
}

export default Signup;