import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AxiosContext } from '../Axios/AxiosContext';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { getUserDataFromToken } from '../Authentication/TokenAuth';
import Footer from '../Footer';
import { ArrowDropDownOutlined } from '@mui/icons-material';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  maintainAspectRatio: false 
};




interface ViewData {
    clicks: string;
    createdAt: string;
  }

  interface CategorizedViews {
    [date: string]: number ;
  }
  
  interface ChartData {
    labels: string[];
    data: number[];
}

interface Property {
  propertyCode: string;
  propertyName: string;
}


interface Record {
    id: number;
    propertyCode: string;
    projectName: string;
    propertyName: string;
    propertyType: string
    address: string;
    state: string;
    price: string;
    totalUnits: string;
    availableUnits: string;
    status: string;
    description: string;
    isApproved: boolean;
    images: string[];
    numberOfBedrooms: string;
    approvalStatus: string;
  }

function RealtorChart() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [filter, setFilter] = useState<'week' | 'month' | 'year' | 'all'>('all');
    const [loading, setLoading] = useState(true);
    const { authAxios } = useContext(AxiosContext)
    const views = useRef<ViewData[]>([])
    const [noOfView, setNoOfView] = useState<string[]>([])
    const [viewDate, setViewDate] = useState<string[]>([])
    const finalViews = useRef<any>()
    const [chartData, setChartData] = useState<ChartData>({ labels: [], data: [] });
    const currentDate = new Date();
    const propertyData =  useRef<Record[]>([]);
    const PropertyViews = useRef<ViewData[]>([])
    const viewsFor = useRef<string>('')
    // filter
    const selectedProperty = useRef<Property | null>(null);
    const selectedDate = useRef<string>('');

    
  const handlePropertyClick = (propertyCode: string, propertyName: string) => {
        selectedProperty.current = { propertyCode, propertyName };
       
       
    };

    const handleDateClick = (date: string) => {
       
        selectedDate.current = date;
       
    };

    const isFilterButtonActive = () => {
        return selectedProperty !== null && selectedDate !== null;
    };

    // Function to get the number of days in a month
    function getDaysInMonth(month: number): number {
        const date = new Date(2022, month, 0);
        return date.getDate();
    }

    // Function to get the name of a month by its number (1 for January, 2 for February, etc.)
    function getMonthName(month: number): string {
        return new Date(2022, month - 1, 1).toLocaleString('default', { month: 'short' });
    }

    function getDaysInMonthByName(monthName: string): number {
        // Create a mapping of month names to their corresponding number of days
        const daysInMonthMap: { [key: string]: number } = {
            "Jan": 31,
            "Feb": 28, // Note: Leap year handling is not included in this example
            "Mar": 31,
            "Apr": 30,
            "May": 31,
            "Jun": 30,
            "Jul": 31,
            "Aug": 31,
            "Sep": 30,
            "Oct": 31,
            "Nov": 30,
            "Dec": 31
        };
    
        // Return the number of days for the given month name
        return daysInMonthMap[monthName];
    }
    
    const getPropertyViews= async()=>{
    
        if(selectedProperty.current?.propertyName != "all" && selectedProperty.current?.propertyName != ''){
          if(selectedProperty.current?.propertyName){
            viewsFor.current = selectedProperty.current?.propertyName
          }
         
        await authAxios.get(`/api/ivantage/agent/property-views/${selectedProperty.current?.propertyCode}`)
        .then(res => {
         
          PropertyViews.current = res.data.data.views
        
          })
          .catch((e) => {
           
    
          });}
    }
    const mapViewsToChartData = (categorizedViews: CategorizedViews): { labels: string[], data: number[] } => {
        const labels: string[] = [];
        const data: number[] = [];
        const uniqueMonths = new Set<string>(); // Explicitly specify string as the type of elements
    
        // Iterate through each key (date) in the categorizedViews object
        Object.keys(categorizedViews).forEach(dateKey => {
            // Split the dateKey to extract month and day
            const [month, day] = dateKey.split(' ');
    
            // Add the month to the set of unique months
            uniqueMonths.add(month);
    
            // Format the date as "Month day"
            const formattedDate = `${month} ${day}`;
    
            // // Push the formatted date to labels array
            // labels.push(formattedDate);
    
            // // Push the views count for the current date to data array
            // data.push(categorizedViews[dateKey]);
        });
      
        // Iterate through each unique month and include all days of those months
        // Iterate through each unique month and include all days of those months
        uniqueMonths.forEach(month => {
            const daysInMonth = getDaysInMonthByName(month); // Get the number of days in the month by name
            for (let day = 1; day <= daysInMonth; day++) {
                const formattedDate = `${month} ${day}`;
                const eachViews = categorizedViews[formattedDate] || 0
                labels.push(formattedDate);
               
                
                data.push(eachViews) ;
               
                // if (!categorizedViews[formattedDate]) {
                //     labels.push(formattedDate);
                //     data.push(0);
                // }
            }
        });

    
        // Update chart data
        setChartData({ labels, data });
    
        return { labels, data };
    }
    
    const mapYearViewsToChartData = (categorizedViews: CategorizedViews): { labels: string[], data: number[] } => {
        const labels: string[] = [];
        const data: number[] = [];
    
        // Initialize an object to store summed views for each month
        const summedViewsByMonth: { [key: string]: number } = {};
    
        // Iterate through each key (date) in the categorizedViews object
        Object.keys(categorizedViews).forEach(dateKey => {
            const [month] = dateKey.split(' ');
            summedViewsByMonth[month] = (summedViewsByMonth[month] || 0) + categorizedViews[dateKey];
           
        });
    
        // Include all months of the year
        for (let month = 1; month <= 12; month++) {
            const monthName = getMonthName(month);
           
           
            const totalViews = summedViewsByMonth[monthName] || 0;
           
           
            // Format the date as "Month"
            const formattedDate = `${monthName}`;
    
            // Add the formatted date as label
            labels.push(formattedDate);
    
            // Add the total views for the month as data
            data.push(totalViews);
        }
    
        // Update chart data
        setChartData({ labels, data });
    
        return { labels, data };
    }
    
    
    
    const handleApplyFilter = async() => {
        // Your filter logic here
        await getPropertyViews()
        let filteredViews;
        let sortedViews;
        if(selectedProperty.current?.propertyName === 'all' || selectedProperty.current?.propertyName === ''){
            sortedViews = views.current;
            viewsFor.current = 'All Properties'
        }
        else{
            sortedViews = PropertyViews.current
        }
       
      
       
        if(selectedDate.current === 'ThisYear'){
            filteredViews = sortedViews
            const categorizedViews: CategorizedViews = {};
            filteredViews?.forEach((view:any) => {
              const { createdAt } = view;
             
               const createdAtDate = new Date(createdAt);
               const monthAbbreviation = createdAtDate.toLocaleString('default', { month: 'short' });
               const day = createdAtDate.getDate();
               const formattedDate = `${monthAbbreviation} ${day}`;
               if (categorizedViews[formattedDate] !== undefined) {
                   categorizedViews[formattedDate]++;
                 } else {
                   categorizedViews[formattedDate] = 1;
                 }
               });
              
              finalViews.current = categorizedViews
              mapYearViewsToChartData(categorizedViews)
        }
        else {
            switch (selectedDate.current) {
            case 'Today': 
                filteredViews = sortedViews.filter(view => 
                    { const [year, month, day] = view.createdAt?.split('-')
                    
                      return parseInt(day) == currentDate.getDate()
                    }
                    )
               
               
               
                break;
            case 'Yesterday':
                const yesterday = new Date(currentDate);
                yesterday.setDate(yesterday.getDate() - 1);
                filteredViews = sortedViews.filter(view => 
                    { const [year, month, day] = view.createdAt?.split('-')
                      return parseInt(day) == yesterday.getDay()
                    }
                    )
                break;
            case 'WeekAgo':
                const weekAgo = new Date(currentDate);
                weekAgo.setDate(weekAgo.getDate() - 7);
                const datesInWeekAgo: string[] = [];
                // Populate datesInWeekAgo with dates in the past week
                for (let i = 0; i < 7; i++) {
                    const date = new Date(weekAgo);
                    date.setDate(date.getDate() + i);
                    datesInWeekAgo.push(date.toISOString().split('T')[0]); // Convert date to YYYY-MM-DD format
                }
                filteredViews = sortedViews.filter(view => {
                    const [year, month, day] = view.createdAt?.split('-');
                    const viewDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // Ensure view date is in YYYY-MM-DD format
                    return datesInWeekAgo.includes(viewDate);
                });
                
                break;
            case 'LastMonth':
                const lastMonth = new Date()
                lastMonth.setDate(1); // Set to the first day of the current month
                lastMonth.setMonth(lastMonth.getMonth() - 1);
                filteredViews = sortedViews.filter(view => 
                    { const [year, month, day] = view.createdAt?.split('-')
                        return parseInt(month) == lastMonth.getMonth()+1
                    }
                    )
                break;
            case 'ThisMonth':
                    const thisMonth = new Date()
                    // lastMonth.setDate(1); // Set to the first day of the current month
                    // lastMonth.setMonth(lastMonth.getMonth() - 1);
                    filteredViews = sortedViews.filter(view => 
                        { const [year, month, day] = view.createdAt?.split('-')
                            return parseInt(month) == thisMonth.getMonth()+1
                        }
                        )
                break;
           
            default:
                filteredViews = sortedViews;
            }
        
        

         const categorizedViews: CategorizedViews = {};

         filteredViews?.forEach((view:any) => {
           const { createdAt } = view;
          
            const createdAtDate = new Date(createdAt);
            const monthAbbreviation = createdAtDate.toLocaleString('default', { month: 'short' });
            const day = createdAtDate.getDate();
            const formattedDate = `${monthAbbreviation} ${day}`;
            if (categorizedViews[formattedDate] !== undefined) {
                categorizedViews[formattedDate]++;
              } else {
                categorizedViews[formattedDate] = 1;
              }
            });
           
           finalViews.current = categorizedViews
           mapViewsToChartData(categorizedViews)
        }
        setIsOpen(false)
    }

  
     // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


  useEffect(() => {
    setLoading(true)
    viewsFor.current = 'All properties'
    const token = localStorage.getItem('user');
    if (token){
    const decodedUserData = getUserDataFromToken(token.toString());
   
    authAxios.get(`/api/ivantage/agent/views/${decodedUserData?.email}`)
      .then(res => {
        
    
        views.current = res.data.data.views
       
       
       
        let filteredViews;
        filteredViews = views.current
            const categorizedViews: CategorizedViews = {};
            filteredViews?.forEach((view:any) => {
              const { createdAt } = view;
             
               const createdAtDate = new Date(createdAt);
               const monthAbbreviation = createdAtDate.toLocaleString('default', { month: 'short' });
               const day = createdAtDate.getDate();
               const formattedDate = `${monthAbbreviation} ${day}`;
               if (categorizedViews[formattedDate] !== undefined) {
                   categorizedViews[formattedDate]++;
                 } else {
                   categorizedViews[formattedDate] = 1;
                 }
               });
              
              finalViews.current = categorizedViews
              mapYearViewsToChartData(categorizedViews)
   
        setLoading(false);
      })
      .catch(() => {
        setLoading(false)
      });
    }
   

    // get properties
    authAxios.get(`/api/ivantage/agent/properties`)
      .then(res => {
       
         
          propertyData.current = res.data.data.propertyData;
         
          setLoading(false);
         })
        .catch((e) => {
         
         
        });
  }, []);


        const labels = chartData.labels
     

        const data = {
        labels,
        datasets: [
            {
            fill: true,
            label: 'Total Views',
            data: chartData.data,
            borderColor: '#006064',
            backgroundColor: '#E0F2F1',
            },
        ],
    
        };

  return (
    <div>
        <section>
            <div className='flex justify-between'>
                <div className='flex '>
              
                    <p className='text-[14px] text-center capitalize '>Views for {viewsFor.current} <span className='text-teal-500'>| {selectedDate.current || `This Year`}</span></p>
                    
                </div>
                
                <div>
                <div ref={dropdownRef} className="relative inline-block">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className=" flex justify-between"
                    >
                       <p className='py-2 px-8 border-gray border-[1px] capitalize'>{selectedProperty.current?.propertyName || `All Properties`}</p>
                       <p className='py-2 px-8 border-gray border-[1px]'>{selectedDate.current || `This Year`}
                       <span><ArrowDropDownOutlined  sx={{fontSize: 20, padding:0}}/></span>
                       </p>
                
                    </button>
                    {isOpen && (
                        <div className="absolute text-[14px] right-0 mt-2 w-full bg-white shadow-lg rounded-md overflow-hidden z-10 flex justify-between">
                          
                            <div className='p-2 '>
                                         <div className='my-1'>
                                              
                                            </div>
                                            <select name="" id="" onChange={(e) => handlePropertyClick(e.target.selectedOptions[0].id, e.target.value)}       className="block px-4 bg-lightgray py-2 text-gray-800 focus:bg-cyan-800 focus:text-lightgray rounded my-2 w-full">
                                            <option value="" id=''>Select Property</option>
                                            <option value="all" id=''>All</option>
                                            {propertyData.current.map((property) => (
                                              <option key={property.propertyCode} id={property.propertyCode} value={property.propertyName}>
                                                {property.propertyName}
                                              </option>
                                            ))}
                                          </select>
                                                                          
                            
                            </div>
                            <div className="p-2">
                            <select
                             // value={selectedDate.current}
                              onChange={(e) => handleDateClick(e.target.value)}
                              className="block px-4 bg-lightgray py-2 text-gray-800 focus:bg-cyan-800 focus:text-lightgray rounded my-2 w-full"
                            ><option value="" id=''>Select TimeFrame</option>
                              <option value="ThisYear">This year</option>
                              <option value="Today">Today</option>
                              <option value="Yesterday">Yesterday</option>
                              <option value="WeekAgo">7 days ago</option>
                              <option value="ThisMonth">This month</option>
                              <option value="LastMonth">Last month</option>
                              
                            </select>

                                                         
                                <button className="block px-4 py-2 bg-cyan-800 items-end mt-8 text-lightgray my-2" onClick={handleApplyFilter}>APPLY FILTER</button>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </section>
        <div>
        <Line options={options} data={data}  
         height={500} />
        </div>
    </div>
  )

}

export default RealtorChart