// src/utils/auth.ts
import { jwtDecode } from "jwt-decode";

interface UserData {
  id: string;
  name: string;
  email: string;
  isEnabled: boolean;
  hasKyc: boolean;
}

export const getUserDataFromToken = (token: string): UserData | null => {
  try {
    
    const decodedToken: any = jwtDecode(token);

    const userData: UserData = {
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      isEnabled: decodedToken.isEnabled,
      hasKyc: decodedToken.hasKyc
    };
    return userData;
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return null;
  }
};
