import axios, { AxiosInstance } from 'axios';
import React, { createContext, ReactNode, useEffect } from 'react'; // Added React import
import baseUrl from '../Util/config';
import { useAuth } from '../Authentication/AuthContext';


// Create AxiosContext
interface AxiosContextType {
  authAxios: AxiosInstance;
  publicAxios: AxiosInstance;
}

const AxiosContext = createContext<AxiosContextType>({
  authAxios: axios.create(),
  publicAxios: axios.create()
});
const { Provider } = AxiosContext; 

interface AxiosProviderProps {
  children?: ReactNode;
}


const AxiosProvider: React.FC<AxiosProviderProps>=({children}) => {
  const { user } = useAuth()

  const authAxios = axios.create({
    baseURL: baseUrl
  });

  const publicAxios = axios.create({
    baseURL: baseUrl 
  });

 
  authAxios.interceptors.request.use(
    (config) => {
      const token = user;
      if (token) {
        config.headers['x-access-token'] = token ;
        // config.headers['token'] = 'Felix' ;
        // 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios
      }}
    >
      {children}
    </Provider>
  );
};

export { AxiosContext, AxiosProvider };
