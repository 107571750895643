import React,{ReactNode} from "react";
import { useAuth } from "./AuthContext";
import { Navigate, Outlet } from "react-router-dom";

interface BaseLayoutProps {
    children?: ReactNode;
  }
  
export const ProtectedRoute: React.FC<BaseLayoutProps> = ({ children }) => {
     const { user } = useAuth()
     if(! user){
       return <Navigate to="/login" />;
    }
     return <Outlet/>
  };