import React,{useState,useEffect, useRef, useContext} from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import { AxiosContext } from './Axios/AxiosContext';
import { getUserDataFromToken } from './Authentication/TokenAuth';
import { useAuth } from './Authentication/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

interface FormData {
  phoneNumber: string;
  otp: string;
}

const VerifyEmail2: React.FC = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const {authAxios}  = useContext(AxiosContext)
    const [email, setEmail] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [number, setNumber] = useState()
    const {logout} = useAuth()

    const successNotification = () => toast.success('Your account has been successfully verified!. You will now be redirected to the login page, to effect the change.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

    const resendNotification = () => toast.success('OTP code has been re-sent to your email!.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  
   const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style:{
            textTransform: 'capitalize',
        }
        });

           useEffect(() => {
            const token = localStorage.getItem('user');
  
            if (token) {
              // Decode JWT token and extract user data
              const decodedUserData = getUserDataFromToken(token.toString());
              
              if (decodedUserData){
                setEmail(decodedUserData.email)
                
                authAxios.get(`/api/ivantage/agent/getUserByEmail?email=${decodedUserData.email}`).then((res)=>{
                  if(res.data.data.phoneNumber){
                    setNumber(res.data.data.phoneNumber)
                    
                  }
                }).catch((e)=> console.error(e)) 
              }
             
            } else {
              console.error('JWT token not found');
            }
          }, []);
    const handleVerification= async()=>{
      if(otp.length==0){
        errMessage.current='OTP code not provided!.'
        errorNotification()
      }
      else{
      setLoading(true)
      await authAxios.put(`/api/ivantage/agent/verifyOTP`,{
        email: email,
        otp: otp
      }).then((res)=>{
        
          successNotification()
        if (res.status==200 || res.data.success==true){
          setLoading(false)
        
          setTimeout(() => 
          { 
            logout()
            navigate('/login')
          }, 3000)
        }
        
      }).catch(e=>{
        
        
        
        errMessage.current = e.response.data.message
        
        setLoading(false)
        errorNotification()
      })
      
    }
    }
  
    const handleResendOtp= async()=>{
      setLoading(true)
      console.log(email)
      await authAxios.post(`/api/ivantage/agent/resendOTP`,{
        sms: false,
        data: email
      }).then((res)=>{
 
        if (res.data.status==true){
          
          setLoading(false)
          toast.success('OTP code has been re-sent to your email!.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
       
        }
        
      }).catch(e=>{
        setLoading(false)
        
        
        errMessage.current = e.response.data.message
        
        errorNotification()
      })
    }
  return (
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 1, delay: 0.4 }}
    className='flex w-screen h-screen justify-center items-center bg-teal-600 bg-opacity-50 z-50 overflow-scroll'>
        <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
        <div className='bg-white z-10 lg:w-[50%] rounded-lg  px-20 py-4'>
            <div>
                <h2 className='text-2xl text-center mb-8 text-teal-600'>VERIFY EMAIL</h2>
                <p className='text-[16px] text-center'>A One-Time-Password (OTP) Code has been sent to your email. Enter it below to activate your account.</p>
                <div className='text-center flex justify-center p-4 my-6'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span className='text-teal-600 font-bold mx-4'>-</span>}
                        renderInput={(props) => <input {...props} className='border-2 border-teal-600 w-[100px] rounded-sm
                        text-4xl  text-teal-600 font-bold focus:border-darkgray' />}
                        shouldAutoFocus= {true}
                         inputType='tel'        
                    />
                </div>
                <p className='text-green-700 text-xs text-center underline cursor-pointer' onClick={handleResendOtp}>Resend Code</p>
                {/* submit button */}
                            
            <div className='text-end mt-10 mb-4' >
              {
                loading?
             
                <button className='bg-cyan-800 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-cyan-800 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
                onClick={handleVerification}
              >VERIFY
              </button>

              }
            </div>
            </div>
        </div>


    </motion.div>
  )
}

export default VerifyEmail2