import { ExportAsExcel, ExportAsPdf, ExportAsCsv, CopyToClipboard, PrintDocument, } from "react-export-table"
import React,{useState, useEffect,useContext, useRef} from 'react'
import SubmissionRecords from "../SubmissionRecords"
import { AddOutlined, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft, Filter1Outlined, FilterListOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Header"
import SideBar from "../SideBar"
import { AxiosContext } from '../Axios/AxiosContext';
import { ColorRing } from 'react-loader-spinner';
import { FaLastfmSquare } from "react-icons/fa"
import { listing } from "../../assets"
// import { data } from '../Charts/RealtorChart';
import { motion } from 'framer-motion';

interface Record {
  id: number;
  propertyCode: string;
  projectName: string;
  propertyName: string;
  propertyType: string
  address: string;
  state: string;
  price: string;
  totalUnits: string;
  availableUnits: string;
  status: string;
  description: string;
  isApproved: boolean;
  images: string[];
  numberOfBedrooms: string;
  approvalStatus: string;
  createdAt: string;
}

const Submissions: React.FC =()=> {
  const [data, setData] = useState<Record[]>([]);
  const [loading, setLoading] = useState(true);
  const { authAxios } = useContext(AxiosContext)
  const navigate = useNavigate()
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const [submissionFound, SetSubmissionFound] = useState<boolean>(true) 
  const [submission, setSubmission] = useState<Record[]>([]);
  const [option, setOption] = useState()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const filtered = useRef<boolean>(false)
  const filterFound = useRef<boolean>(true)

  useEffect(() => {
      setLoading(true)
      authAxios.get(`/api/ivantage/agent/unapproved`)
      .then(res => {
        console.log(res)
        if(res.data.data.propertyData.length == 0){
          SetSubmissionFound(false)
        }
          setData(res.data.data.propertyData)
          setSubmission(res.data.data.propertyData);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false)
          // toast.error('There was an error while retrieving the data', {
          //   position: "top-right",
            
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   });
        });
    }, []);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [selectedOption, setSelectedOption] = useState<string>('');


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setData(submission)
   
   
   
    // let filterData;
     if(selectedOption){
      if(selectedOption === 'all'){
      const filterData = submission
     
      setData(filterData)
      }
      else{
      const filterData =  submission.filter((item)=> item.approvalStatus == selectedOption)
      
      setData(filterData)
      if(filterData.length === 0){
        filterFound.current = false
    } else{
      filterFound.current = true
    }
     
    }
  }
  };
  let indexCounter = 0;


  return (
    <>
   <Header/>
    <div className='flex'>
      <SideBar/>
        <div className='p-8 bg-lightgray flex-1 overflow-hidden'>
            <h2 className='text-2xl lg:mt-10 mt-16  mb-4'>Submitted Properties</h2>

      {
          loading?
          <div className='flex h-[90vh] items-center justify-center'>
          <ColorRing
          visible={true}
          height="100"
          width="100%"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
          />  
        </div>
        :
        <div>
          { submissionFound?
        <section className='mt-4 flex lg:flex-row flex-col  w-full'>
          {
            data.length> 0
            ?
          
          <div className="lg:w-[65%] w-[100%]">
               {/* filter */}
            <div onClick={()=>setShowFilter(!showFilter)} className="flex items-center lg:hidden ">
              <FilterListOutlined sx={{fontSize: 30, color:'#00897B'}}/>
              <p className="text-[14px]">Filter</p>
            </div>
            {
              showFilter &&
            
            <div className="w-[100%] lg:hidden block px-8 mt-2 justify-center ">
              <div className="border-[1.5px] border-gray">
                <h2 className="text-[18px] p-2 bg-gray">Filter your Submissions</h2>
               
              <form onSubmit={handleFilter}>          
               <div className="p-2">
                  <p className="text-[14px]">Status</p>
                  <select name="state" id="state" value={selectedOption} onChange={handleSelectChange}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                  <option value="">Select </option>
                  <option value="all" className="text-[16px]">All</option>
                  <option value="pending" className="text-[16px]" >Pending</option>
                  <option value="declined" className="text-[16px]" >Declined</option>
                 </select>
                </div>

                                                 
               <div className="p-2">
                  <button className="text-[14px] py-2 px-8 text-center bg-cyan-800 text-lightgray font-semibold focus:bg-lightgray focus:text-cyan-800 border-[1px] " type="submit">FILTER</button>

                </div>
            </form> 
            </div>
            </div>
            }

            <SubmissionRecords data={currentItems} filtered={filtered.current}/>
            {
              !filterFound.current && 
              <div className="p-4 bg-gray rounded w-full">
                <p className="">No property found. Please adjust your filter criteria and try again.</p>
              </div>

            }
            <ReactPaginate
                    breakLabel="..."
                    nextLabel= {<KeyboardDoubleArrowRight sx={{color:'#26A69A'}}/>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    previousLabel={<KeyboardDoubleArrowLeft sx={{color:'#26A69A'}}/>}
                    renderOnZeroPageCount={null}
                    className='flex my-3 justify-center items-center'
                    pageClassName='mx-1 p-1 text-sm text-cyan-900  '
                    pageLinkClassName='hover:bg-gray rounded-full px-2 py-1'
                    activeLinkClassName='bg-cyan-900 rounded-full px-2 py-1 text-gray hover:text-cyan-900'


                  />

            </div>
                  :
              <motion.div
              initial={{ height: 0, opacity: 0 }} // Set initial height and opacity to 0
              animate={{ height: "auto", opacity: 1 }} // Animate height to auto and opacity to 1
              transition={{ duration: 0.3 }}
              className="w-[60%]">
              <div className="w-[60%] text-center mx-auto p-2">
              <img src={listing} alt="" />
              </div>
              <div className="text-center">
                  <p className="text-darkgray font-semibold lg:text-[22px] text-[16px]">You do not have any {selectedOption} property.</p>
                  
              </div>
            
            </motion.div>
            }
          {/* filter */}
          <div className="w-[35%] hidden lg:block px-8 mt-2 justify-center ">
            <div className="border-[1.5px] border-gray">
              <h2 className="text-[18px] p-2 bg-gray">Filter your Submissions</h2>
               
              <form onSubmit={handleFilter}>          
               <div className="p-2">
                  <p className="text-[14px]">Status</p>
                  <select name="state" id="state" value={selectedOption} onChange={handleSelectChange}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                  <option value="">Select </option>
                  <option value="all" className="text-[16px]">All</option>
                  <option value="pending" className="text-[16px]" >Pending</option>
                  <option value="declined" className="text-[16px]" >Declined</option>
                 </select>
                </div>

                                              
               <div className="p-2">
                  <button className="text-[14px] py-2 px-8 text-center bg-cyan-800 text-lightgray font-semibold focus:bg-lightgray focus:text-cyan-800 border-[1px] " type="submit">FILTER</button>

                </div>
            </form> 
            </div>
            </div>
        </section>
        :
        <motion.div
        initial={{ height: 0, opacity: 0 }} // Set initial height and opacity to 0
        animate={{ height: "auto", opacity: 1 }} // Animate height to auto and opacity to 1
        transition={{ duration: 0.3 }} 
        className="mt-10">
          <div className="lg:w-[40%] sm:w:[80%] md:w-[55%] text-center mx-auto p-2">
          <img src={listing} alt="" />
          </div>
          <div className="text-center">
              <p className="text-darkgray font-semibold lg:text-[22px] text-[16px]">Oops! You do not have any submitted property.</p>
              <p className="text-darkgray text[14px] border-b-2 border-gray w-[15%] mx-auto cursor-pointer" onClick={()=>navigate('/newproperty')}>Add new property</p>
          </div>
      
         </motion.div>
        }
        </div>
        }
      {/* toastify */}
      
    </div>
    </div>
    </>
  )
}

export default Submissions