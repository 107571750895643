import React from 'react'
import { notfound } from '../assets'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';

const NotFound = () => {
    const navigate = useNavigate()
  return (
    <motion.div
      initial={{ opacity:0 }}
      animate={{ opacity:1 }}
      transition={{ duration: 0.5}}
      className='bg-lightfray w-screen'
      >

        <div>
            <p className='text-teal-500 cursor-pointer underline my-8 px-4' onClick={()=>navigate('/dashboard')}>Go back to the Dashboard</p>

            <div className='lg:w-[35%] sm:w-[100%] md:w-[90%] mx-auto '>
                <img src={notfound} alt="" className='w-full h-full' />
            </div>
            
        </div>
      </motion.div>


 
  )
}

export default NotFound