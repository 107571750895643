import React, { useEffect,useContext, useState, useRef } from 'react'
import Header from '../Header'
import SideBar from '../SideBar'
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom'
import { AxiosContext } from '../Axios/AxiosContext';
import { getUserDataFromToken } from '../Authentication/TokenAuth';
import { ColorRing } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import { useForm,  SubmitHandler , DefaultValues } from "react-hook-form"
import { useAuth } from '../Authentication/AuthContext';
import 'react-toastify/dist/ReactToastify.css';


interface UserData {
    name: string;
    phoneNumber: string,
    phoneNumber2: string;
    address: string,
    agentCompany: string,
    email: string
  }

  
function EditProfile() {
    const navigate = useNavigate()
    const { authAxios } = useContext(AxiosContext)
    const [loading, setLoading] = useState(true);
    const errMessage = useRef<string>('')
    const [userData, setUserData] = useState<UserData | null>(null);
    const [numberMessage, setNumberMessage] = useState<boolean>(false)
    const { logout } = useAuth()
    const otpNumber = useRef<string>('')
    const name= useRef<string>('')
    const userId =  useRef<string>('')
    const successNotification = () => toast.success('Your profile has been successfully updated. ', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    
     const errorNotification = () => toast.error((errMessage.current), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('user');
          if (token) {
            // Decode JWT token and extract user data
           
            const decodedUserData = getUserDataFromToken(token.toString());
           
            if (decodedUserData){
                userId.current = decodedUserData.id
            authAxios.get(`/api/ivantage/agent/getAgent?userId=${decodedUserData.id}`)
                .then(res => {
                  if(res.data.data){
                   
                    name.current=res.data.data.name
                    localStorage.setItem('email', res.data.data.email)
                     
                      setUserData(res.data.data)
                 
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  
                  // alert('There was an error while retrieving the data');
             });
      }
     }},[]);
    
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm<UserData>({
          defaultValues: async(): Promise<UserData>=> {{
            try {
                const response = await authAxios.get(`/api/ivantage/agent/getAgent?userId=${userId.current}`);
                const data = response.data.data; 
               
                return {
                  name: data?.name || '',
                  email: data?.email || '',
                  phoneNumber: data?.phoneNumber || '',
                  phoneNumber2: data?.phoneNumber2 || '',
                  address: data?.address || '',
                  agentCompany: data?.agentCompany || ''
                };
              } catch (error) {
                console.error('Error fetching default values:', error);
                return {
                  name: '',
                  email: '',
                  phoneNumber:  '',
                  phoneNumber2:  '',
                  address:  '',
                  agentCompany:  ''
                }; 
              }
            }}
          ,
        shouldFocusError:true
      })

      const watchPN1 = watch('phoneNumber')
      const watchPN2 = watch('phoneNumber2')



      const onSubmit: SubmitHandler<UserData> = async(data) => {
        setLoading(true)
      
      await authAxios.put(`/api/ivantage/agent/edit-profile`,{
            data,
            email: userData?.email
        }).then((res)=>{
          
          if(res.status == 200 || res.data.success==true){
            setLoading(false)
            successNotification()
           }
          setTimeout(() => 
          {
            // logout()
            navigate(`/dashboard`)
            
          }, 3000)
        }).catch(e=>{
          setLoading(false)
          
          errMessage.current = e.response.data.message + 'An account with the same phone number or email exists!.'
          errorNotification()
        })
      }

  return (
   <>
   <Header/>
   <div className='flex'>
    <SideBar/>
    <div>
        <div  className='p-8 flex-1 bg-lightgray overflow-hidden'>
            <p className='text-teal-500 text-[12px] underline lg:mt-10 mt-16 mb-2 cursor-pointer' onClick={()=>navigate(-1)}>Go back</p>
        <h2 className='text-2xl  lg:mt-8 mt-10 mb-4 font-heading'>Edit Profile</h2>
         {/* <div className='p-4 w-full bg-gray rounded'>
            <p className='text-red-700'>Please Note: You will be required to <span className='font-bold '>re-activate </span>your account(through phone number verification), when you edit your profile.</p>
         </div> */}
        <form className='pt-4'  onSubmit={handleSubmit(onSubmit)}>
            <div className='my-3 px-4  flex flex-col'>
                <label className='text-[14px] mb-2'>Name</label>
                <input 
                    type="text"
                    {...register("name", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] lg:w-[50%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                  {errors.name && <span className='text-xs text-red-600'>This field is required</span>}   
            </div>

            <div className='my-3 px-4  flex flex-col'>
                <label className='text-[14px] mb-2'>Phone Number</label>
                <div>
                              <input 
                              type="tel" 
                              onFocus={()=>setNumberMessage(true)}
                              {...register("phoneNumber", {required: true, 
                                pattern:{
                                value: /^\d{11}$/,
                                message: 'Enter a valid phone number without country code'
                              }
                              })}
                              placeholder='Enter your phone number'  
                              className='bg-gray border-gray p-2 rounded px-3 w-[100%] text:p-3  text-sm placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>

                              {errors.phoneNumber && <span className='text-xs text-red-600'>This field is required</span>}
                              {errors.phoneNumber && <p className='text-xs text-red-600 text-justify'>{errors.phoneNumber.message}</p>}  
                  
                            </div> 
                
              {numberMessage && <span className='text-teal-600 text-xs text-semibold' onClick={()=>setNumberMessage(false)}>Enter a valid phone number without the country code</span>}      
            </div>



            <div className='my-3 px-4  flex flex-col'>
                <label className='text-[14px] mb-2'>Phone Number 2</label>
                <div>
                    <input 
                    type="tel" 
                    onFocus={()=>setNumberMessage(true)}
                    {...register("phoneNumber", {required: true, 
                      pattern:{
                      value: /^\d{11}$/,
                      message: 'Enter a valid phone number without country code'
                    }
                    })}
                    placeholder='Enter your phone number'  
                    className='bg-gray border-gray p-2 rounded px-3 w-[100%] text:p-3  text-sm placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>

                    {errors.phoneNumber && <span className='text-xs text-red-600'>This field is required</span>}
                    {errors.phoneNumber && <p className='text-xs text-red-600 text-justify'>{errors.phoneNumber.message}</p>}  

                    </div> 

                    {numberMessage && <span className='text-teal-600 text-xs text-semibold' onClick={()=>setNumberMessage(false)}>Enter a valid phone number without the country code</span>}    
            </div>

            <div className='my-3 px-4  flex flex-col'>
                <label className='text-[14px] mb-2'>Residential Address</label>
                <input 
                    type="text"
                    {...register("address", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] lg:w-[50%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                  {errors.address && <span className='text-xs text-red-600'>This field is required</span>}   
            </div>

            <div className='my-3 px-4  flex flex-col'>
                <label className='text-[14px] mb-2'>Company</label>
                <input 
                    type="text"
                    {...register("agentCompany", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] lg:w-[50%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                  {errors.agentCompany && <span className='text-xs text-red-600'>This field is required</span>}    
            </div>

              {/* submit button */}
            
              <div className='text-end mt-10 mb-4' >
              {
                loading?
             
                <button className='bg-cyan-900 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="35"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900  hover:bg-gray hover:text-cyan-900 ' type='submit'
              
              > SUBMIT
              </button>

              }
            </div>
        </form>
        </div>
        <Footer/>
    </div>
   </div>


   </>
  )
}

export default EditProfile