import React,{ useEffect, useRef, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-slideshow-image/dist/styles.css';
import Header from '../Header';
import SideBar from '../SideBar';
import { AxiosContext } from '../Axios/AxiosContext';

interface Record {
    id: number;
    projectName: string;
    projectStatus: string;
    description: string;
    titleDocument: FileList;
    survey: FileList;
    accountName: number;
    accountNumber: string;
    accountBankName: string;
    isCharted: boolean;
    titleSearchDone: boolean;
  }

const ViewProject: React.FC = () => {
    const { id } = useParams<any>();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState<Record | null>(null);
    const { authAxios } = useContext(AxiosContext)
    const [showPDF, setShowPDF] = useState<boolean>(false)
    const [survey, setSurvey] = useState<string>('')
    const [titleDocument, setTitleDocument] = useState<string>('')
    const navigate = useNavigate()

    useEffect(() => {
      
      authAxios.get(`/api/ivantage/agent/project?id=${id}`)
          .then(res => {
            if(res.data.data){
                
              setProject(res.data.data)
              setSurvey(res.data.data.survey)
              setTitleDocument(res.data.data.titleDocument)
            }
            setLoading(false);
          })
          .catch(() => {
            alert('There was an error while retrieving the data');
          });
      },[]);
    
      // const [numPages, setNumPages] = useState<number>();
      // const [pageNumber, setPageNumber] = useState<number>(1);
    
      // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      //   setNumPages(numPages);
      // }

   
    
  return (
    <>
    <Header/>

    <div className='flex'>
     <SideBar/>
    <div className='p-8 w-screen bg-lightgray overflow-hidden'>
        {/* <p className='text-2xl mt-10'>Publication</p> */}
        <div className='flex  mt-10'>
         <div className='w-[60%]'>
            <p className='font-semibold text-[22px] py-2'>{project?.projectName} <span className='bg-teal-400 rounded-full text-[12px] font-normal px-2  text-lightgray'>{project?.projectStatus}</span> <span className='bg-cyan-800 rounded-full text-[12px] font-normal px-2 text-lightgray cursor-pointer' onClick={()=>navigate(`/edit-project/${id}`)}>Update Project</span></p>
            
            {/* propery description */}
            <div className='my-2'>
                <p className='text-[18px] mb-2 font-semibold'>Property Description</p>
                <p className='text-[16px] text-justify'>{project?.description}</p>
                </div>
         </div>

         {/*  */}
         <div className='mt-10 shadow-lg ml-8 px-10 py-4'>
         <div className='my-4'>
                <p className='text-[18px] mb-0 font-semibold'>Account Name</p>
                <p className='text-[16px] text-justify'>{project?.accountName}</p>
                </div>

         <div className='my-4'>
                <p className='text-[18px] mb-0 font-semibold'>Bank Name</p>
                <p className='text-[16px] text-justify'>{project?.accountBankName}</p>
                </div>
   
         <div className='my-4'>
                <p className='text-[18px] mb-0 font-semibold'>Account Number</p>
                <p className='text-[16px] text-justify'>{project?.accountNumber}</p>
                </div>

        </div>

     

    </div>

       {/* documents */}
       <div className='mt-8'>
        <p className='text-[18px] mb-0 font-semibold'>Documents</p>
        <div className='p-2 shadow-md my-1 flex text-center'>
        <p>Survey Document</p>
        <a
            href={survey}
            download="Survey Document"
            target="_blank"
            rel="noreferrer"
          >
            <span className='bg-cyan-800 rounded-full text-[12px] font-normal py-1 px-2 text-center mx-2 text-lightgray cursor-pointer' >Download</span>
        </a>
        </div>
        {/* <div>
      <Document file={survey} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      </div> */}
    {/* {
      showPDF && 
      <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50'>
        <div className='flex-1'>
          <div onClick={()=>setShowPDF(false)}>
          <CancelOutlined sx={{fontSize: 40,  color:'#26A69A', justifyItems:'flex-end', alignItems:'end'}} />
          </div>
    
        <iframe
              src={survey}
              title="PDF Viewer"
              width="100%"
              height="500px"
              style={{ border: 'none' }}
            />    
        </div>
      </div>
     } */}
        <div  className='p-2 flex shadow-md my-1'>
          <p>Title Document</p>   
          <a
            href={titleDocument}
            download="Title Document"
            target="_blank"
            rel="noreferrer"
          >
            <span className='bg-cyan-800 rounded-full text-[12px] font-normal py-1 px-2 text-center mx-2 text-lightgray cursor-pointer' >Download</span>
          </a>
          
        </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default ViewProject