import React,{useContext, useRef, useState, useEffect} from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import axios from 'axios';
import { useForm,  SubmitHandler } from "react-hook-form"
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosContext } from '../Axios/AxiosContext';
import { ColorRing } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

enum Dropdown {
    true = "True",
    false = "False",
}  

interface FormData {
    projectName: string;
    description: string;
    accountName: string;
    accountNumber: string;
    accountBankName: string;
    isCharted: boolean;
    titleSearchDone: boolean;
}
  
const EditProject: React.FC = ()=> {
    const { id } = useParams<any>();
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const {authAxios}  = useContext(AxiosContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [projectData, setProjectData] = useState({
      projectName: '',
      description: '',
      accountName: '',
      accountNumber: '',
      accountBankName: '',
      isCharted: true,
      titleSearchDone: true
    });
    const successNotification = () => toast.success('Project updated successfully.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  
   const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  

      useEffect(() => {
          
          authAxios.get(`/api/ivantage/agent/project?id=${id}`)
              .then(res => {
                if(res.data.data){
                    
                    setProjectData({
                      projectName: res.data.data.projectName,
                      description: res.data.data.description,
                      accountName: res.data.data.accountName,
                      accountBankName: res.data.data.accountBankName,
                      accountNumber: res.data.data.accountNumber,
                      isCharted: res.data.data.isCharted,
                      titleSearchDone: res.data.data.titleSearchDone
                      
                    })
                }
                setLoading(false);
              })
              .catch(() => {
                alert('There was an error while retrieving the data');
              });
          },[]);
        
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<FormData>()
    
    const onSubmit: SubmitHandler<FormData> = async(data) => {
        
        setLoading(true)
        // const formData = new FormData()
        // formData.append('projectName', data.projectName);
        // formData.append('description',  data.description);
        // formData.append('accountName', data.accountName);
        // formData.append('accountNumber', data.accountNumber);
        // formData.append('accountBankName', data.accountBankName);
        // formData.append('isCharted', JSON.stringify(data.isCharted));
        // formData.append('titleSearchDone', JSON.stringify(data.titleSearchDone));
        await authAxios.post(`${baseUrl}/api/ivantage/agent/project`, {
          projectName: data.projectName,
          description: data.description,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          accountBankName: data.accountBankName,
          isCharted: Boolean(data.isCharted),
          titleSearchDone: Boolean(data.titleSearchDone),
        },{
        headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then((res)=>{
        
        if(res.status == 200 || res.data.success==true){
          setLoading(false)
          successNotification()
          setTimeout(() => {
            navigate(`/project/${id}`)
          }, 2000);
        }
      }).catch(e=>{
        setLoading(false)
        if(e.response.data.message == 'Validation error')
        {
            errMessage.current = 'Validation error! A project with the same name exists.'
        }
        else{
            errMessage.current = e.response.data.message
        }
        errorNotification()
      })
    }

  return (
    <>
    <Header/>
    <div className='flex'>
      <SideBar/>

      <div className='p-8 bg-lightgray flex-1 overflow-hidden'>
            <h2 className='text-2xl mt-10'>Edit Project</h2>

            <section className='mt-4'>
            <div className='flex py-4 cursor-pointer' onClick={()=>navigate(-1)}>
        
            <p className='text-xs underline text-teal-600'>Go Back</p>
           </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='lg:flex  w-[100%]'>
                <div className=' my-2 px-4 lg:w-[50%]'>
                <label className='text-[14px]'>Project Name</label>
                <input 
                  type="text" 
                  defaultValue={projectData.projectName}
                  {...register("projectName", {required: true})}
                  className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs rounded placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                 {errors.projectName && <span className='text-xs text-red-600'>This field is required</span>}    
              </div>

              <div className=' my-2 px-4 lg:w-[50%]'>
                <label className='text-[14px]'>Account Name</label>
                <input 
                  type="text" 
                  defaultValue={projectData.accountName}
                  {...register("accountName", {required: true})}
                  className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 rounded'
                />
                 {errors.accountName && <span className='text-xs text-red-600'>This field is required</span>}    
              </div>
                </div>

                {/*  */}
                <div className='lg:flex w-[100%]'>
                <div className=' my-2 px-4 lg:w-[50%]'>
                <label className='text-[14px]'>Account Number</label>
                <input 
                  type="text" 
                  defaultValue={projectData.accountNumber}
                  {...register("accountNumber", {required: true, pattern:{value: /^[0-9]\d{0,9}$/,
                  message: "Enter a valid Account Number"}})}
                  className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 rounded'
                />
                  {errors.accountNumber && <span className='text-xs text-red-600'>This field is required</span>} 
                  <br />     
                  {errors.accountNumber && <p className='text-xs text-red-600 text-justify'>{errors.accountNumber.message}</p>}    
              </div>

              <div className='my-2 px-4 lg:w-[50%]'>
                <label className='text-[14px]'>Bank Name</label>
                <input 
                  type="text" 
                  defaultValue={projectData.accountBankName}
                  {...register("accountBankName", {required: true})}
                  className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 rounded'
                />
                 {errors.accountBankName && <span className='text-xs text-red-600'>This field is required</span>}    
              </div>
                </div>

                {/* description */}
            <div className=' my-2 px-4'>
              <label className='text-[14px] mb-4'>Description</label>
              <textarea
                rows={10}
                defaultValue={projectData.description}
                {...register("description", {required: true})}
                typeof='string'
                className='bg-lightgray border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 rounded'
              />
              {errors.description && <span className='text-xs text-red-600'>This field is required</span>}
            </div>

            <div className='lg:flex  w-[100%]'>
             {/* title search*/}
             <div className=' my-2 px-4  lg:w-[50%]'>
                <label className='text-[14px]'>Title Search Done</label>
                <select
                  {...register("titleSearchDone", {required: true})}
                  className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] rounded text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                >
                  {Object.values(Dropdown).map((item) => (
                    <option key={item} value={item}  defaultValue={JSON.stringify(projectData.titleSearchDone)} className='bg-lightgray p-1 rounded-none'>{item}</option>
                  ))}
                </select>    
                {errors.titleSearchDone && <span className='text-xs text-red-600'>Select an Option.</span>}
              </div>

              {/*  is charted */}
              <div className=' my-2 px-4 lg:w-[50%]'>
                <label className='text-[14px]'>Is Charted</label>
                <select
                  defaultValue={JSON.stringify(projectData.isCharted)} 
                  {...register("isCharted", {required: true})}
                  className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                >
                  {Object.values(Dropdown).map((item) => (
                    <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                  ))}
                </select>    
                {errors.isCharted && <span className='text-xs text-red-600'>Select an Option.</span>}
              </div>
            </div>


                 {/* submit button */}
            <div className='text-end mt-10 mb-4' >
            {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
              <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 hover:bg-gray hover:text-teal-600 ' type='submit'> CREATE 
              </button>

            }
            </div>
            </form>

            </section>
      </div>
       {/* Toast container for notifications */}
       
    </div>
    </>
  )
}

export default EditProject