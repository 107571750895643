import React,{useState, useRef, useContext, useEffect, MutableRefObject } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { WithContext as ReactTags } from "react-tag-input";
import {CancelOutlined, CheckCircleOutline, InfoOutlined, KeyboardBackspaceOutlined, RadioButtonUncheckedOutlined, RoomOutlined} from '@mui/icons-material';
import Header from '../Header';
import SideBar from '../SideBar';
import axios from 'axios';
import Map,{Marker, Popup} from 'react-map-gl';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosContext } from '../Axios/AxiosContext';
import { useForm,  SubmitHandler,Controller, useWatch } from "react-hook-form"
import { ColorRing } from 'react-loader-spinner';
import { customAlphabet } from 'nanoid'
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer';
import clsx from 'clsx';
import { originalData } from './data';

enum PropertyStatus {
  UnderConstruction = "Under Construction",
  Completed = "Completed",
  OffPlan = "Off Plan"
}

enum PropertyCategory {
  ForSale = "For Sale",
  ForRent = "For Rent",
}

enum RentTimeframe {
  PerAnnum = "Per Annum",
  PerMonth = "Per Month",
}

enum Amenity {
  Furnished = "Furnished",
  SeaView = "Sea View",
  Aircon = "Aircon",
  Elevator = "Elevator",
  StaffQuarters = "Staff Quarters",
  Intercom = "Intercom",
  Shower = "Shower",
  Gym = "Gym",
  Jacuzzi = "Jacuzzi",
  CableTVInstalled = "Cable TV installed",
  Garage = "Garage",
  ClubHouse = "Club House",
  ElectricFencing = "Electric Fencing",
  Pantry = "Pantry",
  Pool = "Pool",
  Mini = "Mini",
  Fence = "Fence",
  Alarm = "Alarm",
  Borehole = "Borehole",
  AllRoomsEnsuite = "All Rooms Ensuite",
  Generator = "Generator",
  Storage = "Storage",
  BuiltInCupboards = "Built In Cupboards",
  SquashCourt = "Squash Court",
  JettyBerth = "Jetty Berth",
  TennisCourt = "Tennis Court",
  SelfContained = "Self-contained",
  Laundry = "Laundry",
  SecurityPost = "Security Post",
  Inverter = "Inverter",
  WalkInCloset = "Walk in Closet",
  Bathtub = "Bathtub",
  FamilyTvRoom = "Family Tv Room",
  AccessGate = "Access Gate",
  PetsAllowed = "Pets Allowed",
  Study = "Study",
  Kitchen = "Kitchen",
  Serviced = "Serviced",
  GuestToilet = "Guest Toilet",
  Balcony = "Balcony",
  POP = "P.O.P",
  Deck = "Deck",
  SemiDetached = "Semi-detached",
  Detached = "Detached",
  ScenicView = "Scenic View",
  WheelChairFriendly = "Wheel Chair Friendly",
  Garden = "Garden",
  BoysQuarters = "Boys Quarters"
}

interface Tag {
  id: string;
  text: string;
}

interface ImageTag {
  id: string;
  imageUrl: string;
}
interface State {
  name: string;
  localities: string[];
}

interface PropertyType {
  type: string;
  subtype: string[];
}

interface FormData {
  ProjectId: string;
  propertyName: string;
  propertyType: string;
  propertySubtype: string;
  mapLocation: number[];
  address: string;
  state: string;
  locality: string;
  propertySize: string;
  status: PropertyStatus;
  rentTimeframe: string;
  propertyCategory: PropertyCategory;
  price: number;
  totalUnits: number;
  availableUnits: number;
  numberofBedrooms: number;
  numberofBathrooms: number;
  numberofToilets: number;
  propertyFeatures: string[];
  description: string;
  isFurnished: boolean,
  isServiced: boolean,
  serviceCharge: number,
  serviceChargeTimeframe: string,
  refundableCaution: number,
  electricityCharge: number,
  parkingSpace: number,
  completionDate: Date;
}


const EditProperty: React.FC = () => {

const navigate = useNavigate()
const [maplocation, setMaplocation] = useState<boolean>(false)
// Handle Image tags
const [imageTags, setImageTags] = useState<ImageTag[]>([]);
const Images:MutableRefObject<File[]> = useRef<File[]>([]);
const [preview, setPreview] = useState<boolean>(false);
const [img, setImg] = useState<string>('')
const [imgId, setImgId] = useState<string>('')
const maplocationdata = useRef<number[]>([0,0])
const [location , setLocation] = useState<any>()
const [locationdata , setLocationdata] = useState<number[]>()
const [showMap, setShowMap] = useState<boolean>(false)
const errMessage = useRef<string>('')
const {authAxios}  = useContext(AxiosContext)
const [video, setVideo] = useState<File>()
const [flyer, setFlyer] = useState<File>()
const [flyerUrl, setflyerUrl] = useState<string>('')
const [videoUrl, setvideoUrl] = useState<string>('')
const MAX_STEPS = 3;
const [inputText, setInputText] = useState<string>('');
const [checkedAmenities, setCheckedAmenities] = useState<string[]>([]);
const [selectedState, setSelectedState] = useState<string>('');
const [selectedLocality, setSelectedLocality] = useState<string>('');
const [selectedType, setSelectedType] = useState<string>('');
const [selectedSubtype, setSelectedSubtype] = useState<string>('');


const amenities: string[] = Object.values(Amenity);

const filterAmenities = (text: string): string[] => {
    const filteredAmenities: string[] = [];
    const regex = new RegExp(text, 'gi');

    amenities.forEach((amenity: string) => {
        if (amenity.match(regex)) {
            filteredAmenities.push(amenity);
        }
    });

    return filteredAmenities;
};

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
};

const existingAmenities = filterAmenities(inputText);
const highlightMatchingLetters = (text: string, match: string) => {
  return text.replace(new RegExp(match, 'gi'), `<b className='font-bold'>${match}</b>`);
};


const checkedFeatures = useRef<string []>([])
const [checkedState, setCheckedState] = useState<string[]>([]);
// const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const { value, checked } = event.target;
//   if (checked) {
//       // setCheckedAmenities(prevState => [...prevState, value]);
//       checkedFeatures.current.push(value);
//   } else {
//       // setCheckedAmenities(prevState => prevState.filter(item => item !== value));
//       checkedFeatures.current = checkedFeatures.current.filter(item => item !== value);
//   }
 
 
// };

const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let updatedCheckedState = [...checkedState];

    if (checked) {
        // Add item to checked state and ref
        updatedCheckedState.push(value);
        checkedFeatures.current.push(value);
    } else {
        // Remove item from checked state and ref
        updatedCheckedState = updatedCheckedState.filter(item => item !== value);
        checkedFeatures.current = checkedFeatures.current.filter(item => item !== value);
    }

    setCheckedState(updatedCheckedState);
};
// loading
const [loading, setLoading] = useState<boolean>(false)
const [maploading, setMapLoading] = useState<boolean>(false)

// form steps
const [formStep, setFormStep] = React.useState(0)
const { id } = useParams<any>();

useEffect(() => {
  
  authAxios.get(`/api/ivantage/agent/property?id=${id}`)
      .then(res=> {
          if(res.data.data){
            const [latitudeStr, longitudeStr] = res.data.data.mapLocation.split(',');
            const features = res.data.data.propertyFeatures
            checkedFeatures.current = [...features]
            setCheckedState(checkedFeatures.current);
            }
        setLoading(false);
      })
      .catch((e) => {
        
      });
  },[id]);


// Handle file selection
const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
const file = e.target.files?.[0];
if (file) {
    setImg(URL.createObjectURL(file));
}
};

// Preview Image
const previewImage = (url: string, id:string): void => {
  if (url) {
    
    setImg(url);
    setImgId(id)
    setPreview(!preview);
    
  }
};

// state and locality
const transformedData = Object.entries(originalData).map(([state, localities]) => {
  return {
      name: state,
      localities: localities
  };
});

const states: State[] = transformedData

// property type and subtype
const propertyTypes: PropertyType[] = [
     {
      type: 'Flats & Apartment',
      subtype: [
        'Block of Flat',
        'Mini Flat',
        'Self Contain',
        'Shared Apartment',
        'Studio Apartment'
      ]
     },
     {
      type: 'Fully Detached',
      subtype: [
        'Fully Detached Duplex',
        'Fully Detached Bungalow',
      ]
     },
     {
      type: 'Semi Detached',
      subtype: [
        'Semi Detached Duplex',
        'Semi Detached Bungalow',
      ]
     },
     {
      type: 'Terrace',
      subtype: [
        'Terrace Duplex',
        'Terrace Bungalow',
      ]
     },
     {
      type: 'Maisonnette',
      subtype: [
        
      ]
     },
     {
      type: 'Penthouse',
      subtype: [

      ]
     },
     {
     type: 'Townhouse',
      subtype: [

      ]
     },
     {
    type: 'Shell',
      subtype: [

      ]
     },
     {
      type: 'Site and Services',
      subtype: [

      ]
     },
     {
      type: 'Short Stay',
      subtype: [

      ]
     },
     {
      type: 'Bareland',
      subtype: [
          'Commercial Land',
          'Industrial Land',
          'Joint Venture Land',
          'Mixed-Use Land',
          'Residential Land',
      ]
     },

]



const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  handleFileSelect(event)
  const files = event.target.files;
  if(Images.current.length > 9){
    event.target.value = ''
    toast.error('You can only upload a maximum of 10 images.',{
      position: "top-right",
      
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      }) 
  }

  if (files) {
    const updatedImageTags: ImageTag[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const id = file.name
      // `image_${Date.now()}_${i}`;
      const imageUrl = URL.createObjectURL(file);
      updatedImageTags.push({ id, imageUrl });
    }
   const updatedImages= []
   for (let i = 0; i < files.length; i++) {
    const file = files[i];
    updatedImages.push(file);
    // file.append('photos', file)
  }
    setImageTags([...imageTags, ...updatedImageTags]);
    Images.current = [...Images.current,...updatedImages]
  }
  
};


const setDisplayImage = (img:string, id: string,e: React.MouseEvent<HTMLButtonElement>)=>{
  e.preventDefault()
  
  const file = new File([img], id )
  Images.current.map((item)=>{
    if(item.name == file.name){
      
      let index = Images.current.indexOf(item)
      Images.current.splice(index, 1);
      Images.current.unshift(item);
      
      toast.success('Display image successfully set.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    setTimeout(()=> setPreview(!preview), 1000)
    }
  })
  

}

const handleRemove = (id: string) => {
  setImageTags(imageTags.filter((imageTag) => imageTag.id !== id));
};

// Prevent input of negative values
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  };

const Projects = useRef<any[]>([])
const [projectsLoaded, setProjectsLoaded] = useState(false);
// get all projects
useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await authAxios.get('/api/ivantage/agent/projectList');
      if (res.status === 200 || res.data.success === true) {
        Projects.current = res.data.data;
       
        setProjectsLoaded(true)
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []); 
// notifications
const successNotification = () => toast.success('Publication has been successfully updated and is now awaiting approval.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

const errorNotification = () => toast.error((errMessage.current), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

function numberToWords(number: number) {
 
        const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety', 'hundred'];
    
        if (number === 0) {
            return 'zero';
        }
    
        // Function to convert a three-digit number to words
        function convertThreeDigit(num:number) {
            let words = '';
            if (num >= 100) {
                words += ones[Math.floor(num / 100)] + ' hundred ';
                num %= 100;
                if (num !== 0) {
                  words += 'and ';
              }
            }
            if (num >= 20) {
                words += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            } else if (num >= 10) {
                words += teens[num - 10] + ' ';
                return words.trim(); // Return early if the number is a teen
            }
            if (num > 0) {
                words += ones[num] + ' ';
            }
            return words.trim();
        }
    
        let words = '';
        if (number < 0) {
            words = 'negative ';
            number = Math.abs(number);
        }
    
        // Split the number into groups of three digits
        const groups = [];
        while (number > 0) {
            groups.push(number % 1000);
            number = Math.floor(number / 1000);
        }
    
        // Convert each group to words and append scale (thousand, million, etc.)
        const scale = ['', 'thousand,', 'million,', 'billion,', 'trillion,']; // Add more scales as needed
        for (let i = groups.length - 1; i >= 0; i--) {
            const groupWords = convertThreeDigit(groups[i]);
            if (groupWords) {
                words +=  groupWords + ' ' + scale[i] + ' ' ;
            }
        }
      
        return words.trim();
    }
    
// form data--submission
const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }, trigger, getValues 
  } =useForm<FormData>({
    defaultValues: async (): Promise<FormData> => {
      try {
        const response = await authAxios.get(`/api/ivantage/agent/property?id=${id}`);
        const data = response.data.data;
       
        return {
          ProjectId: data.ProjectID || '',
          propertyName: data.propertyName || '',
          propertyType: data.propertyType || '',
          propertySubtype: data.propertySubtype || '',
          address: data.address || '',
          state: data.state || '',
          locality: data.locality || '',
          propertySize: data.propertySize || '',
          status: data.status || '',
          rentTimeframe: data.rentPeriod || '',
          propertyCategory: data.propertyCategory || '',
          price: Number(data.price) || 0,
          totalUnits: data.totalUnits || '',
          availableUnits: Number(data.avalableUnits) || 0,
          numberofBedrooms: Number(data.numberofBedrooms) || 0,
          numberofBathrooms: Number(data.numberofWashrooms) || 0,
          numberofToilets: Number(data.numberofWashrooms) || 0,
          propertyFeatures: data.propertyFeatures || '',
          description: data.description || '',
          isFurnished: data.isFurnished || false,
          isServiced: data.isServiced || false,
          serviceCharge: data.serviceCharge || '',
          serviceChargeTimeframe: data.serviceChargePeriod || '',
          refundableCaution: data.refundableCaution || '',
          electricityCharge: data.electricityCharge || '',
          parkingSpace: Number(data.parkingSpace) || 0,
          completionDate: data.completionDate || '',
          mapLocation: data.maplocation || '',
        };
      } catch (error) {
        console.error('Error fetching default values:', error);
        // You can either return default values or re-throw the error here
        throw error;
      }
    }
  })
  
const [step, setStep] = useState(1);
const [completedSteps, setCompletedSteps] = useState<number[]>([]);
const completeData = useRef<FormData>()


const handlePreviousStep = () => {
  setStep(step - 1);
};

const handleNextStep = async() => {
  // Validate current step fields before proceeding to the next step
 
  const stepValidation = await trigger();
 
 
  if(step === 1){
    trigger('propertyName',{ shouldFocus: true });
    trigger('price',{ shouldFocus: true });
    trigger('propertyCategory',{ shouldFocus: true });
    trigger('propertyType',{ shouldFocus: true });
    trigger('status',{ shouldFocus: true });
    trigger('rentTimeframe',{ shouldFocus: true });
    trigger('state',{ shouldFocus: true });
    trigger('address',{ shouldFocus: true });
    trigger('numberofBathrooms',{ shouldFocus: true });
    trigger('numberofBedrooms',{ shouldFocus: true });
    trigger('numberofToilets',{ shouldFocus: true });
    trigger('propertySize',{ shouldFocus: true });
    trigger('totalUnits',{ shouldFocus: true });
    trigger('availableUnits',{ shouldFocus: true });
    trigger('description',{ shouldFocus: true });
  }
if(stepValidation){
    // Save current step's data
 
   
    const data = getValues();
   

    if(step === 1){
   
    completeData.current = data
   
   
    }
   
    // Move to the next step
   
    if (step < 2) {
      setStep(step + 1);
      setCompletedSteps([...completedSteps, step]);
    }
}
  
};

const watchPrice = watch('price')
const watchCategory = watch('propertyCategory')
const watchServiceCharge = watch('serviceCharge')
const watchRefundableCaution = watch('refundableCaution')
const watchElectricityCharge = watch('electricityCharge')
const watchIsServiced = watch('isServiced')
const watchAddress = watch('address')
const finalData = getValues()
const watchPT = watch('propertyType')

const watchState = watch('state')
const watchLocality = watch('locality')

const onSubmit: SubmitHandler<FormData> = async(data) => {
    setLoading(true)
    const formData = new FormData()
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 7)
    const propertycode = 'pty-rtl'+nanoid()
   
 
   
    const joinedlocation = `${watchAddress},${watchLocality},${watchState}`
    await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${joinedlocation}.json?country=NG&language=en&limit=1&access_token=pk.eyJ1IjoibWFyeS1vZ3VubW9sYSIsImEiOiJjbHNreXl4b2QwOGhoMndyMG1nc3RkbTljIn0.lWhiPPeajrb52VQUudiIgw`)
    .then(res => {
      
      setMapLoading(false)
      maplocationdata.current =  res.data.features[0].center;
      setLocationdata(res.data.features[0].center)
      formData.append('mapLocation',`${maplocationdata.current[1]},${maplocationdata.current[0]}`);
     
     
    
    }).catch((e) => {
        maplocationdata.current = [];
    });

   
   
    formData.append('ProjectId', Projects.current[0].id)
    // formData.append('propertyCode', propertycode);
    formData.append('propertyName', data.propertyName);
    formData.append('propertyCategory', data.propertyCategory);
    formData.append('mapLocation',`${maplocationdata.current[1]},${maplocationdata.current[0]}`);
     
    formData.append('propertyType', data.propertyType);
    formData.append('propertySubtype', data.propertySubtype);
    formData.append('numberOfBedrooms', data.numberofBedrooms.toString());
    formData.append('numberOfWashrooms', data.numberofBathrooms.toString());
    formData.append('numberOfToilets', data.numberofToilets.toString());
    formData.append('availableUnits', data.availableUnits.toString());
    formData.append('totalUnits', data.totalUnits.toString());
    formData.append('price', data.price.toString());
    formData.append('propertySize',  data.propertySize.toString());
    formData.append('propertyFeatures', checkedFeatures.current.join(','));
    formData.append('status', data.status);
    formData.append('rentPeriod', data.rentTimeframe);
    formData.append('description', data.description);
    formData.append('address', data.address);
    formData.append('state', data.state);
    formData.append('locality', data.locality);
    if(data.completionDate){
      formData.append('completionDate', data.completionDate?.toString());
    }
   if(data.isServiced){
    formData.append('isServiced', data.isServiced?.toString());
   }
   
    formData.append('isFurnished', data.isFurnished?.toString());
    formData.append('serviceCharge', data.serviceCharge?.toString());
    formData.append('serviceChargePeriod', data.serviceChargeTimeframe);
    formData.append('refundableCaution', data.refundableCaution?.toString());
    formData.append('electricityCharge', data.electricityCharge?.toString());
    formData.append('parkingSpace', data.parkingSpace?.toString());

  
    await authAxios.put(`api/ivantage/agent/editAgentProperty/${id}`,{
      propertyName: data.propertyName,
      propertyCategory: data.propertyCategory,
      mapLocation: `${maplocationdata.current[1]},${maplocationdata.current[0]}`,
      propertyType: data.propertyType,
      propertySubtype: data.propertySubtype,
      numberOfBedrooms: data.numberofBedrooms,
      numberOfWashrooms: data.numberofBathrooms,
      numberOfToilets: data.numberofToilets,
      availableUnits: data.availableUnits,
      totalUnits: data.totalUnits,
      price: data.price,
      propertySize: data.propertySize,
      propertyFeatures: checkedFeatures.current.join(','),
      status: data.status,
      rentPeriod: data.rentTimeframe,
      description: data.description,
      address: data.address,
      state: data.state,
      locality: data.locality,
      isFurnished: data.isFurnished,
      serviceCharge: data.serviceCharge,
      serviceChargePeriod: data.serviceChargeTimeframe,
      refundableCaution: data.refundableCaution,
      electricityCharge: data.electricityCharge,
      parkingSpace: data.parkingSpace,
    }).then((res)=>{
   
   
    if(res.status == 200 || res.data.success==true){
      setLoading(false)
      successNotification()
      setTimeout(() => {
        navigate(`/submitted-properties`)
      }, 1000);
    }
  }).catch(e=>{
   
    setLoading(false)
    errMessage.current = e.response.data.message
    toast.error((errMessage.current), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    if(e.response.status == 400 )
    {
        errMessage.current = 'Property Exists!'
    }
    else{
        errMessage.current = e.response.data.message
    }
    errorNotification()
  })
}


// check map location
const fullLocation = `${watchAddress},${watchLocality},${watchState}`
const handleMapLocation = async()=>{

  await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${fullLocation}.json?country=NG&language=en&limit=1&access_token=pk.eyJ1IjoibWFyeS1vZ3VubW9sYSIsImEiOiJjbHNreXl4b2QwOGhoMndyMG1nc3RkbTljIn0.lWhiPPeajrb52VQUudiIgw`)
  .then(res => {
    
    setMapLoading(false)
    maplocationdata.current =  res.data.features[0].center;
    setLocationdata(res.data.features[0].center)
    setShowMap(true)
    setMaplocation(true)

    setTimeout(()=>{
      setShowMap(false)
      setMaplocation(!maplocation)
    },2000)
  })
  .catch((e) => {
    toast.error('There was an error locating address!. Enter a correct address.',{
      position: "top-right",
      
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      }) 
      maplocationdata.current = [];
  });
}

  return (
    <>
    <Header/>
    <div className='flex'>

      <SideBar/>

     <div>
      <div className=''>
      <div className='p-10 bg-lightgray w-[100%] overflow-hidden'>
        <section className=''>
        {/* <div className='flex mt-6 pb-2 cursor-pointer' onClick={()=>navigate(-1)}>
        <KeyboardBackspaceOutlined sx={{fontSize: 20, color:'#00838F'}} />
        </div> */}
         <p className='lg:mt-10 mt-16  underline text-teal-500 text-sm mb-2' onClick={()=>navigate(-1)}>Go back</p>
          <h2 className='text-2xl '>Edit Property </h2>
        </section>
        <section className='flex items-center pt-4 pb-8 w-full px-4'>
      {/* Steps with progress */}
      <div className="flex w-full ">
        {/* Step 1 */}
        <div className="flex-1 text-center items-center mr-1">
          <span className="text-[14px] text-center text-teal-600">Property Details</span>
          <div className='flex flex-row justify-center items-center'>
         
                <div className='h-1 flex-1 bg-teal-600'></div>
                <CheckCircleOutline sx={{fontSize: 20, color:'#00897B'}}/>
                <div className='h-1 flex-1 bg-teal-600'></div>
            
            
          </div>
        </div>
        
       
        {/* Step 3 */}
        <div className="flex-1 text-center items-center  lg:mt-0 md:mt-0 mt-6">
          <span className={clsx("text-[14px] text-center text-mediumgray", { 'text-[14px] text-center text-teal-600': completedSteps.includes(1) })}>Submit</span>
          <div className='flex flex-row justify-center items-center'>
            {completedSteps.includes(2) ? (
              <>
                <div className='h-1 flex-1 bg-cyan-800'></div>
                <CheckCircleOutline  sx={{fontSize: 20, color:'#00897B'}} />
                <div className='h-1 flex-1 bg-cyan-800'></div>
              </>
            ) : (
              <>
                <div className='h-1 flex-1 bg-mediumgray'></div>
                <RadioButtonUncheckedOutlined  sx={{fontSize: 20, color:'#BDBDBD'}}/>
                <div className='h-1 flex-1 bg-mediumgray'></div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
        <section>
          <form action="" onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            { step === 1 &&
            (
            <div>
            <div className='lg:flex  w-[100%]'>
                <div className=' my-3 px-4 lg:w-[50%]'>
                  {/* project ID */}
                  <label className='text-[14px]'>Property Name<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <input 
                    type="text"
                 
                    {...register("propertyName", {required: true})}
                    className='bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  {errors.propertyName && <span className='text-xs text-red-600'>This field is required</span>}
               
                </div>

                    {/* property price */}
                    <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] mb-4'>Price<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <div className='flex bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1'>
                  <p className='text-darkgray'>&#x20A6;</p>
                  <span className='mx-1 border-[1px] text-darkgray'></span>
                  <input 
                    type="number"
               
                    onKeyDown={handleKeyDown}
                    //value={watchPrice?.toString().replace(/\B(?=(\d{10})+(?!\d))/g, ",")}
                    min="0"
                    {...register("price", {required: true})}
                    className='bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                
                  
                  </div>
                  {errors.price && <span className='text-xs text-red-600'>This field is required</span>}
                  <p className='text-teal-600 capitalize'>
                   {watchPrice && numberToWords(watchPrice)} naira
                  </p>
                
                  
                </div>  

                {/* Catergory */}
                <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] mb-4'>Category<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <select
                    {...register("propertyCategory", {required: true})}
                     
                     
                    className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] rounded text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  ><option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Category</option>
                    {Object.values(PropertyCategory).map((item) => (
                      <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                    ))}
                  </select>    
                  {errors.propertyCategory && <span className='text-xs text-red-600'>Select a Category.</span>}
                </div>  

              </div>

                 <div className='lg:flex w-[100%]'>

        {/* price timeframe */}
        {
          watchCategory === PropertyCategory.ForRent &&

        <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
        <label className='text-[14px]'>Rent Timeframe<span className='text-teal-400  text-xl absolute' > *</span></label>
        <select
          {...register("rentTimeframe")}
       
          className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
        >
          <option key='' value='' className='bg-lightgray p-1 rounded-none'></option>
          {Object.values(RentTimeframe).map((status) => (
            
            <option key={status} value={status} className='bg-lightgray p-1 rounded-none'>{status}</option>
          ))}
        </select>    
        {errors.status && <span className='text-xs text-red-600'>This field is required</span>}
                </div>       
        }     
        {/* property status */}
        {
          
        watchCategory === PropertyCategory.ForSale &&
        <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
        <label className='text-[14px]'>Property Status<span className='text-teal-400  text-xl absolute' > *</span></label>
        <select
          {...register("status")}
         
          className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
        ><option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Status</option>
          {Object.values(PropertyStatus).map((status) => (
            <option key={status} value={status} className='bg-lightgray p-1 rounded-none'>{status}</option>
          ))}
        </select>    
        {errors.status && <span className='text-xs text-red-600'>This field is required</span>}
        </div>
        }

        {
  
        watchCategory === PropertyCategory.ForRent &&
        <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
          <label className='text-[14px]'>Property Status<span className='text-teal-400  text-xl absolute' > *</span></label>
          <select
            {...register("status")}
          
            className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
          >
            
              <option key='Completed' value='Completed' className='bg-lightgray p-1 rounded-none'>Completed</option>
            
          </select>    
          {errors.status && <span className='text-xs text-red-600'>This field is required</span>}
        </div>
      }
      {
        
          watchCategory === PropertyCategory.ForSale &&

      <div className='my-3 px-4 lg:w-[50%] w-[100%]'>
        <label className='text-[14px]'>Completion Date</label>
        <input
     
          {...register("completionDate")}
          type='date'
          className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
        />
        {/* {errors.completionDate && <span className='text-xs text-red-600'>This field is required</span>} */}
      </div>
      }

      </div>
                  <div  className='lg:flex w-[100%]'>
                      {/* property type */}
                 <div className=' my-3 px-4  lg:w-[50%]'>
                  <label className='text-[14px]'>Property Type<span className='text-teal-400  text-xl absolute' > *</span></label>
                 
              
                      <select

                        {...register('propertyType', {required: true})}
                        className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] rounded text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                      >
                        <option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Property Type</option>
                        {propertyTypes.map((property, index) => (
                            <option key={index} value={property.type}>{property.type}</option>
                          ))}
                      </select>   
         
                        
         
                  {errors.propertyType && <span className='text-xs text-red-600'>Select a type.</span>}
                </div>
                {/* subtype */}
                <div className=' my-3 px-4  lg:w-[50%]'>
                  <label className='text-[14px]'>Subtype</label>
                
                   <select
                  {...register('propertySubtype')}
                    className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1 w-[100%] rounded text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  > <option value="">Select Subtype</option>
                    {propertyTypes.find(property => property.type === watchPT)?.subtype.map((subtype, index) => (
                      <option key={index} value={subtype}>{subtype}</option>
                    ))}
                  </select>    
                    
                </div>
                 </div>
             

              <hr className='border-[1px] border-gray w-[80%] mx-auto  my-8'/>
              <div className='lg:flex w-[100%]'>
              
              {/* state */}
              <div className=' my-3 px-4 lg:w-[50%] w-[100%]'>
                <label className='text-[14px] mb-4'>State<span className='text-teal-400  text-xl absolute' > *</span></label>
              
                          <select
                             {...register('state', {required: true})}
                          className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  >
                     <option value="">Select State</option>
                     {states.map((state, index) => (
                        <option key={index} value={state.name}>{state.name}</option>
                      ))}
                    
                  </select>   
            
                  {errors.state && <span className='text-xs text-red-600'>Select a State.</span>}
              </div>

              {/* Locality */}
              <div className=' my-3 px-4 lg:w-[50%] w-[100%]'>
                <label className='text-[14px] mb-4'>Locality</label>
                
                <select
                       {...register('locality')}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  > <option value="">Select a Locality</option>
                     {states.find(state => state.name === watchState)?.localities.map((locality, index) => (
                      <option key={index} value={locality}>{locality}</option>
                    ))}
                  </select>    
                   
              </div>
            </div>

            <div className='w-[100%]'>
                {/* address */}
                <div className=' my-3 px-4 w-[100%]'>
                <label className='text-[14px] mb-4'>Address<span className='text-teal-400  text-xl absolute' > *</span></label>
                <input 
                  type="field" 
                  {...register("address", {required: true})}
                  className='bg-lightgray rounded border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                {errors.address && <span className='text-xs text-red-600'>This field is required</span>}
              </div>
              <div className='px-4 cursor-pointer' onClick={handleMapLocation}>
                  <p className='text-xs text-darkgray'>View map</p>
                </div>
                
      
            </div>

              {/* map */}
              <div  className='lg:flex  w-[100%]'>
              
                <div className=' my-3 px-4 lg:w-[50%]'>
               
            
              {maplocation && (
                  <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className='bg-white px-10 py-4'>
                          <div className='text-end my-3' onClick={()=> {
                            setShowMap(false)
                            setMaplocation(!maplocation)}}>
                            <button className=' text-sm text-center py-2 px-4  rounded-full font-semibold border-cyan-900 bg-gray text-cyan-900 ' type='submit'> 
                            <CancelOutlined/>
                            </button>
                      </div>
                    
                  
                        {
                          maploading?
                          <div className='flex h-[250px] items-center justify-center'>
                          <ColorRing
                          visible={true}
                          // height="50"
                          width="100%"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                          />  
                      </div>
                        :
                    <div>
                      
                        <div>
                          <div className='font-semibold text-[14px]'>
                            {fullLocation}
                          </div>
                          <Map
                          reuseMaps={true}
                          mapLib={import('mapbox-gl')}
                          mapboxAccessToken='pk.eyJ1IjoibWFyeS1vZ3VubW9sYSIsImEiOiJjbHNreXl4b2QwOGhoMndyMG1nc3RkbTljIn0.lWhiPPeajrb52VQUudiIgw'
                          initialViewState={{
                            longitude: maplocationdata.current[0],
                            latitude: maplocationdata.current[1],
                            zoom: 12
                          }}
                          style={{width: 500, height: 250}}
                          mapStyle="mapbox://styles/mapbox/streets-v9"
                        >
                          <Marker latitude={maplocationdata.current[1]} longitude={maplocationdata.current[0]}>
                            <RoomOutlined/>
                          </Marker>     

                          <Popup longitude={maplocationdata.current[0]} latitude={maplocationdata.current[1]} anchor="bottom"> 
                            Longitude: {maplocationdata.current[0]}
                            <br />
                            Latitude: {maplocationdata.current[1]}
                          </Popup>    
                        </Map>
                    </div>
                    </div>
                    }
                  </div>
                  </div>)}
                </div>
                </div>


            
            <hr className='border-[1px] border-gray w-[80%] mx-auto  my-8'/>

              <div className='lg:flex w-[100%]'>
               

    

                <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                {/* number of bedrooms */}
                  <label className='text-[14px]'>Number of Bedrooms<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <input 
                    type="number"
                    onKeyDown={handleKeyDown}
                    min="0"
                    {...register("numberofBedrooms", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  {errors.numberofBedrooms && <span className='text-xs text-red-600'>This field is required</span>}
                </div>  
                
              {/* number of bathrooms and toilets */} 
                <div className='my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] '>Number of Bathrooms<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <input 
                    type="number"
                    onKeyDown={handleKeyDown}
                    min="0"
                    {...register("numberofBathrooms", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  {errors.numberofBathrooms && <span className='text-xs text-red-600'>This field is required</span>}
                </div>
                <div className=' my-3 px-4  lg:w-[50%] w-[100%] '>
                  <label className='text-[14px]'>Number of Toilets<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <input 
                    type="number"
                    onKeyDown={handleKeyDown}
                    min="0"
                    {...register("numberofToilets", {required: true})}
                    className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  {errors.numberofToilets && <span className='text-xs text-red-600'>This field is required</span>}
                </div>
                </div>

                <div className='  lg:flex  w-[100%] '>
                   {/* property size */}
              <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px]'>Property Size<span className='text-teal-400  text-xl absolute' > *</span></label>
                  <div className=' flex bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'>
                  <input 
                    type="text" 
                    {...register("propertySize", {required: true})}
                    className=' bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  <span className='mx-1 border-[1px] text-darkgray'></span>
                  <p className='text-darkgray'>sqm</p>
                  </div>
                 
                  {errors.propertySize && <span className='text-xs text-red-600'>This field is required</span>}
                </div>
               
                <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px]'>Parking Space</label>
                  <div className=' flex bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'>
                  <input 
                    type="number" 
                    className=' bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  
                  </div>
                 
                  {errors.propertySize && <span className='text-xs text-red-600'>This field is required</span>}
                </div>


                </div>

              <div className='lg:flex w-[100%]'>
                {/* total units */}
              <div className='my-3 px-4  lg:w-[50%] w-[100%]'>
                <label className='text-[14px] mb-4'>Total Units<span className='text-teal-400  text-xl absolute' > *</span></label>
                <input 
                  type="number"
                  onKeyDown={handleKeyDown}
                  min="0"
                  {...register("totalUnits", {required: true})}
                  className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                {errors.totalUnits && <span className='text-xs text-red-600'>This field is required</span>}
              </div>
              {/* available units */}
              <div className='my-3 px-4  lg:w-[50%] w-[100%]'>
                <label className='text-[14px] mb-4'>Available Units<span className='text-teal-400  text-xl absolute' > *</span></label>
                <input 
                  type="number"
                  onKeyDown={handleKeyDown}
                  min="0"
                  {...register("availableUnits", {required: true})}
                  className='bg-lightgray mt-1 rounded border-darkgray border-[1.5px] p-1 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                />
                {errors.availableUnits && <span className='text-xs text-red-600'>This field is required</span>}
              </div>
              </div>  

              {/* checkboxes */}
              <div className='flex '>
                  <div className='flex  my-3 pr-4'>
                    <input type="checkbox" id="" value='true'  {...register("isFurnished")}className='w-12 pl-0 ml-0 accent-teal-600'/>
                    
                    <label className='text-[14px] '>Furnished</label>
                  </div>

                  <div className='flex  my-3 px-4'>
                    <input type="checkbox" id=""  value='true'    {...register("isServiced")} className='w-12 accent-teal-600' />
                    <label className='text-[14px]  '>Serviced</label>
                  </div>
              </div>
              {
                  watchIsServiced &&
              
              <div className='lg:flex w-[100%]'>
                {/* service charge */}
                <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] mb-4'>Service Charge Deposit</label>
                  <div className='flex'>
                    <div 
                   className={
                    watchServiceCharge <= 0 
                    ? 'flex bg-lightgray rounded w-[100%] mt-1 border-darkgray border-[1.5px] p-1' 
                    : 'flex bg-lightgray rounded-l mt-1 border-darkgray border-[1.5px] p-1'
                   }
                    >
                    <p className='text-darkgray'>&#x20A6;</p>
                    <span className='mx-1 border-[1px] text-darkgray'></span>
                    <input 
                      type="number"
                  
                      onKeyDown={handleKeyDown}
                      min="0"
                      {...register("serviceCharge")}
                      className='bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                    />
                  </div>
                  {watchServiceCharge > 0 &&
                  <div className='w-[40%]'>
                  <select
                      {...register("serviceChargeTimeframe")}
                 
                      className='bg-lightgray mt-1 rounded-r border-l-0 border-darkgray border-[1.5px] py-2 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                    >
                     
                        <option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Period</option>
                        <option key='' value='' className='bg-lightgray p-1 rounded-none'>per Day</option>
                        <option key='' value='' className='bg-lightgray p-1 rounded-none'>per Month </option>
                        <option key='' value='' className='bg-lightgray p-1 rounded-none'>per Annum</option>
                      
                    </select>   
                    {/* {errors.serviceChargeTimeframe && <span className='text-xs text-red-600'>Select a </span>}  */}
                  </div>
                  }
                 </div>
                 <p className='text-teal-600 capitalize'>
                   {watchServiceCharge && numberToWords(watchServiceCharge)} naira
                  </p>
                 </div>
                 
                 {/* refundable caution deposit */}
                 <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] mb-4'>Refundable Caution Deposit</label>
                  <div className='flex bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1'>
                  <p className='text-darkgray'>&#x20A6;</p>
                  <span className='mx-1 border-[1px] text-darkgray'></span>
                  <input 
                    type="number"
                    onKeyDown={handleKeyDown}
               
                    min="0"
                    {...register("refundableCaution")}
                    className='bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                 </div>
                 <p className='text-teal-600 capitalize'>
                   {watchRefundableCaution && numberToWords(watchRefundableCaution)} naira
                  </p>
                 </div>

                 {/* electricity charge */}
                 <div className=' my-3 px-4  lg:w-[50%] w-[100%]'>
                  <label className='text-[14px] mb-4'>Electricity Charge Deposit</label>
                  <div className='flex bg-lightgray rounded mt-1 border-darkgray border-[1.5px] p-1'>
                  <p className='text-darkgray'>&#x20A6;</p>
                  <span className='mx-1 border-[1px] text-darkgray'></span>
                  <input 
                    type="number"
             
                    onKeyDown={handleKeyDown}
                    min="0"
                    {...register("electricityCharge")}
                    className='bg-lightgray w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'
                  />
                  
                 </div>
                 <p className='text-teal-600 capitalize'>
                   {watchElectricityCharge && numberToWords(watchElectricityCharge)} naira
                  </p>
                 </div>




                 </div>
              }
              <hr className='border-[1px] border-gray w-[80%] mx-auto  my-8'/>
            
            {/* description */}
            <div className=' my-3 px-4'>
                <label className='text-[14px] mb-4'>Description <span className='text-teal-400  text-xl absolute' > *</span>
                 </label>
                <textarea
                  rows={10}
          
                  {...register("description", {required: true})}
                  typeof='string'
                  className='bg-lightgray border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 rounded'
                />
                {errors.description && <span className='text-xs text-red-600'>This field is required</span>}
              </div>
              {/* features */}
              <div className=' my-3 px-4  w-[100%]'>
                <label className='text-[14px] mb-4'>Property Features</label>
                <div>
                <input
                type="text"
                value={inputText}
                onChange={handleInputChange}
                className='bg-lightgray mt-1 border-darkgray border-[1.5px] p-1  rounded text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900 mb-2'
                placeholder='Search Features...'
              />
                 {existingAmenities.length === 0 && inputText.length > 0 && (
                <div className="text-red-500">Feature not found</div>
                )}
              <div className='grid grid-cols-5 bg-white shadow-lg h-[40vh] overflow-y-scroll rounded p-2'>
                {existingAmenities.map((item) => (
                    <div key={item} className='flex  my-3 px-4'>
                        <input  type="checkbox"
                            name={item}
                            id={item}
                            className='w-4 accent-teal-600'
                            value={item}
                            onChange={handleCheckboxChange}
                            checked={checkedState.includes(item)}
                         
                        />
                        <label className='text-[14px] ml-1' dangerouslySetInnerHTML={{ __html: highlightMatchingLetters(item, inputText) }} />
                    </div>
                ))}
                {Object.values(Amenity).filter(item => !existingAmenities.includes(item)).map((item) => (
                    <div key={item} className='flex  my-3 px-4'>
                        <input  type="checkbox"
                            name={item}
                            id={item}
                            className='w-4 accent-teal-600'
                            value={item}
                            onChange={handleCheckboxChange}
                            checked={checkedFeatures.current.includes(item)}
                          
                        />
                        <label className='text-[14px] ml-1'>{item}</label>
                    </div>
                ))}
            </div>
                </div>

                </div>

              <div className=' text-end mt-10 mb-4'>
              <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 mr-4 '
                type='button' onClick={handlePreviousStep}
                >PREVIOUS</button>
                <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 ml-4 '
                type='button' onClick={handleNextStep}
                >NEXT</button>
              </div>
             
            </div>
            )
            }
          

                  {/* confimation page and submit button */}
            { step === 2 && (
              <div className='p-4'>
                {/* confirm  */}
                <section>
                  <p className='font-semibold'>Review and Submit</p>

                  <div className='grid lg:grid-cols-5 md:grid-cols-2 justify-evenly w-[100%]  '>
                  {Object.entries(finalData).map(([property, value]) => (
                      (property !== 'video' ) && (property !== 'flier') && (
                        <div className='my-2' key={property}>
                          <p className='text-[14px] font-semibold'>{property}:</p>
                          {(value !== null && value !== '' && value !== undefined) && (
                            <p className='text-[16px]'>{value}</p>
                          )}
                        </div>
                      )
                    ))}

               
                   
                  </div>

                  <div className='my-2 ' >
                    <p className='text-[14px] font-semibold'>Property Features:</p>
                    <p className='text-[16px]'>{checkedFeatures.current.join(',')}</p>


                  </div>


                </section>
                <section>
                   <h2></h2>
                </section>
            
              <div className='text-end mt-10 mb-4'>
                {
                  !loading &&
                
               <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 mr-4 '
                type='button' onClick={handlePreviousStep}
                >PREVIOUS</button>
            }
              {
                  loading?
              
                  <button className='bg-teal-600 text-sm text-center px-6' 
                >
                  <ColorRing
                    visible={true}
                    height="40"
                    // width="f0"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                    />
                  </button>
                                    
                  :
                <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 '
                type='submit'
                > SUBMIT</button>
              }
              </div>
              </div>
            )
            }
          </form>

      
        </section>
      </div>
    
            
      </div>
     <Footer/>
          
    </div>

    </div>
    </>
  )
}

export default EditProperty