import React,{useState, useContext, useEffect} from 'react'
import Header from '../Header'
import SideBar from '../SideBar'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { AddOutlined, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material'
import 'react-toastify/dist/ReactToastify.css';
import { AxiosContext } from '../Axios/AxiosContext';
import ProjectRecords from '../ProjectRecord'
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';

interface Record {
  id: number;
  projectName: string;
  description: string
}
function Project() {
    const navigate = useNavigate();
    const [data, setData] = useState<Record[]>([]);
    const [loading, setLoading] = useState(true);
    const { authAxios } = useContext(AxiosContext)

    const itemsPerPage = 5;
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);
    
    useEffect(() => {
        setLoading(true)
        authAxios.get(`/api/ivantage/agent/projectList`)
        .then(res => {
            
            setData(res.data.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false)
            toast.error('There was an error while retrieving the data', {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          });
      }, []);
  
    
    const handlePageClick = (event: { selected: number }) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };
  
  return (
    <>
    <Header/>
    <div className='flex'>
      <SideBar/>

      <div className='p-8 bg-lightgray flex-1 overflow-hidden'>
        <div className='flex justify-between'>
            <h2 className='text-2xl mt-10'>Projects</h2>
            <button className='bg-cyan-900 text-sm text-center mt-10 px-3 lg:px-6 py-3 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 flex' onClick={()=>navigate('/newproject')}>
                <p className='text-white text-xs font-medium'>NEW PROJECT </p> 
                <AddOutlined sx={{fontSize: 15, color:'white', padding: 0}} />    
            </button>
        </div>
        {
          loading?
          <div className='flex h-[50vh] items-center justify-center'>
          <ColorRing
          visible={true}
          height="100"
          width="100%"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
          />  
        </div>
        :
        <section className='mt-4 overflow-scroll'>
            <ProjectRecords data={currentItems} />
            <ReactPaginate
                    breakLabel="..."
                    nextLabel= {<KeyboardDoubleArrowRight sx={{color:'#26A69A'}}/>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<KeyboardDoubleArrowLeft sx={{color:'#26A69A'}}/>}
                    renderOnZeroPageCount={null}
                    className='flex my-3 justify-center items-center'
                    pageClassName='mx-1 p-1 text-sm text-cyan-900  '
                    pageLinkClassName='hover:bg-gray rounded-full px-2 py-1'
                    activeLinkClassName='bg-cyan-900 rounded-full px-2 py-1 text-gray hover:text-cyan-900'
                />
        </section>
        }
      </div>
       {/* toastify */}
       
    </div>
    </>
  )
}

export default Project