import { CalendarMonthOutlined, CalendarTodayOutlined, HomeOutlined, ImageOutlined, LocationOnOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

interface Record {
  id: number;
  propertyCode: string;
  projectName: string;
  propertyName: string;
  propertyType: string
  address: string;
  state: string;
  price: string;
  totalUnits: string;
  availableUnits: string;
  status: string;
  description: string;
  isApproved: boolean;
  images: string[];
  numberOfBedrooms: string;
  approvalStatus: string;
  createdAt: string;
  
}

interface RecordsProps {
  data: Record[];
  filtered: boolean
}


const  SubmissionRecords: FC<RecordsProps> = ({ data, filtered }) => {

    const navigate = useNavigate();
   
    function formatDate(inputDateString: string): string {
      const date = new Date(inputDateString);
    
      const options: Intl.DateTimeFormatOptions = { 
        day: '2-digit', 
        month: 'long', 
        year: 'numeric' as 'numeric' // Specify the type for year explicitly
      };
    
      const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(date);
    
      return formattedDate;
    }
    
    // Example usage:
    const dateString = "2024-03-11T05:24:24.000Z";
    const formattedDateString = formatDate(dateString);
   
    
    let indexCounter = 0;

  return (
    <div className='grid lg:grid-cols-1 w-[100%]' >
    {
        data.map((item)=>(

        <div className='rounded-lg  w-[100%] my-2 bg-white shadow-md p-3' onClick={()=> navigate(`/property/${item.id}`)}>
              
              {
                filtered?

              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
               <h2 className='capitalize text-[20px] font-semibold mb-2 text-darkgray'>{item.propertyName}</h2>
                
                <div className='lg:flex'>
      

                <div className='lg:h-[150px] lg:w-[35%] lg:pb-0 pb-4'>
                <p className='flex bg-lightgray p-1 '>
                      <ImageOutlined/>
                      <span>{item.images? item.images.length : 0}</span>
                    </p>
                    <img src={item.images[0]} alt="" className='w-[100%] h-[100%] '/>
                    
                </div>
                <div className='px-4  flex flex-col justify-between'>
                  <h4 className='text-[17px]  font-semibold text-cyan-900'>{item.numberOfBedrooms} bedroom, {item.propertyType}</h4>
                  <p className='text-[15px] font-semibold text-red-700 uppercase ' >STATUS: {item.approvalStatus}</p>
                    <div className='flex'
                    >
                        <LocationOnOutlined  sx={{color:'#26A69A', fontSize:20}} />
                        <p className='capitalize text-[15px] font-semibold text-darkgray pl-1' >{item.address}, {item.state}. </p>
                    </div>
                    <div className='flex my-[2px]'>
                      <HomeOutlined  sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>{item.propertyType}</p>
                    </div>
                  
                    <div className='my-[2px] flex'>
                    <CalendarMonthOutlined sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>Added on: {formatDate(item.createdAt)} </p>
                    </div>
                    {/* <div className=' my-[2px] flex'>
                    <CalendarMonthOutlined sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>Last updated on: </p>
                    </div> */}
                    <hr  className='text-gray'/>
                    
                </div>
                
                </div>
                  <div className='flex'>
                    <p className='w-[37%]'></p>
                    <p className='text-[22px] text-end items-end   font-bold text-cyan-900'>
                        
                        &#x20A6;{parseInt(item.price).toLocaleString('en-US')}
                    
                    </p>
                </div>
              </motion.div>
              :
              <motion.div
              key={indexCounter++}
              initial={{ height: -1, opacity: 0 }} // Set initial height and opacity to 0
              animate={{ height: "auto", opacity: 1 }} // Animate height to auto and opacity to 1
              transition={{ duration: 0.5 }} // Duration of the animation with a delay based on index
             >
              <h2 className='capitalize text-[20px] font-semibold mb-2 text-darkgray'>{item.propertyName}</h2>
                
                <div className='lg:flex'>
      

                <div className='lg:h-[150px] lg:w-[35%] lg:pb-0 pb-4'>
                  <p className='flex bg-lightgray p-1 '>
                      <ImageOutlined/>
                      <span>{item.images? item.images.length : 0}</span>
                    </p>
                     <img src={item.images ? item.images[0] : ''}  alt="" className='w-[100%] h-[100%] '/> 
                
                </div>
                <div className='px-4  flex flex-col justify-between'>
                  <h4 className='text-[17px]  font-semibold text-cyan-900'>{item.numberOfBedrooms} bedroom, {item.propertyType}</h4>
                  <p className='text-[15px] font-semibold text-red-700 uppercase ' >STATUS: {item.approvalStatus}</p>
                    <div className='flex'
                    >
                        <LocationOnOutlined  sx={{color:'#26A69A', fontSize:20}} />
                        <p className='capitalize text-[15px] font-semibold text-darkgray pl-1' >{item.address}, {item.state}. </p>
                    </div>
                    <div className='flex my-[2px]'>
                      <HomeOutlined  sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>{item.propertyType}</p>
                    </div>
                  
                    <div className='my-[2px] flex'>
                    <CalendarMonthOutlined sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>Added on: {item.createdAt && formatDate(item.createdAt)} </p>
                    </div>
                    {/* <div className=' my-[2px] flex'>
                    <CalendarMonthOutlined sx={{color:'#26A69A', fontSize:20}} />
                        <p className='text-[14px] pl-1'>Last updated on: </p>
                    </div> */}
                    <hr  className='text-gray'/>
                    
                </div>
                
                </div>
                  <div className='flex'>
                    <p className='w-[37%]'></p>
                    <p className='text-[22px] text-end items-end   font-bold text-cyan-900'>
                        
                        &#x20A6;{parseInt(item.price).toLocaleString('en-US')}
                    
                    </p>
                </div>
              </motion.div>
              }
        </div>
                
                ))
            }
        </div>
  )
}

export default SubmissionRecords