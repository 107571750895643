import React, { useState  , useRef, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm,  SubmitHandler } from "react-hook-form"
import { ColorRing } from 'react-loader-spinner';
import { AxiosContext } from './Axios/AxiosContext';
import { kyc } from '../assets';
import { CancelSharp, LogoutOutlined, InfoOutlined } from '@mui/icons-material';
import { useAuth } from './Authentication/AuthContext';
import { motion } from 'framer-motion';

interface FormData {
    govtID: FileList;
    picture: FileList;
    rcnumber: string;
  }



const KYC: React.FC=() =>{
    const [loading, setLoading] = useState<boolean>(false)
    const {authAxios, publicAxios}  = useContext(AxiosContext)
    const [idInfo, setIDInfo]  = useState<boolean>(false)
    const [picInfo, setPicInfo]  = useState<boolean>(false)
    const { logout } = useAuth()
    const kycStatus = useRef<boolean>()
    
    useEffect(()=>{
      const email = localStorage.getItem('email')
      publicAxios.get(`/api/ivantage/agent/getUserByEmail?email=${email}`).then((res)=>{
          
           if(res.data.data.hasKyc == true){
            kycStatus.current = true
            navigate('/login')
           }
      })
    },[])

    const notify = () => toast.success('KYC has been successfully submitted, You will recieve a notification when it has been verified.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
          } = useForm<FormData>()
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
 
    const successNotification = () => toast.success('KYC documents uploaded successfully.', {
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style:{
          width: '600px'
        }
        });

    const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style:{
            textTransform: 'capitalize',
        }
        });
    const onSubmit: SubmitHandler<FormData> = async(data) => {

        
        // const  files = data.cac
        // const renamedFiles: File[] = [];
        //   for (let i = 0; i < files.length; i++) {
        //     const file = files[i];
        //     const renamedFile = new File([file], 'dp', { type: file.type });
        //     renamedFiles.push(renamedFile);
        //   }
       
        setLoading(true)
        await authAxios.post('/api/ivantage/agent/uploadKyc',{
          govtId: data.govtID[0],
          picture: data.picture[0],
          cac: data.rcnumber
        },{
          headers: {
              'Content-Type': 'multipart/form-data'
        }},
            ).then((res)=>{
            

            setLoading(false)
            successNotification()
            localStorage.setItem('kycStatus', 'true');
            setTimeout(() => navigate('/email-verification'), 3000)
          
        }).catch(e=>{
          setLoading(false)
          errMessage.current = e.response.data.message
          errorNotification()
        })
    }

  const handleLogout=()=> {
      const logoutConfirmed = window.confirm('Are you sure you want to logout?');
  
      if (logoutConfirmed) {
        logout()
        navigate('/login')
      }
    }
  
  return (
    <>
       
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 1, delay: 0.4 }}
    className='flex w-screen justify-center items-center bg-teal-600 bg-opacity-50 z-50 overflow-scroll'>
    <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
      <div className='z-10'>
      <div className='z-10 mb-10 px-4 flex justify-end  cursor-pointer mt-4 mr-4' onClick={handleLogout}>
          {/* <LogoutOutlined sx={{fontSize: 20}} /> */}
          <p className='text-teal-600 font-semibold underline'>LogOut</p>
        </div>
        
        <form action="" className='bg-white z-10 shadow-lg px-20 py-4 lg:w-[100%] md:w-[50%] mx-auto' onSubmit={handleSubmit(onSubmit)}>

            <h2 className='font-bold text-2xl text-teal-600 text-center mb-4'>Verify Your Identity</h2>
             <p className='font-semibold text-[14px] text-center mb-4'>To access all features, please complete the KYC process.</p>
             <div className='flex justify-center items-center'>
              <img src={kyc} alt="" className='w-[200px]' />
             </div>
            <div className='my-2'>
                <label htmlFor="" className='text-[14px] flex'>Government Issued ID
                <div className='pl-1 cursor-pointer' onClick={()=>setIDInfo(!idInfo)}>
                <InfoOutlined sx={{fontSize: 15, color:'#26A69A'}} />
               </div>
               {
                idInfo && <p className='pl-2 text-xs py-1 font-medium'>Only pdf files are accepted</p>
        
               }
                </label>
                <input type="file"
                 accept=".pdf" 
                {...register("govtID", {required: true})}
                className='bg-lightgray border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'/>
                {errors.govtID && <span className='text-xs text-red-600'>Select a File.</span>}    
            </div>

            <div  className='my-2'>
                <label htmlFor="" className='text-[14px] flex'>RC Number
              
                </label>
                <input type="text" 
                 placeholder='Enter RC Number' 
                {...register("rcnumber", {required: true, pattern:{
                  value: /^\d{7}$/,
                  message: 'Enter a valid RC number'
                   }})}
                className='bg-lightgray border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'/>
                {errors.rcnumber && <span className='text-xs text-red-600'>This field is required</span>} 
                {errors.rcnumber && <p className='text-xs text-red-600 text-justify'>{errors.rcnumber.message}</p>}  
                                   
            </div>

            <div  className='my-2'>
                <label htmlFor="" className='text-[14px] flex'>Picture
                <div className='pl-1 cursor-pointer' onClick={()=>setPicInfo(!picInfo)}>
                <InfoOutlined sx={{fontSize: 15, color:'#26A69A'}} />
               </div>
               {
                picInfo && <p className='pl-2 text-xs py-1 font-medium'>Only jpeg, jpg and png files are accepted</p>
        
               }
                </label>
                <input type="file" 
                accept=".jpg, .jpeg, .png"
                {...register("picture", {required: true})}
                className='bg-lightgray border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900'/>
                {errors.picture && <span className='text-xs text-red-600'>Select a File.</span>}    
            </div>
            <div className='text-end mt-4 mb-4 '>
            {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                :
              <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 hover:bg-gray hover:text-teal-600 '
              type='submit'
              >SUBMIT</button>
            }
            </div>
        </form>
        </div>
    </motion.div>

    </>
  )
}

export default KYC