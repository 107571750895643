import React,{ useEffect, useRef, useState, useContext } from 'react'
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { ArrowBack, BathroomOutlined, BedOutlined, CarCrashOutlined, LocationOnOutlined, RoomOutlined } from '@mui/icons-material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Header from '../Header';
import SideBar from '../SideBar';
import { AxiosContext } from '../Axios/AxiosContext';
import Map,{Marker, Popup} from 'react-map-gl';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner'
import Footer from '../Footer';
import { motion } from 'framer-motion';

enum PropertyStatus {
  UnderConstruction = "Under Construction",
  Completed = "Completed",
  OffPlan = "Off Plan"
}

enum PropertyCategory {
  ForSale = "For Sale",
  ForRent = "For Rent",
}
interface Record {
  id:string;
  ProjectId: string;
  propertyCode: string;
  propertyName: string;
  propertyType: string;
  propertySubtype: string;
  latitude: number;
  longitude: number;
  mapLocation: number[];
  address: string;
  state: string;
  locality: string;
  propertySize: string;
  status: PropertyStatus;
  rentPeriod: string;
  propertyCategory: PropertyCategory;
  price: number;
  totalUnits: number;
  availableUnits: number;
  numberofBedrooms: number;
  numberofBathrooms: number;
  numberofToilets: number;
  propertyFeatures: string[];
  description: string;
  isFurnished: boolean,
  isServiced: boolean,
  serviceCharge: number,
  serviceChargePeriod: string,
  refundableCaution: number,
  electricityCharge: number,
  parkingSpace: number,
  completionDate: string;
  developer: string;
  flier: string;
  video: string;
  }

const Publication: React.FC = () => {
    const { id } = useParams<any>();
    const [loading, setLoading] = useState(true);
    const [property, setProperty] = useState<Record | null>(null);
    const { authAxios } = useContext(AxiosContext)
    const [latitude, setLatitude] = useState<number>(0)
    const [longitude, setLongitude] = useState<number>(0)
    const navigate = useNavigate()
    const [price, setPrice] = useState<number>(0)
    const [washroom, setWashrooms] = useState<number>(0)
    const [bedrrooms, setBedrooms] = useState<number>(0)
    const [toilet, setToilets] = useState<number>(0)
    const [totalUnits, setTotalUnits] = useState<number>(0)
    const [availableunits, setAvailableUnits] = useState<number>(0)
    const [images, setImages] = useState<string[]>([])
    const [featureValues, setFeatureValues] = useState<string[]>([]);

    useEffect(() => {
      
      authAxios.get(`/api/ivantage/agent/property?id=${id}`)
          .then(res => {
            if(res.data.data){
              console.log(res.data.data)
             
              setProperty(res.data.data)
          
              if(res.data.data){

                setPrice(parseFloat(res.data.data.price));
                setBedrooms(res.data.data.numberOfBedrooms)
                setWashrooms(res.data.data.numberOfWashrooms)
                setToilets(res.data.data.numberOfToilets)
                setAvailableUnits(parseInt(res.data.data?.availableUnits))
                setTotalUnits(res.data.data?.totalUnits)
                setImages(res.data.data.images)
                
                if(property?.propertyFeatures){
                const parsedTextValues: string[] = []; 
                property.propertyFeatures.forEach((item : string) => {
                  // Parse the string as JSON
                  const parsedArray = JSON.parse(item);
                  
                  // Concatenate the parsed array to the items array
                  parsedTextValues.push(...parsedArray);
                });
                

                 
                // Set the parsed text values in state
                setFeatureValues(parsedTextValues)
                }
                
                const [latitudeStr, longitudeStr] = res.data.data.mapLocation.split(',');
          
                setLatitude(parseFloat(latitudeStr))
                setLongitude(parseFloat(longitudeStr))
            }}
            setLoading(false);
          })
          .catch((e) => {
            
            // alert('There was an error while retrieving the data');
          });
      },[]);
    

  return (
    <>
    <Header/>
    
    <div className='flex'>
      <SideBar/>

    <div className='overflow-hidden w-full'>
 
    <div className=''>
     {
        loading?
        <div className='flex w-screen h-[100vh] items-center justify-center'>
          <ColorRing
          visible={true}
          height="100"
          width="100%"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
          />  
      </div>
      :
    <motion.div
      initial={{ opacity:0 }}
      animate={{ opacity:1 }}
      transition={{ duration: 0.5}}
      >
    <div className='p-8 bg-lightgray  overflow-hidden'>
        <div className='flex py-2 lg:mt-10 mt-12  cursor-pointer' onClick={()=>navigate(-1)}>
           <p className='text-xs underline text-teal-600'>Go Back</p>
       </div>
        {/* <p className='text-2xl mt-10'>Publication</p> */}
        <div className='lg:flex justify-between  '>
         <div className='w-[100%]'>

         <p className='font-semibold text-[22px] py-2'>{property?.propertyName} <span className='bg-teal-400 rounded-full text-[12px] font-normal px-2  text-lightgray'>{property?.propertyCategory}</span>
         {
          property?.isFurnished && 
          <span className='bg-cyan-800 rounded-full text-[12px] font-normal px-2 text-lightgray cursor-pointer' >Furnished</span>
         }
         {
          property?.isServiced && 
          <span className='bg-cyan-800 rounded-full text-[12px] font-normal px-2 text-lightgray cursor-pointer' >Serviced</span>
         }
          </p>
         <div className='flex text-[16px] pl-1'>
         <p>{property?.propertyType} </p>{property?.propertySubtype && <p className='text-teal-400'>  | {property?.propertySubtype}
         </p>
         }
         </div>
         <p className='flex'>
          <LocationOnOutlined sx={{color:'#26A69A', fontSize:20}} />
          <p className='text-[14px] capitalize'>{property?.address}, {property?.locality}, {property?.state}.</p>
         </p>
         </div>

         <div className='lg:text-end lg:px-14 pt-4' >
         <span className='bg-gray hover:bg-cyan-900 rounded-full text-[12px] text-cyan-900 hover:text-lightgray font-normal px-2  cursor-pointer' onClick={()=>navigate(`/edit-property/${property?.id}`)}>Update property</span>
           <p className='text-[18px] font-semibold'>Property Price</p>
          <p className='text-[22px] font-bold text-cyan-900'>&#x20A6;{price.toLocaleString('en-US')}
         {/* / <span className='lowercase'> {property?.rentPeriod}</span> */}
          {property?.propertyCategory == 'For Rent' && 
          <span className='lowercase'> {property?.rentPeriod}</span>
          }
          </p>
         </div>

        </div>

        <div  className='lg:px-4 pr-4 lg:flex lg:justify-between w-[100%]'>
        <div>
     
        <div className='lg:w-[500px] md:w-[400px] sm:w-[200px] mt-4'>
        
          {
            images &&
            <Swiper
           
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            style={{
              "--swiper-navigation-color": "#26A69A",
              "--swiper-pagination-color": "#fff",
            }}
          >
            {images.map((image, index) => (
              
              <SwiperSlide> 
                <div className='w-full h-[50vh] object-cover'>
                <span className='text-darkgray rounded-full border-darkgray border-[1px] text-[12px] absolute top-0 font-normal px-2 cursor-pointer' onClick={()=>navigate(`/edit-property-image/${property?.id}`)}>Edit Images</span>
                <img src={image} alt='image' className='w-[100%] object-fill h-[100%]'  />
                </div> </SwiperSlide>
             ))}

          </Swiper>
        }
       </div>

       </div>

{/* / */}
       <div className='lg:px-10 lg:w-[50%] flex flex-col justify-evenly lg:text-end  mt-4'>
      
         {/* propery description */}
         <div className='my-2'>
           <p className='text-[18px] mb-2 font-semibold'>Property Description</p>
           <p className='text-[16px] text-justify'>{property?.description}</p>
         </div>

          {/* propery description */}
          <div className='my-2'>
           <p className='text-[18px] mb-2 font-semibold'>Property Status</p>
           <p className='text-[16px] text-end'>{property?.status}</p>
           <p className='text-[14px] font-semibold text-darkgray text-end'>Completion Date: {property?.completionDate}</p>

         </div>

          {/* propery description */}
          <div className='my-2'>
           <p className='text-[18px] mb-2 font-semibold'>Property Size</p>
           <p className='text-[16px] text-end'>{property?.propertySize} sqm</p>
         </div>



         {/* general features */}
         <div className='grid grid-cols-2  text-center shadow-lg rounded-lg mt-4 p-4 gap-4'>
          <div  >
            <p className='text-[22px] font-bold text-cyan-900'>{totalUnits | 0}</p>
            <p> Total Units</p>
          </div>
           
          <div>
          <p className='text-[22px] font-bold text-cyan-900'>{availableunits}</p>
          <p> Available Units</p>
          </div>
           
          </div>

          <div className='grid grid-cols-2 text-center shadow-lg rounded-lg mt-4 p-4 gap-4'>
          <div >
            <BedOutlined sx={{color:'#26A69A', fontSize:20}}/>
            <p className='text-[16px]'> <span className='text-[22px] font-bold text-cyan-900'>{bedrrooms} </span> Bedrooms</p>
          </div>
          <div>
            <BathroomOutlined sx={{color:'#26A69A', fontSize:20}}/>
            <p className='text-[16px]'><span className='text-[22px] font-bold text-cyan-900'>{washroom} </span> Bathrooms</p>
          </div>
          <div>
            <BathroomOutlined sx={{color:'#26A69A', fontSize:20}}/>
            <p className='text-[16px]'><span className='text-[22px] font-bold text-cyan-900'>{toilet} </span> Toilets</p>
          </div>

          <div>
            <CarCrashOutlined sx={{color:'#26A69A', fontSize:20}}/>
            <p className='text-[16px]'><span className='text-[22px] font-bold text-cyan-900'>{property?.parkingSpace} </span> Parking Space</p>
          </div>
       </div>
      </div>

      </div>
      <br/>
      {/* <div className='grid grid-cols-2  text-center shadow-lg rounded-lg mt-4 p-4 gap-4'>
        {
          property?.serviceCharge != undefined &&
        
          <div  >
            <p className='text-[22px] font-bold text-cyan-900'>{property?.serviceCharge}, {property?.serviceChargePeriod}</p>
            <p> Service Charge</p>
          </div>
        }
      
           
          </div> */}
          
      <div className='mt-4'>
        {/* features */}
        <div >
          <p className='text-[18px] font-semibold my-2'>Property Features</p>
          <div>
          {property?.propertyFeatures.map((feature) => (
               <ul className="divide-y divide-gray-200">
                 <li className="py-4 flex" key={feature}>
                   <div className="flex-shrink-0">
                   <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke='#26A69A'
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>

                   </div>
                   <div className="ml-3">
                     <p className="text-sm capitalize">{feature}</p>
                   </div>
                 </li>
               </ul>
          ))}
          </div>
        </div>

{
  property?.flier &&

    <div className='mt-4'>
    <p className='text-[18px] font-semibold my-2'>Property Flyer </p>
      <img src={property?.flier} alt=""  width="700" height="600"/>
    </div>
}

{
  property?.video &&

    <div className='mt-4'>
    <p className='text-[18px] font-semibold my-2'>Video </p>
      <video width="700" height="600" controls>
        <source src={property?.video} type="video/mp4" />
      </video>
    </div>
}
        {/* location */}
      <div className='mt-8 mb-8'>
      <p className='text-[18px] font-semibold my-2'>Map Location</p>
      <div className='lg:w-[700px]  lg:h-[350px]  h-[350px] border-[2px] border-cyan-900'>
      <Map
                        reuseMaps={true}
                        mapLib={import('mapbox-gl')}
                        mapboxAccessToken='pk.eyJ1IjoibWFyeS1vZ3VubW9sYSIsImEiOiJjbHNreXl4b2QwOGhoMndyMG1nc3RkbTljIn0.lWhiPPeajrb52VQUudiIgw'
                        initialViewState={{
                          longitude: Number(longitude),
                          latitude: Number(latitude),
                          zoom: 12
                        }}
                        //style={{width: '100%', height: '100%'}}
                        mapStyle="mapbox://styles/mapbox/streets-v9"
                      >
                        <Marker latitude={latitude} longitude={longitude}>
                          <RoomOutlined/>
                        </Marker>     

                        {/* <Popup longitude={Number(longitude)} latitude={Number(latitude)} anchor="bottom"> 
                          <p className='text-xs'>{property?.address}</p>
                        </Popup>     */}
                      </Map>
      </div>
      
      </div>
        {/* developers */}
        <div className='mt-4'>
          <p className='text-[18px] font-semibold my-2'>Developers</p>
           <p>{property?.developer}</p>
        </div>
      </div>
    </div>
    </motion.div>
      }

    </div>
      <Footer/> 
    </div>
    </div>
    </>
  )
}

export default Publication