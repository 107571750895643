import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Submissions from './Property/Submissions';

interface Record {
  id: number;
  projectName: string;
  description: string;
}

interface RecordsProps {
  data: Record[];
}

const ProjectRecords: FC<RecordsProps> = ({ data }) => {

  const navigate = useNavigate();

  return (
    <table className="table-auto overflow-x-scroll scroll-m-4 w-[100%] bg-white shadow-sm rounded-sm">
      <thead>
        <tr className='border-b-lightgray border-b-[2px] t text-[14px]'>
          <th scope="col" className='p-2 mx-2 text-start'>Project Name</th>
          <th scope="col" className='p-2 mx-2 text-start'>Description</th>
          <th scope="col" className='p-2 mx-2 text-end'>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className='px-2 border-b-lightgray border-b-[2px] text-start text-[14px] cursor-pointer hover:bg-lightgray ' onClick={()=> navigate(`/project/${item.id}`)}>
            <td className='p-2'>{item.projectName}</td>
            <td className='p-2 text-justify'>{item.description}</td>
            <td className='p-2 text-end' onClick={()=>navigate(`/edit-project/${item.id}`)}>
              <span className='bg-teal-500 rounded-full text-[12px] font-normal px-2  text-lightgray cursor-pointer'>Edit</span>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProjectRecords;
