import React, { useState  , useRef, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import { ColorRing } from 'react-loader-spinner';
import { AxiosContext } from './Axios/AxiosContext';
import { kyc } from '../assets';
import { getUserDataFromToken } from './Authentication/TokenAuth';
import 'react-toastify/dist/ReactToastify.css';

const VerifyEmail: React.FC=() =>{
    const [loading, setLoading] = useState<boolean>(false)
    const {authAxios}  = useContext(AxiosContext) 
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const [email, setEmail] = useState<string>('')
    
    const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style:{
            textTransform: 'capitalize',
        }
        });

      useEffect(() => {
          const token = localStorage.getItem('user');

          if (token) {
            // Decode JWT token and extract user data
            const decodedUserData = getUserDataFromToken(token.toString());
            if (decodedUserData){
              setEmail(decodedUserData.email)
            }
           
          } else {
            console.error('JWT token not found');
          }
        }, []);

    const handleSendOtp= async()=>{
          setLoading(true)
          console.log(email)
          await authAxios.post(`/api/ivantage/agent/resendOTP`,{
            sms: false,
            data: email
          }).then((res)=>{
            
            console.log(res)
            if (res.status==200 || res.data.success==true){
              setLoading(false)
              navigate('/verify-email')
            }
            
          }).catch(e=>{
            setLoading(false)
            
            
            
            errMessage.current = e.response.data.message
            
            errorNotification()
          })
        }

  return (
    <>
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 1, delay: 0.4 }}
     className='flex w-screen h-screen justify-center items-center bg-teal-600 bg-opacity-50 z-50 '>
    <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
         <div className='bg-white  rounded-lg z-10 px-20 py-4'>
            <h2 className='font-bold text-2xl text-teal-600 text-center mb-4'>Verify Your Email</h2>
             <p className='font-semibold text-[14px] text-center mb-4'>Click on the button below to verify your email.</p>
             <div className='flex justify-center items-center'>
              <img src={kyc} alt="" className='w-[200px]' />
             </div>

            <div className='text-center'>
            {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                :
              <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 hover:bg-gray hover:text-teal-600 '
               onClick={handleSendOtp}
              >SEND VERIFICATION CODE</button>
            }
       </div>
       </div>
    
    </motion.div>

    </>
  )
}

export default VerifyEmail