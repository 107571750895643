import { ExportAsExcel, ExportAsPdf, ExportAsCsv, CopyToClipboard, PrintDocument, } from "react-export-table"
import React,{useState, useEffect,useContext, useRef} from 'react'
import PropertyRecords from '../PropertyRecords'
import { AddOutlined, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft, FilterListOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Header"
import SideBar from "../SideBar"
import { AxiosContext } from '../Axios/AxiosContext';
import { ColorRing } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import PropertyListing from "../PropertyListing"
import Footer from "../Footer"
import { listing } from "../../assets"
import { useForm,  SubmitHandler } from "react-hook-form"
import { motion } from 'framer-motion';

interface Record {
  id: number;
  propertyCode: string;
  projectName: string;
  propertyName: string;
  propertyType: string
  address: string;
  state: string;
  price: string;
  totalUnits: string;
  availableUnits: string;
  status: string;
  description: string;
  isApproved: boolean;
  images: string[];
  numberOfBedrooms: string;
  approvalStatus: string;
  views: string;
  createdAt: string;
  propertyCategory: string
}

interface Filters {
  state: string;
  propertyType: string;
  propertySubtype: string;
  propertyCategory: string;
  status: string;
  all: string
}

enum PropertyStatus {
  UnderConstruction = "Under Construction",
  Completed = "Completed",
  OffPlan = "Off Plan"
}

enum PropertyCategory {
  ForRent = "For Rent",
  ForSale = "For Sale",
}


enum States {
  Abia= "Abia",
  Adamawa= "Adamawa",
  AkwaIbom= "Akwa Ibom",
  Anambra= "Anambra",
  Bauchi= "Bauchi",
  Bayelsa= "Bayelsa",
  Benue= "Benue",
  Borno= "Borno",
  CrossRiver= "Cross River",
  Delta= "Delta",
  Ebonyi= "Ebonyi",
  Edo= "Edo",
  Ekiti= "Ekiti",
  Enugu= "Enugu",
  Gombe= "Gombe",
  Imo= "Imo",
  Jigawa= "Jigawa",
  Kaduna= "Kaduna",
  Kano= "Kano",
  Katsina= "Katsina",
  Kebbi= "Kebbi",
  Kogi= "Kogi",
  Kwara= "Kwara",
  Lagos= "Lagos",
  Nasarawa= "Nasarawa",
  Niger= "Niger",
  Ogun= "Ogun",
  Ondo= "Ondo",
  Osun= "Osun",
  Oyo= "Oyo",
  Plateau= "Plateau",
  Rivers= "Rivers",
  Sokoto= "Sokoto",
  Taraba= "Taraba",
  Yobe= "Yobe",
  Zamfara= "Zamfara",
  FCT = "FCT"
}

interface PropertyType {
  type: string;
  subtype: string[];
}
const Properties: React.FC =()=> {
  const [data, setData] = useState<Record[]>([]);
  const [loading, setLoading] = useState(true);
  const { authAxios } = useContext(AxiosContext)
  const navigate = useNavigate()
  const [itemsPerPage, setItemPerPage] = useState<number>(5);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [ dataFound, setDataFound] = useState<boolean>(true)
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const [submissionFound, SetSubmissionFound] = useState<boolean>(true) 
  const [submission, setSubmission] = useState<Record[]>([]);
  const [option, setOption] = useState()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const datalength = useRef<number>(0)
  const filtered = useRef<boolean>(false)
  const filterFound = useRef<boolean>(true)
// property type and subtype
const propertyTypes: PropertyType[] = [
  {
   type: 'Flats & Apartment',
   subtype: [
     'Block of Flat',
     'Mini Flat',
     'Self Contain',
     'Shared Apartment',
     'Studio Apartment'
   ]
  },
  {
   type: 'Fully Detached',
   subtype: [
     'Fully Detached Duplex',
     'Fully Detached Bungalow',
   ]
  },
  {
   type: 'Semi Detached',
   subtype: [
     'Semi Detached Duplex',
     'Semi Detached Bungalow',
   ]
  },
  {
   type: 'Terrace',
   subtype: [
     'Terrace Duplex',
     'Terrace Bungalow',
   ]
  },
  {
   type: 'Maisonnette',
   subtype: [
     
   ]
  },
  {
   type: 'Penthouse',
   subtype: [

   ]
  },
  {
  type: 'Townhouse',
   subtype: [

   ]
  },
  {
 type: 'Shell',
   subtype: [

   ]
  },
  {
   type: 'Site and Services',
   subtype: [

   ]
  },
  {
   type: 'Short Stay',
   subtype: [

   ]
  },
  {
   type: 'Bareland',
   subtype: [
       'Commercial Land',
       'Industrial Land',
       'Joint Venture Land',
       'Mixed-Use Land',
       'Residential Land',
   ]
  },

]

  useEffect(() => {
      setLoading(true)
      authAxios.get(`/api/ivantage/agent/properties?size=${itemsPerPage}&page=1`)
      .then(res => {
        if(res.data.data.count == 0){
          setDataFound(false)
        }
        const sortedData = res.data.data.propertyData.sort((a: any, b: any) => {
          const dateComparison: number = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          if (dateComparison === 0) {
              return b.id - a.id;
          }
          return dateComparison;
      });
          setData(sortedData);
          datalength.current = data.length
         
         
          setSubmission(res.data.data.propertyData)
          setPageCount(res.data.data.totalPages)
          setLoading(false);
          setCount(res.data.data.count)
        })
        .catch(() => {
          alert('There was an error while retrieving the data');
        });
    }, []);

  const handleEntriesChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
      
      setItemPerPage(parseInt(e.target.value))
      
      setLoading(true)
      authAxios.get(`/api/ivantage/agent/properties?size=${e.target.value}&page=1`)
      .then(res => {
        const sortedData = res.data.data.propertyData.sort((a: any, b: any) => {
          const dateComparison: number = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          if (dateComparison === 0) {
              return b.id - a.id;
          }
          return dateComparison;
      });
          setData(sortedData);
          datalength.current = data.length
          setPageCount(res.data.data.totalPages)
          setCount(res.data.data.count)
          setLoading(false);
        })
        .catch(() => {
          setLoading(false)
          // alert('There was an error while retrieving the data');
        });
  }

  const handlePageClick = async(event: { selected: number }) => {
    setPageOffset(event.selected)
    
    
    setLoading(true)
    await authAxios.get(`/api/ivantage/agent/properties?size=${itemsPerPage}&page=${event.selected+1}`)
    .then(res => {
      
      const sortedData = res.data.data.propertyData.sort((a: any, b: any) => {
        const dateComparison: number = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        if (dateComparison === 0) {
            return b.id - a.id;
        }
        return dateComparison;
    });
        setData(sortedData);
        setCount(res.data.data.count)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false)
        toast.error('There was an error while retrieving the data', {
          position: "top-right",          
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      });
  };

 
  const notify = () => toast.success('Copied Successfully', {
    position: "top-right",
    
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

const inputsRef = useRef<{ [key: string]: string }>({});



const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }, trigger, getValues 
  } = useForm<Filters>()
  
  const watchPT = watch('propertyType')
  const watchST = watch('propertySubtype')
  const watchState = watch('state')
  const watchStatus = watch('status')
  const watchCategory = watch('propertyCategory')
  const watchAll = watch('all')
 
  const onSubmit: SubmitHandler<Filters> = () => {
    setData(submission)
    let filterData: any = []
    if(watchAll === 'all'){
      const filter =  submission
     
      filterData= [...filter]
    }
    else{
      if(watchState){
        inputsRef.current.state = watchState
      }
      if(watchStatus){
        inputsRef.current.status = watchStatus
      }
      if(watchPT){
        inputsRef.current.propertyType = watchPT
      }
      if(watchST){
        inputsRef.current.propertySubtype = watchST
      }
      if(watchCategory){
        inputsRef.current.propertyCategory = watchCategory
      }

     
    
     
     
     

    Object.entries(inputsRef.current).forEach(([key, value]) => {
     
      const filter =  submission.filter((item)=> item[key as keyof typeof item] === value)
     
      filterData= [...filter]
      });
    }
     
      setData(filterData)
      if(filterData.length === 0){
          filterFound.current = false
      }
      filtered.current = true
      datalength.current = data.length
     
    }

 
 
  return (
    <>
    <Header/>
    <div className="flex">
       <SideBar/>

    <div className=''>
    <div className='p-8 bg-lightgray flex-1 overflow-hidden'>
        <div className='lg:flex justify-between lg:mt-10 mt-16 mb-4'>
            <h2 className='text-2xl'>My Listed Properties</h2>
            <button className='bg-cyan-900 text-sm text-center mt-2 lg:mt-10 px-3 py-2 lg:px-6 lg:py-3 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 flex' onClick={()=>navigate('/newproperty')}>
                <p className='text-white text-xs font-medium'>NEW PROPERTY </p> 
                <AddOutlined sx={{fontSize: 15, color:'white', padding: 0}} />    
            </button>
        </div>


      {dataFound &&  <section>
          <p className=""><span className="text-xs">Show</span>
          <input type="number" name="" id=""  className="mx-2 w-[60px] text-center text-xs bg-lightgray border-[2px] rounded border-cyan-900" onChange={(e)=>handleEntriesChange(e)} max={count}
        
          />
          <span className="text-xs"> of {count} Properties</span>
          </p>
        </section>}
        {
          loading?
          <div className='flex h-[90vh] items-center justify-center'>
            <ColorRing
            visible={true}
            height="100"
            width="100%"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
            />  
          </div>
          :
          <div>
          {
          
           dataFound ?
          
         <section className='mt-4 flex '>
          <div className="lg:w-[65%] w-[100%]">
      {/* filter */}
      <div onClick={()=>setShowFilter(!showFilter)} className="flex items-center lg:hidden  ">
              <FilterListOutlined sx={{fontSize: 30, color:'#00897B'}}/>
              <p className="text-[14px]" onClick={()=>setShowFilter(!showFilter)} >Filter</p>
            </div>
            {
              showFilter &&
            
              <div className="w-[100%] px-8 mt-2 justify-center hidden lg:block ">
              <div className="border-[1.5px] border-gray">
                <h2 className="text-[18px] p-2 bg-gray">Filter your Listings</h2>
                <form  onSubmit={handleSubmit(onSubmit)}>    
                
                <div className="p-2">
                    <p className="text-[14px]">All </p>
                    <select   {...register("all")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                      <option value="">Select</option>
                      <option value="all">All properties</option>
                   </select>
                  </div> 
                
                  <div   style={{ pointerEvents: watchAll === 'all' ? 'none' : 'auto' }} className={watchAll === 'all' ? 'opacity-25' : ''}>
           
                 <div className="grid grid-cols-2 gap-6 p-2">
                  <div>
                    <p className="text-[14px]">State</p>
                    <select {...register("state")} className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                      <option value="">Select State</option>
                    {Object.values(States).map((item) => (
                        <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                      ))}
                   </select>
                  </div>
  
                  <div>
                    <p className="text-[14px]">Category</p>
                    <select   {...register("propertyCategory")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                      <option value="">Select Category</option>
                    {Object.values(PropertyCategory).map((item) => (
                        <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                      ))}
                   </select>
                  </div>
  
                  <div>
                    <p className="text-[14px]">Type</p>
                    <select {...register("propertyType")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                    <option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Property Type</option>
                          {propertyTypes.map((property, index) => (
                              <option key={index} value={property.type}>{property.type}</option>
                            ))}
                   </select>
                  </div>
  
                  <div>
                <label className='text-[14px]'>Subtype</label>
                  
                  <select
                  {...register('propertySubtype')}
                 
                   className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'
                 > <option value="">Select Subtype</option>
                   {propertyTypes.find(property => property.type === watchPT)?.subtype.map((subtype, index) => (
                     <option key={index} value={subtype}>{subtype}</option>
                   ))}
                 </select>    
                </div>
                 </div>
                                 
               
  
                 <div className="p-2">
                    <p className="text-[14px]">Status</p>
                    <select   {...register("status")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                      <option value="">Select Status</option>
                    {Object.values(PropertyStatus).map((item) => (
                        <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                      ))}
                   </select>
                  </div>
  
                                  
                  </div>
  
                 <div className="p-2">
                 <button className="text-[14px] py-2 px-8 text-center bg-cyan-800 text-lightgray font-semibold focus:bg-lightgray focus:text-cyan-800 border-[1px] " type="submit">FILTER</button>
  
                  </div>
              </form> 
              </div>
         
            </div>
            }
            <PropertyListing data={data} filtered={filtered.current} />
            {
              !filterFound.current && 
              <div className="p-4 bg-gray rounded w-full">
                <p className="">No property found. Please adjust your filter criteria and try again.</p>
              </div>

            }
            {
              data.length > 0 &&
            
            <ReactPaginate
                    breakLabel="..."
                    nextLabel= {<KeyboardDoubleArrowRight sx={{color:'#26A69A'}}/>}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<KeyboardDoubleArrowLeft sx={{color:'#26A69A'}}/>}
                    renderOnZeroPageCount={null}
                    forcePage={pageOffset}
                    className='flex my-3 justify-center items-center'
                    pageClassName='mx-1 p-1 text-sm text-cyan-900  '
                    pageLinkClassName='hover:bg-gray rounded-full px-2 py-1'
                    activeLinkClassName='bg-cyan-900 rounded-full px-2 py-1 text-gray hover:text-cyan-900'
                  />
            }
                            
          </div>

          {/* filter */}
          <div className="w-[35%] px-8 mt-2 justify-center hidden lg:block ">
            <div className="border-[1.5px] border-gray">
              <h2 className="text-[18px] p-2 bg-gray">Filter your Listings</h2>
              <form  onSubmit={handleSubmit(onSubmit)}>    
              
              <div className="p-2">
                  <p className="text-[14px]">All </p>
                  <select   {...register("all")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                    <option value="">Select</option>
                    <option value="all">All properties</option>
                 </select>
                </div> 
              
                <div   style={{ pointerEvents: watchAll === 'all' ? 'none' : 'auto' }} className={watchAll === 'all' ? 'opacity-25' : ''}>
         
               <div className="grid grid-cols-2 gap-6 p-2">
                <div>
                  <p className="text-[14px]">State</p>
                  <select {...register("state")} className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                    <option value="">Select State</option>
                  {Object.values(States).map((item) => (
                      <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                    ))}
                 </select>
                </div>

                <div>
                  <p className="text-[14px]">Category</p>
                  <select   {...register("propertyCategory")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                    <option value="">Select Category</option>
                  {Object.values(PropertyCategory).map((item) => (
                      <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                    ))}
                 </select>
                </div>

                <div>
                  <p className="text-[14px]">Type</p>
                  <select {...register("propertyType")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                  <option key='' value='' className='bg-lightgray p-1 rounded-none'>Select Property Type</option>
                        {propertyTypes.map((property, index) => (
                            <option key={index} value={property.type}>{property.type}</option>
                          ))}
                 </select>
                </div>

                <div>
              <label className='text-[14px]'>Subtype</label>
                
                <select
                {...register('propertySubtype')}
               
                 className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'
               > <option value="">Select Subtype</option>
                 {propertyTypes.find(property => property.type === watchPT)?.subtype.map((subtype, index) => (
                   <option key={index} value={subtype}>{subtype}</option>
                 ))}
               </select>    
              </div>
               </div>
                               
             

               <div className="p-2">
                  <p className="text-[14px]">Status</p>
                  <select   {...register("status")}  className='bg-lightgray mt-1 border-gray border-[1.5px] p-1 w-[100%] rounded-sm text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'>
                    <option value="">Select Status</option>
                  {Object.values(PropertyStatus).map((item) => (
                      <option key={item} value={item} className='bg-lightgray p-1 rounded-none'>{item}</option>
                    ))}
                 </select>
                </div>

                                
                </div>

               <div className="p-2">
               <button className="text-[14px] py-2 px-8 text-center bg-cyan-800 text-lightgray font-semibold focus:bg-lightgray focus:text-cyan-800 border-[1px] " type="submit">FILTER</button>

                </div>
            </form> 
            </div>
       
          </div>
      </section>
          :
          <motion.div
          initial={{ height: 0, opacity: 0 }} // Set initial height and opacity to 0
          animate={{ height: "auto", opacity: 1 }} // Animate height to auto and opacity to 1
          transition={{ duration: 0.3 }} >
          <div className="lg:w-[35%] sm:w:[80%] md:w-[55%] text-center mx-auto p-2">
          <img src={listing} alt="" />
          </div>
          <div className="text-center">
              <p className="text-darkgray font-semibold lg:text-[22px] text-[16px]">Oops! You do not have any listed property.</p>
              <p className="text-darkgray text[14px] border-b-2 border-gray w-[15%] mx-auto cursor-pointer" onClick={()=>navigate('/newproperty')}>Add new property</p>
          </div>
         </motion.div>
         
          }
    </div>
     }
 
    </div>
    <Footer/>
    </div>

    </div>
    </>
  )
}

export default Properties