import React,{useState, useRef, useContext, useEffect, MutableRefObject } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import SideBar from '../SideBar';
import { toast } from 'react-toastify';
import { AxiosContext } from '../Axios/AxiosContext';
import { useForm,  SubmitHandler } from "react-hook-form"
import { ColorRing } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {CancelOutlined, InfoOutlined, RoomOutlined} from '@mui/icons-material';

interface ImageTag {
  id: string;
  imageUrl: string;
}

interface FormData {
  photos: []
}

const EditImages: React.FC = () => {
const { id } = useParams<any>();
const navigate = useNavigate()
const errMessage = useRef<string>('')
const {authAxios}  = useContext(AxiosContext)
const [imageTags, setImageTags] = useState<ImageTag[]>([]);
const Images:MutableRefObject<File[]> = useRef<File[]>([]);
const [preview, setPreview] = useState<boolean>(false);
const [img, setImg] = useState<string>('')
const [imgId, setImgId] = useState<string>('')
const [loading, setLoading] = useState<boolean>(false)

useEffect(() => {
  authAxios.get(`/api/ivantage/agent/property?id=${id}`)
      .then(res=> {
          if(res.data.data){
             Images.current = res.data.data.images
             
            }
        setLoading(false);
      })
      .catch((e) => {
        
      });
  },[id])

// Handle file selection
const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
  const file = e.target.files?.[0];
  if (file) {
      setImg(URL.createObjectURL(file));
  }
  }
  
// Preview Image
const previewImage = (url: string, id:string): void => {
  if (url) {
    
    setImg(url);
    setImgId(id)
    setPreview(!preview);
    
  }
};

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileSelect(event)
    const files = event.target.files;
    if(Images.current.length > 9){
      event.target.value = ''
      toast.error('You can only upload a maximum of 10 images.',{
        position: "top-right",
        
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        }) 
    }
  
    if (files) {
      const updatedImageTags: ImageTag[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const id = file.name;
        const imageUrl = URL.createObjectURL(file);
        updatedImageTags.push({ id, imageUrl });
      }
     const updatedImages= []
     for (let i = 0; i < files.length; i++) {
      const file = files[i];
      updatedImages.push(file);
      // file.append('photos', file)
    }
      setImageTags([...imageTags, ...updatedImageTags]);
      Images.current = [...Images.current,...updatedImages]
    }
    
  };

// get photos
useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await authAxios.get(`/api/ivantage/agent/property?id=${id}`);
      if (res.status === 200 || res.data.success === true) {
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []); 
const handleRemove = (id: string) => {
  setImageTags(imageTags.filter((imageTag) => imageTag.id !== id));
};

const setDisplayImage = (img:string, id: string, e: React.MouseEvent<HTMLButtonElement>)=>{
  e.preventDefault()
  
  const file = new File([img], id )
  
  Images.current.map((item)=>{
    if(item.name == file.name){
      
      let index = Images.current.indexOf(item)
      Images.current.splice(index, 1);
      Images.current.unshift(item);
      
      toast.success('Display image successfully set.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    setTimeout(()=> setPreview(!preview), 500)
    }
  })
  

}

const successNotification = () => toast.success('Property Images has been successfully updated .', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

 const errorNotification = () => toast.error((errMessage.current), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

// form data--submission
const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>()


const onSubmit: SubmitHandler<FormData> = async(data) => {
    setLoading(true)
    const formData = new FormData()
    Images.current.map((image)=>
    formData.append('photos', image)
    )
    await authAxios.patch(`api/ivantage/editfiles/${id}`,formData,{
    headers: {
        'Content-Type': 'multipart/form-data'
      }
  }).then((res)=>{
    
    if(res.status == 200 || res.data.success==true){
      setLoading(false)
      
      successNotification()
      setTimeout(()=> navigate('/properties'), 1000)
    }
  }).catch(e=>{
    
    setLoading(false)
    if(e.response.status == 400 )
    {
        errMessage.current = 'Validation Error!'
    }
    else{
        errMessage.current = e.response.data.message
    }
    errorNotification()
  })
}

  return (
    <>
    <Header/>

    <div className='flex'>
      <SideBar/>
    <div className='p-8 bg-lightgray w-[100%] overflow-hidden'>
      <section className='mt-6'>
      <div className='flex py-4 cursor-pointer' onClick={()=>navigate(-1)}>
          <p className='text-xs underline text-teal-600'>Go Back</p>
        </div>
        <h2 className='text-xl font-semibold'>Upload new Images</h2>
      </section>

      <section>
        <form action="" onSubmit={handleSubmit(onSubmit)}>

             {/* photos */}
             <div className=' my-3 px-4 w-[100%] lg:w-[50%]'>
                <label className='text-[14px] mb-4'>Images (maximum of 10)</label>
                <input type="file" accept="image/*" multiple  max={10} maxLength={10} 
                 onChange={handleFileChange}
                 name='file[]'
                //{...register("photos", {required: true})} 
                className='bg-lightgray rounded border-darkgray border-[1.5px] p-1 w-[100%] mt-1 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none focus:border-cyan-900' />
                {errors.photos && <span className='text-xs text-red-600'>Select an image.</span>}
                <div>
                <div className='lg:grid grid-cols-4'>
                  {imageTags.map((tag) => (
                  
                    <div key={tag.id} className='mt-2'>
                     <div className='w-[100px] h-[70px]'>
                        
                      <img src={tag.imageUrl} alt={tag.id}   onClick={()=>previewImage(tag.imageUrl, tag.id)}  className='cursor-pointer w-[100%] h-[100%] rounded object-fill'/>
                   
                        </div>
                      <button onClick={() => handleRemove(tag.id)} className='font-semibold py-2 text-cyan-900 text-xs'>Remove</button>
                    </div>
                 
                  ))}
                     </div>
                 {preview && img && imgId &&(
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <div className='bg-white'>
                    <div className='flex justify-between'>
                      <div className='text-start my-3'>
                          <button className=' text-sm text-center py-2 px-4  rounded-full font-semibold border-cyan-900 bg-gray text-cyan-900 ' type='submit' onClick={(e)=>setDisplayImage(img, imgId,e)}> Set as Diplay Picture
                          </button>
                        </div>

                        <div className='text-end my-3' onClick={()=> setPreview(!preview)}>
                          <button className=' text-sm text-center py-2 px-4  rounded-full font-semibold border-cyan-900 bg-gray text-cyan-900 ' type='submit'> 
                          <CancelOutlined/>
                          </button>
                        </div>
                    </div>
                    <div className="sm:w-[250px] md:w-[350px] lg:w-[400px] h-[300px] object-fill ">
                       <img src={img} alt="Preview" className="w-[100%] h-[100%] object-fill "/>
                    </div>
                  
                  </div>
                     
                </div>
                  )}
                </div>
  
            </div>

            {/*  submit button */}
        <div className='text-end mt-10 mb-4'>
        {
            loading?
        
            <button className='bg-cyan-800 text-sm text-center px-6' 
          >
            <ColorRing
              visible={true}
              height="40"
              // width="f0"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
              />
            </button>
                                          
            :
          <button className='bg-cyan-900 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-cyan-900 hover:bg-gray hover:text-cyan-900 '
          type='submit'
          >UPDATE</button>
        }
        </div>

        </form>

     
      </section>
    </div>
           {/* Toast container for notifications */}
           
    </div>
    </>
  )
}

export default EditImages