import React, { useEffect, useState } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import { Login, Signup } from './Authentication';
import Dashboard from './Dashboard/Dashboard';
import Properties from './Property/Properties';
import Profile from './Dashboard/Profile';
import NewProperty from './Property/NewProperty';
import Verification from './Authentication/Verification';
import Publication from './Property/Publication';
import KYC from './KYC';
import { ProtectedRoute } from './Authentication/ProtectedRoute';
import Project from './Project/Project';
import CreateProject from './Project/CreateProject';
import Submissions from './Property/Submissions';
import ViewProject from './Project/ViewProject';
import EditProperty from './Property/EditProperty';
import EditProject from './Project/EditProject';
import VerifyEmail from './VerifyEmail';
import VerifyEmail2 from './VerifyEmail2';
import ChangePassword from './Authentication/ChangePassword';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './Authentication/ForgotPassword';
import EnterPasswordOtp from './Authentication/EnterPasswordOtp';
import ResetPassword from './Authentication/ResetPassword';
import { PublicRoute } from './Authentication/PublicRoute';
import EditImages from './Property/EditImages';
import Analytics from './Analytics/Analytics';
import PropertyAlert from './Alerts/PropertyAlert';
import EditProfile from './Profile/EditProfile';
import NotFound from './NotFound';
import ScrollToTopOnLoad from './ScrollToTopOnLoad';

const Router: React.FC = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // });
  return (
  
    <BrowserRouter> 
    <ScrollToTopOnLoad/>
    <Routes>
       
        <Route path="/*" element={<NotFound />} />
        <Route element={<PublicRoute />}>
         <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password/:email" element={<EnterPasswordOtp />} />

          <Route path="/reset-password/:email" element={<ResetPassword />} />
          {/* <Route path="/activate-account/:email" element={<Verification />} /> */}
        </Route>
        <Route element={<ProtectedRoute />}>
        
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/submitted-properties" element={<Submissions />} />
          <Route path="/newproject" element={<CreateProject />} />
          <Route path="/project/:id" element={<ViewProject />} />
          <Route path="/newproperty" element={<NewProperty />} />
          <Route path="/property-alerts" element={<PropertyAlert />} />
          <Route path="/listing-statistics" element={<Analytics />} />
          <Route path="/edit-property/:id" element={<EditProperty />} />
          <Route path="/edit-property-image/:id" element={<EditImages />} />
          <Route path="/edit-project/:id" element={<EditProject />} />
        
          <Route path="/property/:id" element={<Publication />} />
          <Route path="/account" element={<Profile />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path="/email-verification" element={<VerifyEmail />} />
          <Route path="/verify-email" element={<VerifyEmail2 />} />
          
        </Route>
    </Routes>
           
    </BrowserRouter>

  )
}


export default Router

