import React from 'react'
import RealtorChart from '../Charts/RealtorChart'
import SideBar from '../SideBar'
import Footer from '../Footer'
import PercentageChart from '../Charts/PercentageChart'
import Header from '../Header'

function Analytics() {
  return (
    <>
    <Header/>
    <div className='flex '>
      <SideBar/>
 
     <div className=''>
      <section className='p-8 bg-lightgray overflow-hidden'>
      <h2 className='text-2xl mt-10'>Listing Statistics</h2>
       
         <section className='lg:w-[80%] md:w-[90%] sm:w-[100%] mt-6'>
          
          <RealtorChart/>
        </section>

        
      </section>

   
      <Footer/>
    </div>
     
    </div>
    
    </>
  )
}

export default Analytics