import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Dashboard from './Dashboard/Dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {HomeWork , AnalyticsOutlined, ArrowCircleRightOutlined, ArrowCircleLeftOutlined, LogoutOutlined, HolidayVillage, ArrowDropDownOutlined, CheckCircleOutline, PendingOutlined, MenuOutlined, CancelOutlined, CancelSharp, HomeSharp, HomeMaxOutlined, AddOutlined, BarChartOutlined, AccountCircleOutlined, HolidayVillageOutlined, NotificationsOutlined } from '@mui/icons-material';
import { shortlogo, logo } from '../assets';
import { useAuth } from './Authentication/AuthContext';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';


interface SideBarProps {
  opensidebar : boolean
}

const SideBar: React.FC=()=> {

  const [openSidebar, setOpenSidebar] = useState<boolean>(false)

  const [menuSidebar, setMenuSidebar] = useState<boolean>(false)

  const navigate = useNavigate()
  const [openProperties, setOpenProperties] = useState<boolean>(false)
  
  const { logout } = useAuth()
  const location = useLocation();

  const handleLogout=()=> {
    const logoutConfirmed = window.confirm('Are you sure you want to logout?');

    if (logoutConfirmed) {
      logout()
      navigate('/login')
    }
  }

  return (
    <div className='lg:h-screen h-screen sticky top-0 bg-lightgray ' >
      
    {!openSidebar &&  
    <div className='lg:hidden flex absolute z-10'>
       <div className=''>
          <img src={logo} alt="i-vantage logo" className='w-[20px] p-2' />
        </div>
        <div  className='p-4' onClick={()=>setOpenSidebar(true)}>
          <MenuOutlined sx={{fontSize: 30, }}  />
        </div>
      </div>
    }
   { openSidebar &&
    
         <motion.div 
         className={`top-0 left-0 absolute w-full h-full z-1000 ${openSidebar ? 'bg-black bg-opacity-80' : ''}`}
         initial={{ opacity: 0 }}
         animate={{ opacity: openSidebar ? 1 : 0 }}
         transition={{ duration: 0.3 }}
       >
        <motion.div
        className={` top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 z-9999 ${openSidebar ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: openSidebar ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        >
       <div className={`absolute block lg:hidden  z-1000 rounded-tr-[50px]  bg-white  text-sm font-semibold lg:justify-between  p-2 top-0 left-0  bg-gray-800 h-full ${openSidebar ? 'translate-x-0 ' : '-translate-x-full'}`}>
        
        <div  className='text-end p-2' onClick={()=>setOpenSidebar(false)}>
        <CancelSharp/>
       </div>

        <div className='flex flex-col justify-between '>

        <div className='mt-10'>
    
        <Link to={'/dashboard'} className='px-4 flex '>
          <DashboardIcon sx={{fontSize: 20}} />
          <p className={`ml-2  ${location.pathname === '/dashboard' && 'border-b-[1.5px] border-teal-500'}`}>Dashboard</p>
        </Link>
        
        <p className='py-4'></p>
        
        <Link to={'/properties'} className='px-4 flex '>
          <HomeSharp sx={{fontSize: 20 }} />
          <p className={`ml-2  ${location.pathname === '/properties' && 'border-b-[1.5px] border-teal-500'}`}>My Listings</p>
        </Link>

        <p className='py-4'></p>
        
       
        <Link to={'/submitted-properties'} className='px-4 flex '>
          <HolidayVillageOutlined sx={{fontSize: 20}} />
          <p className={`ml-2 ${location.pathname === '/submitted-properties' && 'border-b-[1.5px] border-teal-500'}`}>Submissions</p>
        </Link>

        <p className='py-4'></p>

        <Link to={'/newproperty'} className='px-4 flex '>
          <AddOutlined sx={{fontSize: 20 }} />
          <p className={`ml-2  w-24  ${location.pathname === '/newproperty' && 'border-b-[1.5px] border-teal-500'}`}>New Property</p>
        </Link>

        <p className='py-4'></p>

        <Link to={'/listing-statistics'} className='px-4 flex '>
          <BarChartOutlined sx={{fontSize: 20 }} />
          <p className={`ml-2 ${location.pathname === '/listing-statistics' && 'border-b-[1.5px] border-teal-500'}`}>Statistics</p>
        </Link>
        <p className='py-4'></p>
     

       

        </div>




        <div className=' mb-10 px-4 flex mt-2  py-4 cursor-pointer flex-end fixed bottom-0 left-0 text-end ' onClick={handleLogout}>
          <LogoutOutlined sx={{fontSize: 20 }} />
          <p className='w-[10%] duration-700 ml-2'>LogOut</p>
        </div>
                  
        </div>
       
      </div>
      </motion.div>  
    </motion.div>

    }

       {/* large screen  */}
 
        <motion.div
         initial={{ x: "-100%" }}
         animate={{ x:  0  }}
         transition={{ duration: 0.3 }}
        className='hidden lg:block rounded-tr-[50px]  translate-x-0  bg-white z-50 text-sm font-semibold lg:justify-between h-screen  p-2'>
 
        <div className='px-2 py-1'>
          <img src={logo} alt="i-vantage logo" className='lg:w-[120px] w-[200px] p-2' />
        </div>
      
        <div className='flex flex-col justify-between '>

        <div className='mt-10'>
    
        <Link to={'/dashboard'} className='px-4 flex '>
          <DashboardIcon sx={{fontSize: 20}} />
          <p className={`ml-2  ${location.pathname === '/dashboard' && 'border-b-[1.5px] border-teal-500'}`}>Dashboard</p>
        </Link>
        
        <p className='py-4'></p>
        
        <Link to={'/properties'} className='px-4 flex '>
          <HomeSharp sx={{fontSize: 20 }} />
          <p className={`ml-2  ${location.pathname === '/properties' && 'border-b-[1.5px] border-teal-500'}`}>My Listings</p>
        </Link>

        <p className='py-4'></p>
        
       
        <Link to={'/submitted-properties'} className='px-4 flex '>
          <HolidayVillageOutlined sx={{fontSize: 20}} />
          <p className={`ml-2 ${location.pathname === '/submitted-properties' && 'border-b-[1.5px] border-teal-500'}`}>Submissions</p>
        </Link>

        <p className='py-4'></p>

        <Link to={'/newproperty'} className='px-4 flex '>
          <AddOutlined sx={{fontSize: 20 }} />
          <p className={`ml-2 w-24  ${location.pathname === '/newproperty' && 'border-b-[1.5px] border-teal-500'}`}>New Property</p>
        </Link>

        <p className='py-4'></p>

        <Link to={'/listing-statistics'} className='px-4 flex '>
          <BarChartOutlined sx={{fontSize: 20 }} />
          <p className={`ml-2 ${location.pathname === '/listing-statistics' && 'border-b-[1.5px] border-teal-500'}`}>Statistics</p>
        </Link>
        <p className='py-4'></p>
        {/* <Link to={'/property-alerts'} className='px-4 flex '>
          <NotificationsOutlined sx={{fontSize: 20 }} />
          <p className={`ml-2 ${location.pathname === '/property-alerts' && 'border-b-[1.5px] border-teal-500'}`}>Alerts</p>
        </Link> */}
        <p className='py-4'></p>

       

        </div>




        <div className=' mb-10 px-4 flex mt-2  py-4 cursor-pointer flex-end fixed bottom-0 left-0 text-end ' onClick={handleLogout}>
          <LogoutOutlined sx={{fontSize: 20 }} />
          <p className='w-[10%] duration-700 ml-2'>LogOut</p>
        </div>
                  
        </div>
       
      
      </motion.div>  



    </div>
    
    
  )
}

export default SideBar