import React from 'react'
import Header from '../Header'
import SideBar from '../SideBar'

function PropertyAlert() {
  return (
    <div>

       <Header/>
       <div className='flex'>
        <SideBar/>
        <div className='p-8 flex-1 bg-lightgray overflow-hidden'>
        
        <h2 className='text-2xl mt-10  mb-4'>Create Property Alert</h2>
        </div>
       </div>


    </div>
  )
}

export default PropertyAlert