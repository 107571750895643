import React, { useState, useEffect, useContext } from 'react';
import { FaBars, FaBell, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { shortlogo } from '../assets';
import { AxiosContext } from '../components/Axios/AxiosContext';
import { getUserDataFromToken } from '../components//Authentication/TokenAuth';
import { CancelOutlined } from '@mui/icons-material';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [image, setImage] = useState<string>('')
  const { authAxios } = useContext(AxiosContext)
  const [loading, setLoading] = useState(true);
  const [isApproved, setIsApproved] = useState<number>(1)
  const [showApproved, setShowApproved] = useState<boolean>(true)
  const imageurl =    localStorage.getItem('dp')
  useEffect(() => {
   
    const token = localStorage.getItem('user');
          if (token) {
            // Decode JWT token and extract user data
            const decodedUserData = getUserDataFromToken(token.toString());
          
           if (decodedUserData){
            authAxios.get(`/api/ivantage/agent/getAgent?userId=${decodedUserData.id}`)
                .then(res => {
                  if(res.data.data){
                 
                      setImage(res.data.data.picture)
                      setIsApproved(res.data.data.isEnabled)
                     
                      localStorage.setItem('dp',res.data.data.picture)
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  
                  // alert('There was an error while retrieving the data');
             });
      }
     }},[]);
  return (
    <div className='fixed z-1000 bg-lightgray w-screen '>
    <div className='flex items-end justify-end w-screen p-2' >
      <div className='flex pr-2 text-center justify-center items-center'>
        <Link to={'/profile'}>
          <div className='flex pr-2 text-center justify-center items-center'>
            <p className='px-2 text-[16px] text-center'>Profile</p>
            <div>
                  <img src={imageurl || ''} alt="" className='w-12 h-12 rounded-full'/>
            </div>
            {/* <FaUserCircle className='my-1 text-teal-600' size={30} /> */}
          </div>
        </Link>
      </div>
   
    </div>

    {
        !isApproved &&  <div className='absolute right-12 flex p-4 bg-teal-100'> 
        
          <p>Your account is yet to be approved as your documents are still in review.
            <br/> 
            <span className='text-red-500 '> Please Note: You won&apos;t be able to post a property until it is approved.</span>
          </p>
          <div className='text-end ml-2 cursor-pointer' onClick={()=>setShowApproved(false)}>
          <CancelOutlined/>
        </div>
        </div>
      }

      {/* <section  className=''> */}
   
        {/* <div className='px-2 '>
          <img src={shortlogo} alt="i-vantage logo" className='lg:w-[30px] w-[30px]' />
        </div>

        <div className='flex justify-center flex-1'>
          <div className='mx-4'>
            <Link to={'/dashboard'} className="text-[16px] ">
              Dashboard
            </Link>
          </div>
          <div className=''>
            <button
              onClick={toggleDropdown}
              type="button"
              className="text-[16px] hover:underline"
            >
              Listing Management
            </button>

            {isOpen && (
              <div
                className="absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
              >
                <div className="py-1">
                  <Link
                    to={'/add-property'}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Add property
                  </Link>
                  <Link
                    to={'/properties'}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Properties
                  </Link>
                  <Link
                    to={'/listing-statistics'}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Listing Statistics
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div> */}

       
      {/* </section> */}
    </div>
  )
}

export default Header;
