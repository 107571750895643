import React,{useState,useEffect, useRef} from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import { Email } from '@mui/icons-material';
// import { getUserDataFromToken } from './Authentication/TokenAuth';
import 'react-toastify/dist/ReactToastify.css';
import { logo } from '../../assets';
import { motion } from 'framer-motion';

interface FormData {
  phoneNumber: string;
  otp: string;
}

const EnterPasswordOtp: React.FC = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const number= useRef()
    const [loading, setLoading] = useState<boolean>(false)
    const { email } = useParams<any>();
    


    const resendNotification = () => toast.success('OTP code has been re-sent to your email address!.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style:{
        textTransform: 'capitalize',
    }
      });
  
   const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    useEffect(()=>{
        axios.get(`${baseUrl}/api/ivantage/agent/getUserByEmail?email=${email}`).then((res)=>{
          
          if(res.data.data.phoneNumber){
            number.current=res.data.data.phoneNumber
            
          }
        }).catch((e)=> console.error(e)) 
        })
    
    const handleVerification= async()=>{
        setLoading(true)
      if(otp.length==0){
        errMessage.current='OTP code not provided!.'
        setLoading(false)
        errorNotification()
      }
      else{
      setLoading(true)
      
      
      await axios.put(`${baseUrl}/api/ivantage/agent/verifyOTP`,{
        phoneNumber: number.current,
        otp: otp
      }).then((res)=>{
        
        if (res.status==200 || res.data.success==true){
          setLoading(false)

          navigate(`/reset-password/${email}`)
        }
        
      }).catch(e=>{
        
        setLoading(false)
        
        errMessage.current = e.response.data.message
        
        setLoading(false)
        errorNotification()
      })}
    }
  
    const handleResendOtp= async()=>{
        setLoading(true)
      await axios.post(`${baseUrl}/api/ivantage/agent/resendOtp`,{
        sms:false,
        data: email
      }
        ).then((res)=>{
        
        if (res.status==200 || res.data.success==true){
          setLoading(false)
          resendNotification()
        }
        
      }).catch(e=>{
        setLoading(false)
        errMessage.current = e.response.data.message
        errorNotification()
      })
    }
  return (
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className='min-h-screen bg-teal-600  text-gray-900 antialiased relative w-screen flex items-center justify-center'>
        <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
       ></div>
   {/* logo */}
     
      <div className='p-6 absolute left-0 top-0'>
          <img src={logo} alt="i-vantage logo" className='w-[50%]' />
        </div>
        <div className='z-10 h-[80%] mt-24 mx-auto px-3 items-center rounded-t-lg rounded-b-lg lg:p-8 lg:w-[40%] sm:w-[90%] md:w-[60%] p-6 bg-white '>
            <div>
                <h2 className='font-semibold text-2xl text-center mb-8 text-teal-600'>Enter OTP</h2>
                <p className='text-[16px] text-center'>Enter the OTP code that was sent to your email.</p>
                <div className='text-center flex justify-center p-4 my-6'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span className='text-teal-600 font-bold mx-4'>-</span>}
                        renderInput={(props) => <input {...props} className='border-2 border-teal-600 w-[100px] rounded-sm
                        text-4xl  text-teal-600 font-bold focus:border-darkgray' />}
                        shouldAutoFocus= {true}
                         inputType='tel'        
                    />
                </div>
                <p className='text-green-700 text-xs text-center underline cursor-pointer' onClick={handleResendOtp}>Resend Code</p>
                {/* submit button */}
                            
            <div className='text-end mt-10 mb-4' >
              {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="35"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
                onClick={handleVerification}
              >SUBMIT
              </button>

              }
            </div>
            </div>
        </div>

          {/* Toast container for notifications */}
          

    </motion.div>
  )
}

export default EnterPasswordOtp