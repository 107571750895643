import React, { useState, useEffect, useContext } from 'react'
import PropertyRecords from '../PropertyRecords';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import { AccountCircleOutlined, AnalyticsOutlined, BarChartOutlined, HolidayVillageOutlined, HomeOutlined, InsightsOutlined, NotificationsActiveOutlined, SellOutlined } from '@mui/icons-material';
import Header from '../Header';
import SideBar from '../SideBar';
import { AxiosContext } from '../Axios/AxiosContext';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { getUserDataFromToken } from '../Authentication/TokenAuth';
import Footer from '../Footer';
import { motion } from 'framer-motion';

interface Record {
  id: number;
  propertyCode: string;
  projectName: string;
  propertyType: string
  address: string;
  state: string;
  price: string;
  totalUnits: string;
  availableUnits: string;
  status: string;
  description: string;
}

interface UserData {
  id: string;
  name: string;
  email: string;
  isEnabled: boolean;
  hasKyc: boolean;
}

const Dashboard: React.FC = () =>{

      const navigate = useNavigate()
      const [data, setData] = useState<Record[]>([]);
      const [loading, setLoading] = useState(true);
      const location = useLocation();

      const { authAxios } = useContext(AxiosContext)
      const [ totalProperties, setTotalProperties] = useState<number>(0)

      const [userData, setUserData] = useState<UserData | null>(null);
      const [views, setViews] = useState<number>(0)
      const [submissions, setSubmissions] = useState<number>(0)
      const [name, setName] = useState<string>('')
      // Check local storage for KYC status on initial render
         useEffect(() => {
          const token = localStorage.getItem('user');

          if (token) {
            // Decode JWT token and extract user data
            const decodedUserData = getUserDataFromToken(token.toString());
           
            if(!decodedUserData?.hasKyc){
              navigate('/kyc')
            }
            setUserData(decodedUserData);

          } else {
            console.error('JWT token not found');
          }
        }, []);
            

        

      useEffect(() => {
        setLoading(true)
        authAxios.get(`/api/ivantage/agent/properties?size=5&page=1`)
          .then(res => {
            setData(res.data.data.propertyData);
           
            setTotalProperties(res.data.data.count)
            setLoading(false);
          })
          .catch(() => {
            setLoading(false)
          });
      }, []);

      
    

      useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem('user');
        if (token){
        const decodedUserData = getUserDataFromToken(token.toString());
       
        authAxios.get(`/api/ivantage/agent/views/${decodedUserData?.email}`)
          .then(res => {
            
        
            setViews(res.data.data.count)
            setLoading(false);
          })
          .catch(() => {
            setLoading(false)
          });
        }
      }, []);

      useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem('user');
        if (token){
     
           
      authAxios.get(`/api/ivantage/agent/unapproved`)
      .then(res => {
       
          setSubmissions(res.data.data.count);
          setLoading(false);
        })
          .catch(() => {
            setLoading(false)
          });
        }
      }, []);

  return (
    <>
    <Header/>

    <div className='flex '>
      <SideBar/>

   <div>

   
    <div className='p-8 flex-1 bg-lightgray overflow-hidden z-10'
    
    >
      {/* <h2 className='text-2xl mt-10 mb-4'>Dashboard</h2> */}
      {/* boxes */}
      {/* <p className='text-teal-500 text-xs  pb-2'>{location.pathname}</p> */}
      <h2 className='text-2xl lg:mt-10 mt-16  mb-4 font-heading'>Welcome, {userData?.name}</h2>
      {/* <div className='absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-teal-800 to-transparent' style={{ clipPath: 'polygon(0% 25%, 50% 0%, 100% 25%, 100% 100%, 0% 100%)' }}></div> */}



      <motion.div
    //  initial={{ x: "25vh" }} 
      animate={{ x: 0 }}
      transition={{ duration: 0.4 }}  className='flex flex-col lg:flex-row  justify-between '>

      <div className='bg-gradient-to-r from-cyan-900 to-teal-600 p-4 shadow-md rounded-sm my-2 lg:mr-2 lg:w-[35%] cursor-pointer ' > 
      <div className='flex justify-between'>
        <p className='font-semibold text-[14px] lg:text-[16px] text-white'>Total Properties </p>
        
        <p className='font-bold text-2xl text-center mx-4 text-white '>{totalProperties}
        </p>
      </div>
     
      </div>

      <div className='bg-gradient-to-r from-cyan-900 to-teal-500 p-4 shadow-md rounded-sm my-2 lg:mr-2 lg:w-[35%] cursor-pointer ' > 
      <div className='flex justify-between'>
        <p className='font-semibold text-[14px] lg:text-[16px] text-white'>Total No of Views </p>
      
        <p className='font-bold text-2xl text-center mx-4 text-white'>{views}
        </p>
      </div>
     
      </div>

      <div className='bg-gradient-to-r from-cyan-900 to-teal-600 p-4 shadow-md rounded-sm my-2 lg:w-[35%] cursor-pointer ' > 
      <div className='flex justify-between'>
        <p className='font-semibold text-[14px] lg:text-[16px] text-white'>Total Submissions</p>
      
        <p className='font-bold text-2xl text-center mx-4 text-white'>{submissions}
        </p>
      </div>
     
      </div>


      </motion.div>


      <div className='grid grid-cols-2 gap-4 w-[100%] mx-auto mt-2'>

      <motion.div
      initial={{ scale: 0.9 }} // Set initial scale to 0
      animate={{ scale: 1 }} // Animate to scale 1 (normal size)
      transition={{ duration: 0.5 }} // Duration of the animation
       className='bg-white py-8 shadow-md rounded-sm my-2 cursor-pointer hover:bg-teal-50' onClick={()=>navigate('/properties')}> 
          <div className='text-center justify-center align-middle items-center mt-2 '>
            <div className=' p-2'>
              <HomeOutlined sx={{fontSize: 100, color:'#00838F'}} />
            </div>
          
            <p className='font-semibold text-[15px] lg:text-[16px] mt-2'>My Listings</p>
            <p className='text-[12px] lg:text-[12px] font-body'>View, Add, and Edit listings.</p>
            </div>
         </motion.div>


      <motion.div
      initial={{ scale: 0.9 }} // Set initial scale to 0
      animate={{ scale: 1 }} // Animate to scale 1 (normal size)
      transition={{ duration: 0.5 }} // Duration of the animation
         className='bg-white hover:bg-teal-50 py-8 shadow-md rounded-sm my-2  cursor-pointer ' onClick={()=>navigate('/listing-statistics')}> 
          <div className='text-center justify-center align-middle items-center mt-2 '>
            <div className=' p-2'>
              <InsightsOutlined sx={{fontSize: 100, color:'#00838F'}} />
            </div>
          
            <p className='font-semibold text-[15px] lg:text-[16px] mt-2'>Listing Statistics</p>
            <p className='text-[12px] lg:text-[12px]' >View listing statistics.</p>
            </div>
         </motion.div>

      <motion.div
      initial={{ scale: 0.9 }} // Set initial scale to 0
      animate={{ scale: 1 }} // Animate to scale 1 (normal size)
      transition={{ duration: 0.5 }} // Duration of the animation 
      className='bg-white hover:bg-teal-50 py-8 shadow-md rounded-sm my-2  cursor-pointer ' onClick={()=>navigate('/submitted-properties')}> 
          <div className='text-center justify-center align-middle items-center mt-2 '>
            <div className=' p-2'>
              <HolidayVillageOutlined sx={{fontSize: 100, color:'#00838F'}} />
            </div>
          
            <p className='font-semibold text-[15px] lg:text-[16px] mt-2'>Submissions</p>
            <p className='text-[12px] lg:text-[12px]'>View submitted properties.</p>
            </div>
         </motion.div>
         {/* <div className='bg-white hover:bg-teal-50 py-8 shadow-md rounded-sm my-2 cursor-pointer ' onClick={()=>navigate('/property-alerts')}> 
          <div className='text-center justify-center align-middle items-center mt-2 '>
            <div className=' p-2'>
              <NotificationsActiveOutlined sx={{fontSize: 100, color:'#00838F'}} />
            </div>
          
            <p className='font-semibold text-[14px] lg:text-[16px] mt-2'>My Property Alerts</p>
            <p className='text-[12px] lg:text-[12px]'>Create property alerts.</p>
            </div>
         </div> */}

      <motion.div
      initial={{ scale: 0.9 }} // Set initial scale to 0
      animate={{ scale: 1 }} // Animate to scale 1 (normal size)
      transition={{ duration: 0.5 }} // Duration of the animation
       className='bg-white hover:bg-teal-50 py-8 shadow-md rounded-sm my-2  cursor-pointer ' onClick={()=>navigate('/profile')}> 
          <div className='text-center justify-center align-middle items-center mt-2 '>
            <div className=' p-2'>
              <AccountCircleOutlined sx={{fontSize: 100, color:'#00838F'}} />
            </div>
          
            <p className='font-semibold text-[15px] lg:text-[16px] mt-2'>My Profile</p>
            <p className='text-[12px] lg:text-[12px]'>View, and Edit Profile.</p>
            </div>
         </motion.div>





         </div>
       

     
    </div>
    
        
         
    <Footer/>              
   </div>
    </div>
    </>
  )
}

export default Dashboard
