import React,{useState,useEffect, useRef} from 'react'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import { Email } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { useForm,  SubmitHandler } from "react-hook-form"
import { logo } from '../../assets';
import { motion } from 'framer-motion';

interface FormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()
    const errMessage = useRef<string>('')
    const number= useRef()
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger
      } = useForm<FormData>()

   const errorNotification = () => toast.error((errMessage.current), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    const successNotification = () => toast.success('An OTP code has been sent to your email..', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    const onSubmit: SubmitHandler<FormData> = async(data) => {
        setLoading(true)
        const handleResendOtp= async()=>{
            await axios.post(`${baseUrl}/api/ivantage/agent/resendOtp`,{
              sms:false,
              data: data.email
            }
              ).then((res)=>{
              
              successNotification()
              setTimeout(()=> navigate(`/forgot-password/${encodeURIComponent(data.email)}`), 1000)
            }).catch(e=>{
    
              errMessage.current = e.response.data.message
              
              errorNotification()
            })
          }
        axios.get(`${baseUrl}/api/ivantage/agent/getUserByEmail?email=${data.email}`).then((res)=>{
          if(res.data.data != null){
            setLoading(false)
            handleResendOtp()   
          }
          else{
            setLoading(false)
            errMessage.current = "The email address is not registered!"
            errorNotification()
          }
        }).catch((e)=>
          {
           setLoading(false)
          } ) 
        

}
  return (
    
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className='min-h-screen bg-teal-600 flex flex-col items-start text-gray-900 antialiased relative'>
      <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
  {/* logo */}
     
    <div className='p-6 absolute left-0 top-0'>
      <img src={logo} alt="i-vantage logo" className='w-[50%]' />
    </div>
        <div className='z-10 sm:w-[90%] md:w-[60%] mt-24 lg:w-[40%] mx-auto items-center rounded-t-lg rounded-b-lg p-10 bg-white'>
            <div>
                <h2 className='font-semibold text-2xl text-center mb-8 text-teal-600'>Forgot Password</h2>
                <p className='text-[16px] text-center'>Please enter your email address below. <br /> You will receive a one-time password (OTP) code to reset your password.</p>
                <form action=""  onSubmit={handleSubmit(onSubmit)}>
                <div className='p-4 '>
                    {/* email */}
                        <div className='flex flex-col my-4'>
                      
                        <div className='w-[100%]'>
                        <input 
                        type="email" 
                        {...register("email", {required: true, pattern: {
                            value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Enter a valid email address"         
                        }   })}
                        placeholder='Enter your email'  
                        className='bg-gray border-gray rounded px-3  w-[100%] p-2 text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none'/>
                        {errors.email && <span className='text-xs text-red-600'>This field is required</span>}
                        {errors.email && <p className='text-xs text-red-600 text-justify'>{errors.email.message}</p>}      
                        
              
              </div>
            </div>
                </div>
        
                            
            <div className='text-end mt-6 mr-4 rounded-md mb-4' >
              {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-teal-600 text-sm  text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
            
              >SUBMIT
              </button>

              }
            </div>
            </form>
            </div>
        </div>

          {/* Toast container for notifications */}
          

    </motion.div>
  )
}

export default ForgotPassword