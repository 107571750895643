import React,{useEffect,useContext, useState} from 'react'
import Header from '../Header'
import SideBar from '../SideBar'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { AxiosContext } from '../Axios/AxiosContext';
import { getUserDataFromToken } from '../Authentication/TokenAuth';
import { ColorRing } from 'react-loader-spinner'
import { motion } from 'framer-motion';
import { data } from '../Charts/PercentageChart';

interface UserData {
  id: string;
  name: string;
  email: string;
  phoneNumber: "+2348430493384",
  phoneNumber2: "+2348430493384",
  isVerified: 1,
  isEnabled: 0,
  hasKyc: 0,
  picture: null,
  govtId: null,
  address: "2, Shanghai Schengen",
  agentCompany: string
}

function Profile() {
  const { authAxios } = useContext(AxiosContext)
  const [loading, setLoading] = useState(true);
  
  const [userData, setUserData] = useState<UserData | null>(null);
  const [image, setImage] = useState<string>('')


  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('user');
          if (token) {
            // Decode JWT token and extract user data
           
            const decodedUserData = getUserDataFromToken(token.toString());
           
            if (decodedUserData){
            authAxios.get(`/api/ivantage/agent/getAgent?userId=${decodedUserData.id}`)
                .then(res => {
                  if(res.data.data){
                   
                     
                      setUserData(res.data.data)
                      setImage(res.data.data.picture)
                    
                  
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  
                  // alert('There was an error while retrieving the data');
             });
      }
     }},[]);

  return (
    <>
    <div className='flex'>
      <SideBar/>
      <div className='flex-1'>
      {
        loading?
        <div className='flex-1 overflow-hidden items-center justify-center'>
          <ColorRing
          visible={true}
          height="100"
          width="100%"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
          />  
      </div>
      :
      <motion.div
      initial={{ opacity:0 }}
      animate={{ opacity:1 }}
      transition={{ duration: 0.5}}
      >
    <div className='p-8 bg-lightgray w-[100%] overflow-hidden'>
      <h2 className=' text-2xl lg:mt-10 mt-16  font-semibold'>Profile</h2>
      {!userData?.hasKyc && <Link to='/kyc'><p className='text-end text-red-700 underline text-[16px] '>Upload KYC</p></Link>}
    
      <div>
        <div className='flex pb-1 mt-4'>
 
      {
          userData?.isVerified &&
         <span className='bg-teal-400  rounded-full text-[12px] font-normal px-2  text-lightgray'>Verified</span>
          }
           {
          userData?.isEnabled ?
          <span className='bg-cyan-900  rounded-full text-[12px] font-normal px-2  text-lightgray'>Approved</span>
         :
         <span className='bg-red-700  rounded-full text-[12px] font-normal px-2  text-lightgray'>Not Approved</span>
          }
                   
        </div>
        <div className='flex justify-between'>
         <img src={image} alt="" className='w-[160px] h-[150px] rounded-full border-teal-600 shadow-lg' />
         <div>
         <Link to='/change-password'><p className='text-end text-red-700 underline text-[16px] mb-2 '>Change Password</p></Link>
        <Link to='/edit-profile'><p className='text-end text-red-700 underline text-[16px] my-2 '>Edit Profile</p></Link>
         </div>
       
          </div>
          <div className='flex '>
         
         <div className='lg:w-[50%] w-[90%]'>
           <div className='my-4'>
            <p className='text-[16px] font-medium'>Name</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.name}</p>
           </div>

           <div className='my-4'>
            <p className='text-[16px] font-medium'>Email</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.email}</p>
           </div>

           <div className='my-4'>
            <p className='text-[16px] font-medium'>Phone Number</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.phoneNumber}</p>
           </div>

           {
            userData?.phoneNumber2 && 
            <div className='my-4'>
            <p className='text-[16px] font-medium'>Phone Number2</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.phoneNumber2}</p>
           </div>
           }

           <div className='my-4'>
            <p className='text-[16px] font-medium'>Residential Address</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.address}</p>
           </div>

           <div className='my-4'>
            <p className='text-[16px] font-medium'>Company</p>
            <p className='border-b border-[1px] border-x-0 border-t-0 p-3 border-teal-600 text-[16px]'>{userData?.agentCompany}</p>
           </div>

           </div>           
         </div>
      </div>
      
      </div>
      </motion.div>
      }
      </div>
    </div>
    </>
  )
}

export default Profile