import React from 'react'

function Footer() {
  return (
    <div className='bg-gray p-8 text-[14px] lg:text-[16px]'>
    <footer id="footer">
        <div className="footer-top">
            <div className="container">
            <div className="flex lg:flex-row flex-col justify-between ">
                <div className='lg:w-[20%]'>
                <h4 className='text-xl lg:text-2xl font-bold'>iVantage</h4>
                <p>
                    <span><i className="fa-solid fa-house"></i> </span> 124, Norman Williams St, Ikoyi 106104, Lagos
                </p>
                </div>

            
                <div className="lg:w-[25%] lg:my-0 my-2">
                <h4 className='text-1xl font-semibold '>About us</h4>
                <p>
                We offer a shortened and hitch-free customer journey towards property identification, mortgage processing, mortgage approval and subsequent disbursement and monitoring
                </p>
                </div>
                <div>
                <h4 className='text-1xl font-semibold'>Useful Links</h4>
                <a href="#">Home</a>
                <br />
                <a href="#">Property Listing</a>
                <br />
                <a href="#">Privacy Policy</a>
                <br />
                <a href="#">FAQs</a>

                </div>
                <div className="lg:w-[25%]  lg:my-0 my-2">
                <h4 className='text-1xl font-semibold'>Contact us</h4>
                <p>
                    <i className="fa-solid fa-envelope"></i> info@ivantage.africa<br />
                    <hr />
                    <i className="fa-solid fa-phone"></i> 0906 397 6238<br />
                </p>
                <hr />
                <p>
                    <span><i className="fa-solid fa-globe"></i> </span><a href="www.ivantage.africa">www.ivantage.africa</a>
                </p>
                <hr />
                
                <hr />
                <div>
                <div className="social-links mt-3">
                    <a href="https://www.linkedin.com/company/ivantage-technology-services-limited/" className="linkedin"><i className="bx bxl-linkedin"></i></a>Linkedin <br />
                    <a href="https://www.instagram.com/ivantagetech/" className="instagram"><i className="bx bxl-instagram"></i></a>Instagram <br />
                    <a href="https://www.facebook.com/people/IVantage/100088414636870/" className="facebook"><i className="bx bxl-facebook"></i></a> Facebook <br />
                </div>
                </div>
                </div>
                
            </div>
            </div>
        </div>
        <div className="text-center mt-4">
            <div className="copyright">
            All Rights Reserved. &copy; Copyright <strong><span className="name">iVantage Technology Services Limited</span></strong>.
            </div>
        </div>
    </footer>

    </div>
  )
}

export default Footer