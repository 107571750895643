import React,{useState,ChangeEvent,useEffect,useRef} from 'react';
import { logo, signupimage2 } from '../../assets';
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import baseUrl from '../Util/config';
import { ToastContainer, toast } from 'react-toastify';
import { useForm,  SubmitHandler } from "react-hook-form"
import axios from 'axios';
import { useAuth } from './AuthContext';
import { ColorRing } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Footer';

interface FormData {
  email: string;
  password: string;
}

const Login: React.FC = () =>{  
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState<string>("password");
  const errMessage = useRef<string>('')
  const { login } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const togglePassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const successNotification = () => toast.success('Login  successful!.', {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

 const errorNotification = () => toast.error((errMessage.current), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style:{
        textTransform: "capitalize",
      }
      });

  const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm<FormData>()
      const onSubmit: SubmitHandler<FormData> = async(data) => {
        setLoading(true)
        localStorage.setItem('email', data.email)
        await axios.post(`${baseUrl}/api/ivantage/agent/login`,data).then((res)=>{
          
          if (res.status==200 || res.data.success==true){
            setLoading(false)
    
            login(res.data.data.token)
            navigate('/dashboard')
            console.log(res)
          }
        }).catch(e=>{
          setLoading(false)
          
          
          console.log(e.response)
          if(e.response.data.message == "unVerified"){
            errMessage.current = 'Your account is yet to be verified, you will now be redirected to the activation page to verify your account.'
            setTimeout(()=> navigate(`/activate-account/${data.email}`),3000)
          }
          else{
            errMessage.current = e.response.data.message
          }

          errorNotification()
        })
      }
    
  return (
    <div className='min-h-screen min-w-screen bg-teal-600 flex flex-col items-start text-gray-900 antialiased relative '
    
    >
       <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "34rem",
        }}
        className="absolute bg-teal-50 inset-x-0 top-0"
      ></div>
  


  <div 
  className='z-10 h-[80%] mt-24 mx-auto px-3 items-center rounded-t-lg rounded-b-lg lg:p-8 lg:w-[50%] sm:w-[150%] md:w-[80%] p-6 '>
    
      {/* logo */}
     
      <div className='p-6 absolute left-0 top-0'>
      <img src={logo} alt="i-vantage logo" className='w-[50%]' />
    </div>
    
    <motion.div
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ duration: 0.8, delay: 0.2 }}
    >


        {/* sign up form */}
        <div className=' w-[100%]  bg-white  lg:px-8  px-4 rounded-lg '>
          <h1 className='text-teal-600 text-3xl py-6 px-0 font-semibold  text-center '>Login</h1>

          <form action="" onSubmit={handleSubmit(onSubmit)}>
          {/* email */}
            <div className='flex flex-row my-4'>       
              <p className='p-2 font-semibold  lg:w-[40%] w-[50%]'>Email</p>
              <div className='w-[100%]'>
              <input 
               type="email"
               {...register("email", {required: true, pattern: {
                value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Enter a valid email address"         
              }   })}
               placeholder='Enter your email'  
               className='bg-gray border-gray w-[100%] py-2 text-sm  rounded placeholder:text-xs px-3 placeholder:gray-500 placeholder:opacity-85  focus:outline-none '
               />
              {errors.email && <span className='text-xs text-red-600'>Email is required</span>}
              {errors.email && <p className='text-xs text-red-600 text-justify'>{errors.email.message}</p>}      
             
              
              </div>
            </div>

          {/* password */}
            <div className='flex flex-row my-4 '>       
              <p className='p-2  font-semibold lg:w-[40%] w-[50%]'>Password</p>
              <div className='w-[100%]'>
              <div className='w-[100%] relative'>
                <input 
                type={passwordType} 
                {...register("password", {required: true })}
                placeholder='Enter your password'  
                className='relative bg-gray border-gray rounded py-2 px-3 w-[100%] text-sm text:p-3 placeholder:text-xs placeholder:gray-500 placeholder:opacity-85 focus:outline-none' 
                />
                <button className="absolute right-2 top-0 bottom-0" onClick={togglePassword}>
                     { passwordType==="password"? <FaEyeSlash /> : <FaEye/> }
                 </button>
              </div>
              {errors.password && <span className='text-xs text-red-600 '>Password is required</span>}  
              </div>
            </div>
            <div className='flex px-2 pt-4'>
                {/* checkbox */}
              <input type="checkbox" name="" id="" className='text-cyan-900 mr-2 p-2 w-4' />
               
                <p className='text-[14px] font-medium'>Remember me.
                </p>
            </div>
            {/* submit button */}
            
            <div className='text-end mt-6 mb-4' >
              {
                loading?
             
                <button className='bg-teal-600 text-sm text-center px-6' 
              >
                <ColorRing
                  visible={true}
                  height="40"
                  // width="f0"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#26A69A', '#abbd81', '#00838F']}
                  />
                </button>
                                  
                :
                <button className='bg-teal-600 text-sm text-center py-2 px-10 text-gray font-semibold hover:border-teal-600 disabled:bg-gray disabled:text-lightgray hover:bg-gray hover:text-teal-600 ' type='submit'
              
              > LOGIN
              </button>

              }
            </div>
          </form>
           
           <p className='text-teal-600 text-[14px] text-center mt-6 mb-4 hover:underline cursor-pointer' onClick={()=>navigate('/forgot-password')}>Forgot Password?</p>

          <div  className='text-center my-2 '>
            <p className='text-[14px] text-black'>Don&apos;t have an Account?  <span className='text-teal-600 hover:underline'>
                <Link to="/signup">
                SignUp
                </Link>
              </span>
            </p>
          </div>
        </div>

          {/* Toast container for notifications */}
          
      </motion.div>
      
      </div>

    </div>
  )
}

export default Login;